import React from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';

import { Field, Form } from 'react-final-form';
import TextFieldWrapper from './TextFieldFinalFormWrapper';
import useCommonStyles from '../../util/commonStyles';
import { composeValidators, isNumber, required } from '../../util/finalFormValidators';
import useFormStyles from '../../util/formStyles';

const ErrForm = ({ validateERR, validating, validationError }) => {
    const commonClasses = useCommonStyles();
    const formClasses = useFormStyles();

    return (
        <Box p={1}>
            <Typography className={commonClasses.centerScreen} variant="h6">
                Enter your ERR Number
            </Typography>
            <Form
                initialValues={{ errNumber: '' }}
                onSubmit={validateERR}
                render={({ handleSubmit, pristine, invalid }) => (
                    <form onSubmit={handleSubmit}>
                        <Box sx={{ display: 'flex', width: '100%', maxWidth: '50rem', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <Box p={1}>
                                <Field
                                    sx={{ flex: 1 }}
                                    className={formClasses.formField}
                                    label="ERR Number"
                                    name="errNumber"
                                    validate={composeValidators(required, isNumber)}
                                    component={TextFieldWrapper}
                                />
                            </Box>

                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                disabled={pristine || invalid || validating}
                                style={{
                                    color: 'white',
                                }}>
                                {validating ? (
                                    <>
                                        Validating
                                        <CircularProgress sx={{ marginLeft: '1rem' }} size={20} />
                                    </>
                                ) : (
                                    'Validate'
                                )}
                            </Button>
                        </Box>
                        {validationError ? (
                            <Typography className={commonClasses.error} sx={{ marginLeft: '1rem' }}>
                                Failed to validate. Please try again.
                            </Typography>
                        ) : null}
                    </form>
                )}
            />
        </Box>
    );
};

export default ErrForm;
