import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useCommonStyles = makeStyles((theme: Theme) => ({
    centerScreen: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
    },
    error: {
        color: '#FF0000',
    },
    heightInherit: {
        height: 'inherit',
    },
    pageTitle: {
        paddingLeft: '15px',
    },
}));

export default useCommonStyles;
