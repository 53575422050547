import React from 'react';
import useCommonStyles from '../../../util/commonStyles';
import { Box, Typography, Paper } from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';

const Innovation = () => {
    const commonClasses = useCommonStyles();

    return (
        <div>
            <Typography className={commonClasses.pageTitle} sx={{ marginBottom: '1.5rem' }} variant="h4">
                CX Innovation
            </Typography>
                {/* <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                    <CircularProgress />
                    <Typography align={'center'}>Fetching Sites...</Typography>
                </Box> */}
           
        </div>
    );
};

export default Innovation;
