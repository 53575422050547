import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Button, Card, Collapse, Paper, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import VerifiedIcon from '@mui/icons-material/Verified';
import DemoFileUpload from './DemoFileUpload';
import { makeStyles } from '@mui/styles';
import prettyBytes from 'pretty-bytes';
import { useDispatch } from 'react-redux';
import { openDrawer } from '../../../../../app/actions/UiAction';
import CSPCreationTool from './CSPCreationTool';
import CXApproval from './CXApproval';
import WebexAppUse from '../../../../../assets/WebexApp-UseExample.png';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? <Check className="QontoStepIcon-completedIcon" /> : <div className="QontoStepIcon-circle" />}
        </QontoStepIconRoot>
    );
}

const steps = ['Gather Required Screenshots', 'Upload Screenshots', 'CX Approval', 'Submit to Cisco', 'Cisco Approval'];
const docs = [
    {
        uri: require('../../../../../assets/WebexApp-UseExample.png'),
        name: 'WebexApp-UseExample.png',
        size: 227840,
    },
];

const UseStepper = () => {
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = React.useState(0);
    const [workCollapsed, setWorkCollapsed] = React.useState(true);
    const [cspFiles, setCspFiles] = React.useState<File[]>([]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleCSPCreate = () => {
        dispatch(openDrawer({ rightDrawerContent: <CSPCreationTool />, widthDrawer: 900 }));
    };

    return (
        <>
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '90%',
                    maxWidth: '100rem',
                    minWidth: '50rem',
                    borderRadius: '1rem',
                    mt: 1,
                    mb: 2,
                    pt: 2,
                    pb: 1,
                }}>
                <Tooltip followCursor placement="top" title="Click to collapse USE stage details">
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                        }}>
                        <Typography variant="h5">USE(Activate) - Proof of Performance</Typography>
                        <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />} sx={{ width: '100%' }} onClick={() => setWorkCollapsed(!workCollapsed)}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                </Tooltip>
                <Collapse in={workCollapsed} sx={{ width: '90%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', pt: 2 }}>
                        {activeStep === 0 && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography variant="h6">Based on the SKUs listed in this deal, you need to gather the following Screenshots:</Typography>
                                <Box sx={{ alignSelf: 'flex-start', pt: 2, wordWrap: 'normal', maxWidth: '60rem' }}>
                                    <Typography variant="subtitle1">Webex App - Quarterly POP Submission</Typography>
                                    <li style={{ marginLeft: '1rem' }}>
                                        <Typography>
                                            A screenshot from your customer's Cisco Webex Control Hub must include the account name, subscription ID, and workloads.
                                        </Typography>
                                        <Typography variant="caption" sx={{ pl: 2, pr: 2 }}>
                                            Note: The PoP screenshot is only used by the Lifecycle Incentives team to validate the number of activated workloads for Messaging,
                                            Meeting, and/or Calling. Cisco will validate the number of users per month using the “Webex App Usage by Bill To Partner” internal tool.
                                            This is based on activated users that are linked to that specific eligible bookings' subscription ID, and/or organization name.
                                        </Typography>
                                        <img
                                            src={WebexAppUse}
                                            alt="Webex App - Use Example Screenshot"
                                            style={{ margin: '0%', width: '100%', maxWidth: '800px', minHeight: '20vh', maxHeight: '480px' }}
                                        />
                                    </li>
                                </Box>
                            </Box>
                        )}

                        {activeStep === 1 && (
                            <>
                                <DemoFileUpload title="Upload Screenshots:" acceptedFiles={cspFiles} setAcceptedFiles={setCspFiles} />
                            </>
                        )}

                        {activeStep === 2 && <CXApproval doc={docs[0]} />}

                        {activeStep === 3 && (
                            <>
                                <Typography variant="h6">Ready to submit to Cisco:</Typography>
                                <Box sx={{ display: 'flex' }}>
                                    <Paper sx={{ display: 'flex', flexDirection: 'column', maxWidth: '150px', padding: '20px', mr: 2, alignItems: 'center' }}>
                                        <FilePresentIcon sx={{ padding: '20px' }} fontSize="large" />
                                        <Typography textAlign="center">{docs[0].name}</Typography>
                                        <Typography textAlign="center">{prettyBytes(docs[0].size)}</Typography>
                                    </Paper>
                                </Box>
                            </>
                        )}

                        {activeStep === 4 && (
                            <>
                                <Typography variant="h6" sx={{ mt: 2 }}>
                                    Cisco Approval is currently pending
                                </Typography>
                                <Typography variant="h6" sx={{ mt: 2 }}>
                                    This page should update automatically when Cisco approves these documents
                                </Typography>
                                <Button variant="outlined" sx={{ mt: 3 }}>
                                    I certify Cisco has approved this Use Submission
                                </Button>
                            </>
                        )}

                        {activeStep === 5 && (
                            <>
                                <VerifiedIcon color="success" sx={{ width: '4rem', height: '4rem' }} />
                                <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
                                    Use Lifecycle Completed
                                </Typography>
                            </>
                        )}

                        <Box sx={{ display: 'flex', width: '100%' }}>
                            {activeStep < 5 && (
                                <>
                                    <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ m: 2 }}>
                                        Back
                                    </Button>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button onClick={handleNext} sx={{ m: 2 }}>
                                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                    </Button>
                                </>
                            )}
                        </Box>
                    </Box>
                </Collapse>
            </Card>
        </>
    );
};

export default UseStepper;
