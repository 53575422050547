import { Box, Button, Checkbox, FormControlLabel, FormGroup, Tab, Tabs, TextField, Typography } from '@mui/material';
import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomizedInputBase from './CustomizedInputBase';
import Ribbon from './Ribbon';
import { useDispatch } from 'react-redux';
import { closeDrawer, sendToastMessage } from '../../../../../app/actions/UiAction';

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface SolutionProducts {
    [key: string]: Record<string, boolean>;
}

const CSPCreationTool = () => {
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [customerInfoRibbon, setCustomerInfoRibbon] = React.useState(true);
    const [customerInfo, setCustomerInfo] = React.useState({
        'Contact Name': 'Fake Name',
        'Contact Email Address': 'FakeName@geico.com',
        'Contact Phone Number': '301-986-5000',
        'Account Name': 'GOVERNMENT EMPLOYEES INSURANCE COMPANY (GEICO)',
        'Account Address': '4608 WILLARD AVENUE, CHEVY CHASE, MD 20815',
        'Cisco Deal ID': '52914736 // Web Order 90712996',
        'Date of Product Sold': '10/15/2021',
    });
    const [companyOverviewRibbon, setCompanyOverviewRibbon] = React.useState(true);
    const [companyOverview, setCompanyOverview] = React.useState({
        'Company Overview': `The Government Employees Insurance Company is an American auto insurance company with headquarters in Chevy Chase, Maryland. It is the second largest auto insurer in the United States, after State Farm`,
    });
    const [solutionCategory, setSolutionCategory] = React.useState({
        Collaboration: true,
        'Data Center': false,
        'Enterprise Networks': false,
        'Enterprise Networks - Cisco DNA Center': false,
        Security: false,
    });
    const [solutionProduct, setSolutionProduct] = React.useState({
        Collaboration: {
            'Webex App': true,
            'Cloud Calling': false,
        },
        'Data Center': {
            'Application Centric Infrastructure(ACI)': false,
            'D2Ops Activation(Nexus Dashboard)': false,
            'Data Center Network Manager(DCNM)': false,
        },
        'Enterprise Networks': {
            Stealthwatch: false,
            ThousandEyes: false,
            'Identity Services Engine(ISE)': false,
        },
        'Enterprise Networks - Cisco DNA Center': {
            'Campus Software Image Management(SWIM)': false,
            'Network Device Onboarding(PnP)': false,
            'Campus Network Assurance (Assurance)': false,
            'Campus Network Segmentation(SD-A)': false,
            'Scalable Access Policy(SD-A)': false,
        },
        Security: {
            'Secure Workload(formerly Tetration)': false,
            'Secure X': false,
        },
    });
    const [successStoryRibbon, setSuccessStoryRibbon] = React.useState(true);
    const [successStory, setSuccessStory] = React.useState({
        'Project Goal': '',
    });
    const [measureSuccessRibbon, setMeasureSuccessRibbon] = React.useState(true);
    const [measureSuccess, setMeasureSuccess] = React.useState({
        'Business Outcomes and KPIs': '',
        Timeframe: '',
    });
    const [executionPlanRibbon, setExecutionPlanRibbon] = React.useState(true);
    const [executionPlan, setExecutionPlan] = React.useState({
        'Execution Plan': '',
        'Achieved Business Outcome': 'When the project is complete, ePlus will document the achieved business outcomes and what measures we could verify with the customer.',
    });

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue === 5) {
            dispatch(closeDrawer());
            dispatch(sendToastMessage('Your CSP is being completed and will be sent shortly'));
        }
        setSelectedTab(newValue);
    };

    const handleCustomerInfo = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fieldName: string) => {
        setCustomerInfo({ ...customerInfo, [fieldName]: event.target.value });
    };

    const handleCompanyOverview = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fieldName: string) => {
        setCompanyOverview({ ...companyOverview, [fieldName]: event.target.value });
    };

    const handleSuccessStory = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fieldName: string) => {
        setSuccessStory({ ...successStory, [fieldName]: event.target.value });
    };

    const handleMeasureSuccess = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fieldName: string) => {
        setMeasureSuccess({ ...measureSuccess, [fieldName]: event.target.value });
    };

    const handleExecutionPlan = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fieldName: string) => {
        setExecutionPlan({ ...executionPlan, [fieldName]: event.target.value });
    };

    const handleSolutionCategory = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fieldName: string, fieldValue: boolean) => {
        setSolutionCategory({ ...solutionCategory, [fieldName]: fieldValue });
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '80vh' }}>
            <Typography variant="h4" sx={{ p: 1 }}>
                Create Your CSP
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                <Tabs value={selectedTab} onChange={handleChange} aria-label="basic tabs example" sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <Tab label="Customer Info" {...a11yProps(0)} sx={{ flexGrow: 1 }} />
                    <Tab label="Overview and Solution" {...a11yProps(1)} sx={{ flexGrow: 1 }} />
                    <Tab label="Success Story" {...a11yProps(2)} sx={{ flexGrow: 1 }} />
                    <Tab label="Measure Success" {...a11yProps(3)} sx={{ flexGrow: 1 }} />
                    <Tab label="Execution Plan" {...a11yProps(4)} sx={{ flexGrow: 1 }} />
                </Tabs>
            </Box>
            {selectedTab === 0 && (
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
                    <Ribbon
                        message="This information has been pre-populated from Cisco Funds Manager - verify and edit as needed"
                        showRibbon={customerInfoRibbon}
                        setHidden={setCustomerInfoRibbon}
                        color="green"
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-evenly',
                            alignItems: 'flex-start',
                            width: '70%',
                            height: '50vh',
                            minHeight: '35rem',
                        }}>
                        <Typography variant="h6">Enter the following Customer Details:</Typography>
                        {Object.entries(customerInfo as { [key: string]: string }).map(([key, value]) => (
                            <CustomizedInputBase name={key} value={value} handleChange={handleCustomerInfo} />
                        ))}
                    </Box>
                </Box>
            )}
            {selectedTab === 1 && (
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
                    <Ribbon
                        message="This information has been pre-generated based on data from OSIT-BOM - verify and edit as needed"
                        showRibbon={companyOverviewRibbon}
                        setHidden={setCompanyOverviewRibbon}
                        color="green"
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            width: '90%',
                            height: '68vh',
                            minHeight: '35rem',
                            overflow: 'hidden',
                            overflowY: 'auto',
                            pt: 2,
                        }}>
                        <Typography variant="h6" sx={{ mb: 1 }}>
                            Enter a Company Overview:
                        </Typography>
                        {Object.entries(companyOverview as { [key: string]: string }).map(([key, value]) => (
                            <CustomizedInputBase multiline={true} minLines={8} maxLines={20} name={key} value={value} handleChange={handleCompanyOverview} />
                        ))}
                        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                            Select the Category and Products:
                        </Typography>
                        <FormGroup>
                            {Object.entries(solutionCategory as { [key: string]: boolean }).map(([key, value]) => (
                                <>
                                    <FormControlLabel control={<Checkbox checked={value} onChange={(event) => handleSolutionCategory(event, key, !value)} />} label={key} />
                                    {value && (
                                        <Box sx={{ ml: 4, display: 'flex', flexDirection: 'column' }}>
                                            {Object.entries(solutionProduct[key as 'Collaboration'] as { [key: string]: boolean }).map(([key, value]) => (
                                                <FormControlLabel
                                                    control={<Checkbox checked={value} onChange={(event) => handleSolutionCategory(event, key, !value)} />}
                                                    label={key}
                                                />
                                            ))}
                                        </Box>
                                    )}
                                </>
                            ))}
                        </FormGroup>
                    </Box>
                </Box>
            )}
            {selectedTab === 2 && (
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
                    <Box sx={{ height: '4rem' }} />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            width: '90%',
                            minHeight: '35rem',
                            pt: 2,
                        }}>
                        <Typography variant="h6" sx={{ mb: 1 }}>
                            Enter the Project Goal:
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', ml: 1, mb: 2 }}>
                            <InfoOutlinedIcon />
                            <Typography variant="caption">
                                This should be a paragraph explaining challenges the customer is having that this solution should solve and how it will solve it
                            </Typography>
                        </Box>
                        {Object.entries(successStory as { [key: string]: string }).map(([key, value]) => (
                            <Box sx={{ width: '100%', mb: 2 }}>
                                <CustomizedInputBase multiline={true} minLines={8} maxLines={20} name={key} value={value} handleChange={handleSuccessStory} />
                            </Box>
                        ))}
                    </Box>
                </Box>
            )}
            {selectedTab === 3 && (
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
                    <Box sx={{ height: '4rem' }} />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            width: '90%',
                            minHeight: '35rem',
                            pt: 2,
                        }}>
                        <Typography variant="h6" sx={{ mb: 1 }}>
                            Enter details about how we will measure success and when:
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', ml: 1, mb: 2 }}>
                            <InfoOutlinedIcon />
                            <Typography variant="caption">
                                Business Outcomes are the goals we expect to achieve, and KPIs are how we will measure to verify the goal is achieved
                            </Typography>
                        </Box>
                        {Object.entries(measureSuccess as { [key: string]: string }).map(([key, value]) => (
                            <Box sx={{ width: '100%', mb: 2 }}>
                                <CustomizedInputBase multiline={true} minLines={4} maxLines={20} name={key} value={value} handleChange={handleMeasureSuccess} />
                                {key === 'Business Outcomes and KPIs' && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            width: '95%',
                                            m: 1,
                                            p: 1,
                                            borderRadius: '1rem',
                                            backgroundColor: '#67d380',
                                        }}>
                                        <Typography variant="body2" sx={{ width: '100%', whiteSpace: 'pre-wrap', p: 1 }}>
                                            Based on the products selected, here are some common Business Outcomes and Associated KPIs
                                            <Box sx={{ m: 1 }}>
                                                <b>Outcome:</b> OpEx Cost Containment
                                                <Box sx={{ ml: 2 }}>
                                                    KPIs could be measured through - Financial Statements & Reports
                                                    <Box sx={{ ml: 2 }}>
                                                        <li>Reduced license and admin cost</li>
                                                        <li>Month over month reduction in overages and consolidated invoices</li>
                                                        <li>20% Growth Allotment and Savings</li>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <b>Outcome:</b> Improve Business Process Agility
                                                <Box sx={{ ml: 2 }}>
                                                    KPIs could be measured through - Usability Metrics
                                                    <Box sx={{ ml: 2 }}>
                                                        <li>Integration with Outlook, and a desktop application</li>
                                                        <li>Instructions to end-users on installing Webex Meeting desktop</li>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <b>Outcome:</b> Simplified Licensing Management
                                                <Box sx={{ ml: 2 }}>
                                                    KPIs could be measured through - Smart Account Reports
                                                    <Box sx={{ ml: 2 }}>
                                                        <li>Smart Account Administration & User Role Setup</li>
                                                        <li>Provisioning of licenses for Cisco Unified Collaboration Apps to include CUCM and Unity Connection</li>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <b>Outcome:</b> Enhanced Visibility & Utilization
                                                <Box sx={{ ml: 2 }}>
                                                    KPIs could be measured through - Webex Utilization Data/Telemetry
                                                    <Box sx={{ ml: 2 }}>
                                                        <li>Meeting Usage: # of meetings, # of minutes and # of participants</li>
                                                        <li>Meeting Quality: Average Join Time & VoIP Quality</li>
                                                        <li>Number of meetings created using Webex Productivity Tools</li>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <b>Outcome:</b> User Self-Proficiency
                                                <Box sx={{ ml: 2 }}>
                                                    KPIs could be measured through - Usability Metrics
                                                    <Box sx={{ ml: 2 }}>
                                                        <li>Easy to consume User Guide Documentation</li>
                                                        <li>Number of meetings</li>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Typography>
                                    </Box>
                                )}
                                {key === 'Timeframe' && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            width: '95%',
                                            m: 1,
                                            p: 1,
                                            borderRadius: '1rem',
                                            backgroundColor: '#67d380',
                                        }}>
                                        <Typography variant="body2" sx={{ width: '100%', whiteSpace: 'pre-wrap', p: 1 }}>
                                            Based on other EA Webex Meeting CSPs, the timeframe is typically listed as:
                                            <br />
                                            <b>"Review licensing and validate outcome measures within 180 to 365 days"</b>
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        ))}
                    </Box>
                </Box>
            )}
            {selectedTab === 4 && (
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
                    <Box sx={{ height: '4rem' }} />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            width: '90%',
                            minHeight: '35rem',
                            pt: 2,
                        }}>
                        <Typography variant="h6" sx={{ mb: 1 }}>
                            Enter the Execution/Adoption Plan:
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', ml: 1, mb: 2 }}>
                            <InfoOutlinedIcon />
                            <Typography variant="caption">
                                Enter the steps you plan to complete with the customer to ensure successful adoption to meet the goals identified
                            </Typography>
                        </Box>
                        {Object.entries(executionPlan as { [key: string]: string }).map(([key, value]) => (
                            <Box sx={{ width: '100%', mb: 2 }}>
                                <CustomizedInputBase multiline={true} minLines={8} maxLines={20} name={key} value={value} handleChange={handleExecutionPlan} />
                            </Box>
                        ))}
                    </Box>
                </Box>
            )}
            <Box sx={{ marginTop: 'auto', display: 'flex', justifyContent: 'space-between', width: '90%', mb: 2 }}>
                <Button disabled={selectedTab === 0} onClick={(event) => handleChange(event, selectedTab - 1)}>
                    Previous
                </Button>
                <Button onClick={(event) => handleChange(event, selectedTab + 1)}>{selectedTab === 4 ? 'Submit' : 'Next'}</Button>
            </Box>
        </Box>
    );
};

export default CSPCreationTool;
