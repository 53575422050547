import portal_home from './PortalHome.png';

const Home = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <img src={portal_home} alt="Portal Home" style={{ width: '95%', maxWidth: 1600 }} />
        </div>
    );
};

export default Home;
