import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import React from 'react';
import { Field, Form } from 'react-final-form';
import TextFieldWrapper from '../../../../components/assessments/TextFieldFinalFormWrapper';
import useCommonStyles from '../../../../util/commonStyles';
import { composeValidators, isEmail, required } from '../../../../util/finalFormValidators';
import useFormStyles from '../../../../util/formStyles';
import { makeStyles } from '@mui/styles';
import SelectFieldWrapper from '../../../../components/assessments/questions/selectField';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    grayText: {
        color: theme.palette.grey[600],
    },
    grayInput: {
        background: 'rgba(0, 0, 0, 0.08)',
    },
    dropdown: {
        padding: theme.spacing(3, 3, 0, 2),
        width: '40%',
    },
}));

const CustomAssessmentDetailsForm = ({ submit, ERRData, customFields, submitting, submitHeaderError }) => {
    const classes = useStyles();
    const commonClasses = useCommonStyles();
    const formClasses = useFormStyles();
    const { authState, oktaAuth } = useOktaAuth();
    const [userInfo, setUserInfo] = React.useState(null);

    React.useEffect(() => {
        if (!authState || !authState.isAuthenticated) {
            // When user isn't authenticated, forget any user info
            setUserInfo(null);
        } else {
            oktaAuth.getUser().then((info) => {
                setUserInfo(info);
            });
        }
    }, [authState, oktaAuth]);

    return (
        <Box className={commonClasses.centerScreen} p={1} sx={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '80rem' }}>
            <Typography variant="h6">Enter your Assessment Details</Typography>
            <Form
                initialValues={{
                    email: userInfo?.email,
                }}
                onSubmit={submit}
                style={{ width: '100%' }}
                render={({ handleSubmit, pristine, invalid }) => (
                    <form className={commonClasses.centerScreen} style={{ width: '100%' }} onSubmit={handleSubmit}>
                        <Box width="100%" maxWidth="50rem">
                            <div>
                                <Box sx={{ display: 'flex', alignItems: 'center' }} p={1}>
                                    <Field
                                        sx={{ flex: 1 }}
                                        className={formClasses.formField}
                                        label="Name"
                                        name="description"
                                        validate={composeValidators(required)}
                                        component={TextFieldWrapper}
                                        placeholder="(ex. New Analytics Dashboard Idea)"
                                        InputLabelProps={{ shrink: true }}
                                        required={true}
                                    />
                                </Box>
                            </div>
                            <div>
                                <Box sx={{ display: 'flex', alignItems: 'center' }} p={1}>
                                    <Field
                                        sx={{ flex: 1 }}
                                        className={formClasses.formField}
                                        label="Details"
                                        name="details"
                                        validate={composeValidators(required)}
                                        component={TextFieldWrapper}
                                        multiline
                                        minRows={3}
                                        placeholder="Enter a short description"
                                        InputLabelProps={{ shrink: true }}
                                        required={true}
                                    />
                                </Box>
                            </div>
                            <div>
                                <Box className={classes.dropdown} sx={{ display: 'flex', alignItems: 'center' }} p={1}>
                                    <Field
                                        sx={{ flex: 1 }}
                                        className={formClasses.formField}
                                        showLabel="Focus Area"
                                        label="Focus Area"
                                        name="focus"
                                        validate={composeValidators(required)}
                                        component={SelectFieldWrapper}
                                        options={['New Solution', 'Solution Enhancement', 'Back Office Improvement', 'CX']}
                                        InputLabelProps={{ shrink: true }}
                                        required={true}
                                    />
                                </Box>
                            </div>
                            <div>
                                <Box sx={{ display: 'flex', alignItems: 'center' }} p={1}>
                                    <Field
                                        sx={{ flex: 0.5 }}
                                        className={formClasses.formField}
                                        label="Contact Email"
                                        name="contactEmail"
                                        validate={composeValidators(isEmail)}
                                        component={TextFieldWrapper}
                                        placeholder="(ex. first.last@email.com)"
                                        InputLabelProps={{ shrink: true }}
                                        required={true}
                                        variant="outlined"
                                    />
                                </Box>
                            </div>
                            <div>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    disabled={pristine || invalid || submitting}
                                    className={classes.button}
                                    style={{
                                        color: 'white',
                                        margin: '1rem',
                                    }}>
                                    {submitting ? (
                                        <>
                                            Submitting
                                            <CircularProgress sx={{ marginLeft: '1rem' }} size={20} />
                                        </>
                                    ) : (
                                        'Submit'
                                    )}
                                </Button>
                                {submitHeaderError ? <Typography className={commonClasses.error}>Failed to submit. Please try again.</Typography> : null}
                            </div>
                        </Box>
                    </form>
                )}
            />
        </Box>
    );
};

export default CustomAssessmentDetailsForm;
