import React from 'react';
import { makeStyles } from '@mui/styles';
import { Paper, Typography } from '@mui/material';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import prettyBytes from 'pretty-bytes';

const useStyles = makeStyles((theme) => ({
    fileBackground: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '150px',
        padding: '20px',
        alignItems: 'center',
        wordWrap: 'anywhere',
    },
    fileIcon: {
        padding: '20px',
    }
}));

const FileDetails = ({ file, fileName, setFileName }) => {
    const classes = useStyles()

    return (
        <div className="file-details">
            <Paper className={classes.fileBackground}>
                <FilePresentIcon className={classes.fileIcon} fontSize='large' />
                <Typography textAlign='center'>{fileName}</Typography>
                <Typography textAlign='center'>{prettyBytes(file.size)}</Typography>
            </Paper>
        </div>
    );
}

export default FileDetails;