import React from 'react';
import { useDispatch, connect } from 'react-redux';
import { Drawer } from '@mui/material';
import { closeDrawer } from '../app/actions/UiAction';
import { makeStyles } from '@mui/styles';

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
    rightDrawer: (props) => ({
        width: props.widthDrawer,
        maxWidth: '80%',
        flexShrink: 0,
        whiteSpace: 'nowrap',
    }),
}));

const RightDrawer = ({ rightDrawerContent, widthDrawer = 625 }) => {
    const dispatch = useDispatch();
    const classes = useStyles({ widthDrawer });
    //classes.rightDrawer.width = widthDrawer;

    const handleDrawer = (event, reason) => {
        dispatch(closeDrawer());
    };

    return (
        <Drawer
            classes={{
                paper: classes.rightDrawer,
            }}
            anchor="right"
            open={rightDrawerContent !== null}
            onClose={handleDrawer}>
            {rightDrawerContent}
        </Drawer>
    );
};

const mapStateToProps = (state) => ({
    rightDrawerContent: state.Ui.rightDrawerContent.rightDrawerContent,
    widthDrawer: state.Ui.rightDrawerContent.widthDrawer,
});

export default connect(mapStateToProps)(RightDrawer);
