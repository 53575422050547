import { combineReducers } from 'redux';
import UiReducer from './UiReducer';
import { maturityModelApi } from '../MaturityModelApi';
import { dataProtectionApi } from '../DataProtectionApi';
import { sitesApi } from '../../modules/spoa/practiceSite/SitesApi';
import { subscriptionsApi } from '../SubscriptionsApi';
import { portalApi } from '../PortalApi';
import User from './User';

const reducers = combineReducers({
    Ui: UiReducer,
    User: User,
    [maturityModelApi.reducerPath]: maturityModelApi.reducer,
    [dataProtectionApi.reducerPath]: dataProtectionApi.reducer,
    [subscriptionsApi.reducerPath]: subscriptionsApi.reducer,
    [sitesApi.reducerPath]: sitesApi.reducer,
    [portalApi.reducerPath]: portalApi.reducer,
    //other reducers come here...
});

export default reducers;
