import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import DragDrop from '../../../../upload/components/DragDrop';
interface IProps {
    title: string;
    acceptedFiles: File[];
    setAcceptedFiles: React.Dispatch<React.SetStateAction<File[]>>;
}
const DemoFileUpload = ({ title, acceptedFiles, setAcceptedFiles }: IProps) => {
    const [fileName, setFileName] = React.useState('');
    //const [loading, setLoading] = useState(false);
    // const [acceptedFiles, setAcceptedFiles] = React.useState([]);
    const [addingNewFile, setAddingNewFile] = React.useState(true);
    const [selection, setSelection] = React.useState(null);

    console.log(acceptedFiles);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ pt: 3, pl: 3 }}>
                {title}
            </Typography>
            <DragDrop
                fileName={fileName}
                //loading={loading}
                setAcceptedFiles={setAcceptedFiles}
                setFileName={setFileName}
                addingNewFile={addingNewFile}
                setAddingNewFile={setAddingNewFile}
            />
        </Box>
    );
};

export default DemoFileUpload;
