import React, { useEffect, useState } from 'react';
import { Typography, Button, CircularProgress, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { useGetAssessmentsMutation } from '../../../app/DataProtectionApi';
import useCommonStyles from '../../../util/commonStyles';
import AssessmentOverview from './AssessmentOverview';
import { getDateFromCosmosString } from '../../../util/utilFunctions';
import NewAssessment from './NewAssessment';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    container: {
        display: 'flex',
        padding: '5px',
    },
    details: {
        padding: '0px 10px',
    },
}));

const PresalesOverview = () => {
    const classes = useStyles();
    const commonClasses = useCommonStyles();
    const [rows, setRows] = useState(null);
    const [resetButton, setResetButton] = useState(false);
    const [resetAction, setResetAction] = useState(false);

    const [getAssessments, { isLoading: isGettingAssessments, data, error: getAssessmentsError }] = useGetAssessmentsMutation();

    React.useMemo(() => setRows(data), [data]);

    useEffect(() => {
        getAssessments({ type: 'overview' });
    }, [getAssessments]);

    React.useMemo(() => {
        if (data) {
            const modifiedData = _.cloneDeep(data);
            modifiedData?.forEach((assessment) => {
                assessment.last_edited = getDateFromCosmosString(assessment.last_edited);
                assessment.submitted_at = getDateFromCosmosString(assessment.submitted_at);
                assessment.answered_at = getDateFromCosmosString(assessment.answered_at);
            });
            setRows(modifiedData);
        } else {
            setRows(data);
        }
    }, [data]);

    return (
        <div className={classes.root}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography className={commonClasses.pageTitle} variant="h4">
                        Pre-Sales Assessments Overview
                    </Typography>
                    <NewAssessment />
                </Box>
                <Button variant="contained" sx={{ visibility: resetButton ? 'visible' : 'hidden', margin: '.5rem' }} onClick={() => setResetAction(true)}>
                    Clear All Filters
                </Button>
            </Box>

            {!isGettingAssessments && rows !== undefined ? (
                <AssessmentOverview rows={rows} setResetButton={setResetButton} resetAction={resetAction} setResetAction={setResetAction} />
            ) : (
                <div className={commonClasses.centerScreen}>
                    {getAssessmentsError ? <Typography className={commonClasses.error}>Failed to get your assessments. Please try again.</Typography> : <CircularProgress />}
                </div>
            )}
        </div>
    );
};

export default PresalesOverview;
