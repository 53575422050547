import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { Backdrop, Paper, Typography, Button } from '@mui/material';
import useCommonStyles from '../../util/commonStyles';

const Logout = () => {
    const commonClasses = useCommonStyles();

    return (
        <Backdrop sx={{ color: '#fff', zIndex: 9999 }} open={true}>
            <Paper sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                <Typography className={commonClasses.pageTitle} variant="h6">
                    You've logged out.
                </Typography>
                <Button component={NavLink} to="/" selected={useRouteMatch('/')?.isExact}>
                    Sign Back In
                </Button>
            </Paper>
        </Backdrop>
    );
};

export default Logout;
