export const addSite = ({ selectedSite, findSiteById, addSiteReq, setAddedSitesArr, addedSitesArr, practice, practiceIds, searchSiteList }) => {
    const siteInfo = findSiteById({ id: selectedSite.id, searchSiteList: searchSiteList });
    const payload = {
        site_id: siteInfo.site_id,
        web_id: siteInfo.web_id,
        practice_id: practiceIds[practice],
    };
    if (siteInfo) {
        addSiteReq({ body: payload });
        setAddedSitesArr([...addedSitesArr, { site_id: siteInfo.site_id, web_url: siteInfo.web_url, web_id: siteInfo.web_id }]);
    }
};

export const findSiteById = ({ id, searchSiteList }) => {
    if (searchSiteList) {
        const site = searchSiteList.filter((item) => item.web_id === id)[0];
        return site;
    } else return null;
};

export const deleteSite = ({ siteId, deleteSiteReq }) => {
    deleteSiteReq({ id: siteId });
};

export const getSuggestions = (searchSiteList) => {
    const allSuggestions = [];
    const cache = {};

    for (let i = 0; i < searchSiteList.length; i++) {
        if (!cache[searchSiteList[i].display_name] && searchSiteList[i].display_name !== null) {
            cache[searchSiteList[i].display_name] = true;
            allSuggestions.push({ label: String(searchSiteList[i].display_name), id: String(searchSiteList[i].web_id) });
        }
    }
    return allSuggestions;
};
