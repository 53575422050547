export const sendToastMessage = (msg) => {
    return (dispatch) => dispatch({ type: 'TOAST_MESSAGE', payload: msg });
};

export const closeToastMessage = () => {
    return (dispatch) => dispatch({ type: 'TOAST_MESSAGE', payload: null });
};

export const openDrawer = ({ rightDrawerContent, widthDrawer }) => {
    return (dispatch) => dispatch({ type: 'OPEN_DRAWER', payload: { rightDrawerContent, widthDrawer } });
};

export const closeDrawer = () => {
    return (dispatch) => dispatch({ type: 'OPEN_DRAWER', payload: { rightDrawerContent: null } });
};
