import React from 'react';
import { makeStyles } from '@mui/styles';
import TableauDashboard from '../../components/TableauDashboard';
import { useGetTableauDashboardMutation } from '../../app/PortalApi';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
}));

const DASHBOARD_NAME = 'MSC Overview';

const MscOverviewDashboard = () => {
    const classes = useStyles();
    const [vizUrl, setVizUrl] = React.useState<string | null>(null);
    const [jwt, setJwt] = React.useState<string | null>(null);

    const [getDashboard, { data, isLoading, isError }] = useGetTableauDashboardMutation();

    React.useEffect(() => {
        getDashboard(DASHBOARD_NAME);
    }, [getDashboard]);

    React.useEffect(() => {
        if (data) {
            setVizUrl(data?.dashboard_url);
            setJwt(data?.jwt);
        }
        return () => {
            setVizUrl(null);
            setJwt(null);
        };
    }, [data]);

    return (
        <div className={classes.root}>
            <TableauDashboard dashboardUrl={vizUrl} userJWT={jwt} loading={isLoading} error={isError} height={'900px'} width={'1600px'} />
        </div>
    );
};

export default MscOverviewDashboard;
