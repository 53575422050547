import React from 'react';
import { Box, Card, CardMedia, Paper, Typography } from '@mui/material';
import InnovationMaze from '../../../../assets/InnovationMaze.png';
import SvgBackground from './SvgBackground.svg';
import LifecycleTable from './LifecycleTable';

const EditableIntro = ({ editable }: { editable: boolean }) => {
    return (
        <Paper elevation={2} sx={{ borderRadius: '10px' }}>
            <Card sx={{ boxShadow: 'none', backgroundImage: `url(${SvgBackground})` }}>
                <Typography variant="h4" sx={{ margin: '1rem', color: '#FFFFFF' }}>
                    The Product Owner: Navigation the Product Lifecycle
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box sx={{ maxWidth: '60%', margin: ' 0 1rem 1rem 1rem' }}>
                        <Typography sx={{ paddingBottom: '1rem', color: '#FFFFFF' }}>
                            Driving force behind bringing new products to market. From idea to release and beyond, responsible for ensuring that the product meets the needs of
                            target audiences and drives business growth.
                        </Typography>
                        <LifecycleTable />
                    </Box>
                    <Box sx={{ margin: '3rem 1rem 1rem 1rem' }}>
                        <CardMedia>
                            <img src={InnovationMaze} alt="NextSteps" style={{ borderRadius: '5px', width: '100%', minWidth: '400px', maxWidth: '1200px' }} />
                        </CardMedia>
                        <Box sx={{ marginTop: '1rem', padding: '.5rem 4rem', backgroundColor: '#00d4a6', borderRadius: '5px', textAlign: 'center' }}>
                            <Typography sx={{ fontSize: '1.5rem', color: '#ffffff' }}>With a solid strategy and a focus on innovation, you'll lead our team to success.</Typography>
                        </Box>
                    </Box>
                </Box>
            </Card>
        </Paper>
    );
};

export default EditableIntro;
