/* eslint-disable no-nested-ternary */
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useCommonStyles from '../../../../util/commonStyles';
import { sendToastMessage } from '../../../../app/actions/UiAction';
import CustomAssessmentDetailsForm from './CustomAssessmentDetailsForm';
import EditableIntro from './EditableIntro';

const CustomAssessmentStart = ({ assessmentRoute, serviceId, useAddHeaderMutation, customFields }) => {
    const dispatch = useDispatch();
    const commonClasses = useCommonStyles();
    const history = useHistory();
    const [submitted, setSubmitted] = React.useState(false);

    const [submitHeader, { isLoading: submitting, error: submitHeaderError, data }] = useAddHeaderMutation();

    const submit = async ({ name, customer_id, description, details, focus, email, contactEmail, errNumber }) => {
        submitHeader({
            description,
            details,
            focus,
            email,
            service_id: serviceId,
            customer_email: contactEmail,
            customer_id: 'c5b08bab-e0cc-4185-b597-d171581d161e',
            name: 'ePlus',
            ERR: '00000',
        })
            .unwrap()
            .then((res) => {
                setSubmitted(true);
            })
            .catch((error) => {
                dispatch(sendToastMessage('Failed to create assessment.'));
            });
    };

    // TODO: updated field name from maturity_header_id
    const assessmentHeaderId = React.useMemo(() => data?.id, [data]);

    return (
        <div className={commonClasses.centerScreen} style={{ width: '100%' }}>
            {!submitted && <CustomAssessmentDetailsForm submit={submit} submitting={submitting} submitHeaderError={submitHeaderError} />}
            {submitted && (
                <Box sx={{ display: 'flex', margin: 'auto', alignItems: 'center', flexDirection: 'column', maxWidth: '1350px', minWidth: '975px' }}>
                    <EditableIntro />
                    <Button variant="contained" sx={{ margin: '1rem' }} onClick={() => history.push(`/${assessmentRoute}/create/${assessmentHeaderId}/form`)}>
                        <Typography>Begin My Assessment</Typography>
                    </Button>
                </Box>
            )}
        </div>
    );
};

export default CustomAssessmentStart;
