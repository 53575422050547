import { OktaAuth } from '@okta/okta-auth-js';

const oktaAuth = new OktaAuth({
    issuer: `https://eplus.okta.com/`,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    responseType: 'code',
    pkce: true,
    redirectUri: `${window.location.origin}/login/callback`,
    postLogoutRedirectUri: `${window.location.origin}/logout`,
});

export default oktaAuth;
