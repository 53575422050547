import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { Field } from 'react-final-form';
import { useGetCustomersQuery, useAddCustomerMutation, useAddHeaderMutation } from '../../../app/DataProtectionApi';
import AssessmentStart from '../../../components/assessments/AssessmentStart';
import { composeValidators, isEmail } from '../../../util/finalFormValidators';
import TextFieldWrapper from '../../../components/assessments/TextFieldFinalFormWrapper';
import useFormStyles from '../../../util/formStyles';
import useCommonStyles from '../../../util/commonStyles';

const SERVICE_ID = `e11f6cd7-7989-484e-9c09-792240af01b2`;

const AvaPreSalesStart = ({ match }) => {
    const formClasses = useFormStyles();
    const commonClasses = useCommonStyles();

    const customFields = () => {
        return (
            <>
                <Box sx={{ display: 'flex', alignItems: 'center' }} p={1}>
                    <Field
                        sx={{ flex: 0.5 }}
                        className={formClasses.formField}
                        label="Contact Email"
                        name="contactEmail"
                        validate={composeValidators(isEmail)}
                        component={TextFieldWrapper}
                        placeholder="(ex. first.last@email.com)"
                        InputLabelProps={{ shrink: true }}
                        required={true}
                        variant="outlined"
                    />
                </Box>
            </>
        );
    };

    return (
        <>
            <Typography className={commonClasses.pageTitle} variant="h4">
                AVA Pre-Sales Assessment
            </Typography>
            <Paper>
                <AssessmentStart
                    match={match}
                    assessmentRoute={'pre-sales/ava-collab'}
                    serviceId={SERVICE_ID}
                    useGetCustomersQuery={useGetCustomersQuery}
                    useAddCustomerMutation={useAddCustomerMutation}
                    useAddHeaderMutation={useAddHeaderMutation}
                    customFields={customFields()}
                />
            </Paper>
        </>
    );
};

export default AvaPreSalesStart;
