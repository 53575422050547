import isInt from 'validator/lib/isInt';
import isFloat from 'validator/lib/isFloat';
import isEmailValidator from 'validator/lib/isEmail';

export const required = (value: any) => (value !== undefined && value !== null && value !== '' ? undefined : 'Required');

export const notEmpty = (value: string) => (value !== '' ? undefined : 'Required');

export const minLen = (len: number) => (value: string) => {
    return value?.length < len ? `Must have at least ${len} characters` : undefined;
};

export const composeValidators =
    (...validators: any[]) =>
    (value: any) =>
        validators.reduce((error, validator) => error || validator(value), undefined);

export const isBetween = (min: number, max: number) => (value: string | number) => {
    return !isInt(value.toString(), { min, max }) ? `Must be between ${min} and ${max}` : undefined;
};

export const isEmail = (value?: string) => (isEmailValidator(value || '') ? undefined : 'You must enter a valid email');

export const isNumber = (value: string) => {
    return (value === '' || isInt(value.toString()) || isFloat(value.toString()) ? undefined : 'Must be a number')
}