import { createTheme } from '@mui/material'
// import type {} from '@material-ui/lab/themeAugmentation';

const AppTheme = createTheme({
    palette: {
        primary: { main: '#114989' },
        secondary: { main: '#00D4A6' },
        error: { main: '#FF0000'},
    },
});

export default AppTheme;