import { BlobServiceClient } from '@azure/storage-blob';
import axios from 'axios';
import oktaAuth from '../modules/auth/OktaAuth';

const GetBlobServiceClient = async () => {
    const options = {
        method: 'get',
        url: `${process.env.REACT_APP_DATA_PROTECTION_URL}GenerateSAS` || `https://innovation-portal-data-security.azure-api.net/dev/GenerateSAS`,
        headers: {
            Authorization: `Bearer ${oktaAuth.getIdToken()}`,
            'Access-Control-Allow-Origin': '*',
        },
    };
    const res = await axios(options);
    return new BlobServiceClient(res.data);
};

export const getDownloadLink = async (container, blob) => {
    const blobServiceClient = await GetBlobServiceClient();
    const containerClient = blobServiceClient.getContainerClient(container);
    const blobClient = containerClient.getBlobClient(blob);
    return blobClient.url;
};
