import React from 'react';
import { Card, Typography } from '@mui/material';
import LifecycleTable from './LifecycleTable';

const InnovationIntakeInfo = () => {
    return (
        <div style={{ width: '100%' }}>
            <Typography sx={{ margin: '1rem', flex: '1 1 100%', fontWeight: 'bold' }} variant="h5" component="div">
                Next Steps...
            </Typography>
            <Card sx={{ margin: '.5%', boxShadow: 'none' }}>
                <LifecycleTable />
            </Card>
        </div>
    );
};

export default InnovationIntakeInfo;
