import * as React from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
// import { getDownloadLink } from '../../../util/blob';
import { List, ListItem, Popover } from '@mui/material';
import { getDateFromCosmosString } from '../../../../util/utilFunctions';

const useStyles = makeStyles((theme) => ({
    metadataCard: { flexGrow: 1, minWidth: 275, margin: 2 },
    buttons: {
        margin: '2%',
    },
}));

const Metadata = ({ data, additionalButtons }) => {
    const history = useHistory();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [downloadLinks, setDownloadLinks] = React.useState({});
    const [resumeLink, setResumeLink] = React.useState();
    const openDowloadMenu = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        switch (data.service ? data.service : data.service_name) {
            case `Data Protection`:
                setDownloadLinks({
                    assessment: 'ePlus_Data_Protection_Report.docx',
                    proposalPDF: 'ePlus Managed Backup Budgetary Proposal.pdf',
                    proposalPPT: 'ePlus Managed Backup Budgetary Proposal.pptx',
                });
                setResumeLink(`/pre-sales/data-protection/create/${data.id}/form`);
                break;
            case `AVA for Collaboration Spaces`:
                setDownloadLinks({
                    assessment: 'ePlus_AVA_Report.docx',
                    proposalPDF: 'AVA_managed_spaces_proposal_final.pdf',
                    proposalPPT: 'AVA_managed_spaces_proposal_final.pptx',
                });
                setResumeLink(`/pre-sales/ava-collab/create/${data.id}/form`);
                break;
            case `Innovation Intake`:
                setDownloadLinks({
                    assessment: 'Innovation_Intake.docx',
                    proposalPDF: 'Innovation_Intake.pdf',
                    proposalPPT: 'Innovation_Intake.pptx',
                });
                setResumeLink(`/innovation/intake/create/${data.id}/form`);
                break;
            default:
                break;
        }
    }, [data]);

    const formatDate = (dateString) => {
        const dateVal = new Date(dateString);

        return `${dateVal.toLocaleDateString()}, ${dateVal.toLocaleTimeString()}`;
    };

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Card className={classes.metadataCard} raised={true}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <CardContent sx={{ flex: 4 }}>
                            <Typography variant="h6">Details</Typography>
                            <Typography sx={{ fontSize: 16 }} gutterBottom>
                                <b>Plan Id:</b> {data.plan_id}
                            </Typography>
                            <Typography sx={{ fontSize: 16 }} gutterBottom>
                                <b>Deal Id:</b> {data.cisco_deal_id}
                            </Typography>
                            <Typography sx={{ fontSize: 16 }} gutterBottom>
                                <b>CR Party Name:</b> {data.cr_party_name}
                            </Typography>
                            <Typography sx={{ fontSize: 16 }} gutterBottom>
                                <b>Stage:</b> {data.stage}
                            </Typography>
                            <Typography sx={{ fontSize: 16 }} gutterBottom>
                                <b>Incentive Eligibility Status:</b> {data.incentive_eligibility_status}
                            </Typography>
                            <Typography sx={{ fontSize: 16 }} gutterBottom>
                                <b>Lifecycle Opt-in Status:</b> {data['lifecycle_opt-in_status']}
                            </Typography>
                            <Typography sx={{ fontSize: 16 }} gutterBottom>
                                <b>Eligible Incentive Activity Description and Estimated Incentive Value:</b>
                                {data['eligible_incentive_activity_description_and_estimated_incentive_value']}
                            </Typography>
                        </CardContent>
                        <CardContent sx={{ flex: 3 }}>
                            <Typography sx={{ paddingBottom: 3 }}></Typography>
                            <Typography sx={{ fontSize: 16 }} gutterBottom>
                                <b>Booking PO #:</b> {data.booking_po_number}
                            </Typography>
                            <Typography sx={{ fontSize: 16 }} gutterBottom>
                                <b>Deal Incentive Expiry Date:</b> {data.deal_incentive_expiry_date}
                            </Typography>
                            <Typography sx={{ fontSize: 16 }} gutterBottom>
                                <b>Booking Amount-Net to Cisco:</b> {'$'}
                                {data['booking_amount-net_to_cisco']}
                            </Typography>
                            <Typography sx={{ fontSize: 16 }} gutterBottom>
                                <b>Max Estimated Incentives Amount:</b> {'$'}
                                {data.max_estimated_incentives_amount}
                            </Typography>
                            <Typography sx={{ fontSize: 16 }} gutterBottom>
                                <b>Track:</b> {data.track}
                            </Typography>
                            <Typography sx={{ fontSize: 16 }} gutterBottom>
                                <b>Sub-Track:</b> {data['sub-track']}
                            </Typography>
                            <Typography sx={{ fontSize: 16 }} gutterBottom>
                                <b>EA Flag:</b> {data.ea_flag}
                            </Typography>
                            <Typography sx={{ fontSize: 16 }} gutterBottom>
                                <b>Telemetry Flag:</b> {data.telemetry_flag}
                            </Typography>
                            <Typography sx={{ fontSize: 16 }} gutterBottom>
                                <b>Fund Type:</b> {data.fund_type}
                            </Typography>
                            <Typography sx={{ fontSize: 16 }} gutterBottom>
                                <b>Deal Updated At:</b> {formatDate(data.deal_updated_at)}
                            </Typography>
                        </CardContent>
                        <Box sx={{ flex: 2, flexGrow: 1, minWidth: '20rem', margin: '2rem' }}>
                            {(data.status === 'created' || data.status === 'saved') && (
                                <Button variant="contained" sx={{ margin: '5px 5px' }} fullWidth onClick={() => history.push(resumeLink)}>
                                    Resume Customer Success Plan
                                </Button>
                            )}
                            {/* <Box sx={{ display: 'flex' }}>
                                <Button variant="contained" disabled={data.status !== 'Closed'} fullWidth sx={{ margin: '10px' }}>
                                    Re-open
                                </Button>
                                <Button variant="contained" disabled={data.status === 'Closed'} fullWidth sx={{ margin: '10px 0' }}>
                                    Close
                                </Button>
                            </Box> */}
                            <Box>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    disabled={!data.blob}
                                    sx={{ margin: '5px 5px' }}
                                    onClick={async () => {
                                        // const downloadUrl = await getDownloadLink(data.id, downloadLinks.assessment);
                                        // window.location.href = downloadUrl;
                                    }}>
                                    Add Business Outcome
                                </Button>
                                <Button fullWidth variant="contained" disabled={!data.blob} sx={{ margin: '5px 5px' }} onClick={handleClick} id="download-button">
                                    Add Use Case
                                </Button>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    disabled={false}
                                    sx={{ margin: '5px 5px' }}
                                    id="download-button"
                                    onClick={() => history.push('/cx/lifecycle/detailed')}>
                                    Review Customer Success Plan
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Card>
            </Box>
        </>
    );
};

export default Metadata;
