import React from 'react';
import { SecureRoute } from '@okta/okta-react';
import Admin from './Admin';
import { hasPermission } from '../../util/permissions';
import CheckingPermissions from '../base/CheckingPermissions';

const AdminRoutes = (currentUser) => [
    hasPermission(currentUser, '934f4955-6586-40dc-abd2-e2655b3e1e8d', 'manage') ? (
        <SecureRoute key={'admin'} path="/admin" component={Admin} />
    ) : (
        <SecureRoute key={'admin'} path="/admin" render={() => <CheckingPermissions permission={hasPermission(currentUser, '934f4955-6586-40dc-abd2-e2655b3e1e8d', 'manage')} />} />
    ),
];

export default AdminRoutes;
