import React from 'react';
import { SecureRoute } from '@okta/okta-react';
import practiceSite from './practiceSite/practiceSite';
import { hasPermission } from '../../util/permissions';
import CheckingPermissions from '../base/CheckingPermissions';

const SPOARoutes = (currentUser) => [
    hasPermission(currentUser, '210032ed-3b2e-4b80-ac12-7714b8e81ade', 'left_pane', 'manage_practices') ? (
        <SecureRoute key={'spoa-tools'} path="/spoa/practice-Site" component={practiceSite} />
    ) : (
        <SecureRoute
            key={'spoa-tools'}
            path="/spoa/practice-Site"
            render={() => <CheckingPermissions permission={hasPermission(currentUser, '210032ed-3b2e-4b80-ac12-7714b8e81ade', 'left_pane', 'manage_practices')} />}
        />
    ),
];

export default SPOARoutes;
