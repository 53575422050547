import React from 'react';

import ChartInfoCard from './ChartInfoCard';
import { Typography } from '@mui/material';

const DealsInProgress = ({ count }) => {
    return (
        <ChartInfoCard title={'DEALS IN PROGRESS'} bgColor={'#00d4a6'}>
            <Typography variant="h2" fontWeight={600} color={'#FFFFFF'}>
                {count}
            </Typography>
        </ChartInfoCard>
    );
};

export default DealsInProgress;
