import { Typography } from '@mui/material'
import React from 'react'
import ChartInfoCard from './ChartInfoCard'

const TotalCustomersCard = ({ totalCustomersData }) => {
  return (
    <ChartInfoCard title={"TOTAL CUSTOMERS"} bgColor="#09a0b2">
        <Typography variant="h1" fontWeight={600} color={'#FFFFFF'}>{totalCustomersData}</Typography>
    </ChartInfoCard>
  )
}

export default TotalCustomersCard