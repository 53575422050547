import React from 'react';
import { Paper, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import EnhancedTable from '../../../components/enhancedTable/EnhancedTable';
import Metadata from '../components/Metadata';

const columns = [
    {
        id: 'customer',
        numeric: false,
        label: 'Customer',
        filterable: 'search',
    },
    {
        id: 'service',
        numeric: false,
        label: 'Assessment Type',
        filterable: 'dropdown',
    },
    {
        id: 'description',
        numeric: false,
        label: 'Description',
        filterable: 'search',
    },
    {
        id: 'email',
        numeric: false,
        label: 'Entered By',
        filterable: 'dropdown',
    },
    {
        id: 'status',
        numeric: false,
        label: 'Status',
        filterable: 'dropdown',
    },
    // {
    //     id: 'last_edited',
    //     numeric: false,
    //     label: 'Last Edited',
    //     collapsed: false,
    //     type: 'date',
    // },
];

const AssessmentOverview = ({ rows, setResetButton, resetAction, setResetAction }) => {
    const history = useHistory();

    const handleViewDetails = (id) => {
        history.push(`/pre-sales/${id}/detailedResults`);
    };

    const additionalButton = (id) => {
        return (
            <Button value={id} variant="contained" sx={{ margin: '5px 5px' }} fullWidth onClick={(e) => handleViewDetails(e.target.value)}>
                View Details
            </Button>
        );
    };

    return (
        <>
            <Paper>
                <EnhancedTable
                    rows={rows}
                    columns={columns}
                    setResetButton={setResetButton}
                    resetAction={resetAction}
                    setResetAction={setResetAction}
                    collapsibleRow={(row) => <Metadata data={row} additionalButtons={additionalButton} />}
                />
            </Paper>
        </>
    );
};

export default AssessmentOverview;
