import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import TrackPieChart from './TrackPieChart';
import TotalStagePieChart from './TotalStagePieChart';
import TotalCustomersCard from './TotalCustomersCard';

const ChartsContainer = ({ totalStageChartData, trackChartData, totalCustomersData, columns, updateFilters, clearFilters }) => {
    const [isFiltering, setIsFiltering] = useState(false);

    const handleIsFiltering = () => {
        if (!isFiltering) setIsFiltering(true);
    };

    const totalStageChartInfo = totalStageChartData();
    const trackChartInfo = trackChartData();
    const totalCustomersInfo = totalCustomersData();

    const getColumn = (columnLabel) => {
        return columns.find((column) => column.label === columnLabel);
    };

    return (
        <>
            {isFiltering && (
                <Box>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            clearFilters();
                            setIsFiltering(false);
                        }}
                        size="large">
                        Clear Filters
                    </Button>
                </Box>
            )}
            <Box sx={{ display: 'flex', gap: 3, flexWrap: 'wrap', padding: '30px 0', alignItems: 'center', justifyContent: 'start' }}>
                {totalStageChartData && (
                    <TotalStagePieChart
                        chartData={totalStageChartInfo}
                        handleClick={(title) => {
                            const column = getColumn('Stage');
                            updateFilters(column, title.toLowerCase());
                            handleIsFiltering();
                        }}
                    />
                )}
                {trackChartData && (
                    <TrackPieChart
                        chartData={trackChartInfo}
                        handleClick={(title) => {
                            const column = getColumn('Track');
                            updateFilters(column, title.toLowerCase());
                            handleIsFiltering();
                        }}
                    />
                )}
                {totalCustomersData && <TotalCustomersCard totalCustomersData={totalCustomersInfo} />}
            </Box>
        </>
    );
};

export default ChartsContainer;
