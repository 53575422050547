import React from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { Field, Form } from 'react-final-form';
import TextFieldWrapper from './TextFieldFinalFormWrapper';
import useCommonStyles from '../../util/commonStyles';
import { composeValidators, isNumber, required } from '../../util/finalFormValidators';
import useFormStyles from '../../util/formStyles';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    grayText: {
        color: theme.palette.grey[600],
    },
    grayInput: {
        background: 'rgba(0, 0, 0, 0.08)',
    },
}));

const AssessmentDetailsForm = ({ submit, ERRData, customFields, submitting, submitHeaderError }) => {
    const location = useLocation();
    const { pathname } = location;
    const classes = useStyles();
    const commonClasses = useCommonStyles();
    const formClasses = useFormStyles();
    const { authState, oktaAuth } = useOktaAuth();
    const [userInfo, setUserInfo] = React.useState(null);

    console.log(pathname);

    React.useEffect(() => {
        if (!authState || !authState.isAuthenticated) {
            // When user isn't authenticated, forget any user info
            setUserInfo(null);
        } else {
            oktaAuth.getUser().then((info) => {
                setUserInfo(info);
            });
        }
    }, [authState, oktaAuth]);

    return (
        <Box className={commonClasses.centerScreen} p={1} sx={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '80rem' }}>
            <Typography variant="h6" sx={{ pt: '.75rem' }}>
                Enter your {pathname.startsWith('/ai/workshop') ? `survey` : `assessment`} details:
            </Typography>
            <Form
                initialValues={{
                    errNumber: ERRData.errNumber,
                    customer: ERRData.customer_name,
                    customer_id: ERRData.customer_id,
                    name: ERRData.opportunity_name,
                    email: userInfo?.email,
                }}
                onSubmit={submit}
                style={{ width: '100%' }}
                render={({ handleSubmit, pristine, invalid }) => (
                    <form className={commonClasses.centerScreen} style={{ width: '100%' }} onSubmit={handleSubmit}>
                        <Box width="100%" maxWidth="50rem">
                            <div>
                                <Box sx={{ display: 'flex', alignItems: 'center' }} p={1}>
                                    <Field
                                        sx={{ flex: 0.2, minWidth: '7rem' }}
                                        className={formClasses.formField}
                                        disabled={true}
                                        label="ERR Number"
                                        name="errNumber"
                                        validate={composeValidators(required, isNumber)}
                                        component={TextFieldWrapper}
                                        InputProps={{ className: classes.grayInput }}
                                    />
                                </Box>
                            </div>
                            <div>
                                <Box sx={{ display: 'flex', alignItems: 'center' }} p={1}>
                                    <Field
                                        sx={{ flex: 1 }}
                                        className={formClasses.formField}
                                        disabled={true}
                                        label="Customer Name"
                                        name="customer"
                                        validate={composeValidators(required)}
                                        component={TextFieldWrapper}
                                        InputProps={{ className: classes.grayInput }}
                                    />
                                </Box>
                            </div>
                            <div>
                                <Box sx={{ display: 'flex', alignItems: 'center' }} p={1}>
                                    <Field
                                        sx={{ flex: 1 }}
                                        className={formClasses.formField}
                                        disabled={true}
                                        label="Opportunity Name"
                                        name="name"
                                        validate={composeValidators(required)}
                                        component={TextFieldWrapper}
                                        InputProps={{ className: classes.grayInput }}
                                    />
                                </Box>
                            </div>
                            <div>
                                <Box sx={{ display: 'flex', alignItems: 'center' }} p={1}>
                                    <Field
                                        sx={{ flex: 1 }}
                                        className={formClasses.formField}
                                        label="Description"
                                        name="description"
                                        validate={composeValidators(required)}
                                        component={TextFieldWrapper}
                                        placeholder="(ex. Team is assessing their options for DR)"
                                        InputLabelProps={{ shrink: true }}
                                        required={true}
                                    />
                                </Box>
                            </div>
                            {customFields != null ? <div>{customFields}</div> : null}
                            <div>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    disabled={pristine || invalid || submitting}
                                    className={classes.button}
                                    style={{
                                        color: 'white',
                                        margin: '1rem',
                                    }}>
                                    {submitting ? (
                                        <>
                                            Submitting
                                            <CircularProgress sx={{ marginLeft: '1rem' }} size={20} />
                                        </>
                                    ) : (
                                        'Submit'
                                    )}
                                </Button>
                                {submitHeaderError ? <Typography className={commonClasses.error}>Failed to submit. Please try again.</Typography> : null}
                            </div>
                        </Box>
                    </form>
                )}
            />
        </Box>
    );
};

export default AssessmentDetailsForm;
