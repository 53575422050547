import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { SecureRoute } from '@okta/okta-react';
import { makeStyles } from '@mui/styles';
import DataProtectionForm from './DataProtectionForm';
import DataProtectionStart from './DataProtectionStart';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
}));

const DataProtection = ({ match }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Switch>
                <Redirect key="dataProtectionCreateRedirect" from={`${match.url}`} exact to="/pre-sales/data-protection/create/start" />,
                <SecureRoute key="start" path={`${match.url}/create/start`} component={DataProtectionStart} />,
                <SecureRoute key="form" path={`${match.url}/create/:id/form`} component={DataProtectionForm} />,
            </Switch>
        </div>
    );
};

export default DataProtection;
