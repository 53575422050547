import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import AddComment from './AddComment';
import CXFeedback from './CXFeedback';

interface IProps {
    doc: {
        uri: string;
        name: string;
        size: number;
    };
}
const CXApproval = ({ doc }: IProps) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Button variant="outlined" sx={{ mr: 3 }}>
                    Reject
                </Button>
                <Button variant="contained" sx={{ ml: 3 }}>
                    Approve for Next Stage
                </Button>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-evenly', width: '100%' }}>
                <Box sx={{ flexGrow: 7, maxWidth: '65rem', mt: 2 }}>
                    <DocViewer
                        documents={[doc]}
                        pluginRenderers={DocViewerRenderers}
                        config={{
                            header: {
                                disableHeader: false,
                                disableFileName: false,
                                retainURLParams: false,
                            },
                            csvDelimiter: ',', // "," as default,
                            pdfZoom: {
                                defaultZoom: 1.1, // 1 as default,
                                zoomJump: 0.2, // 0.1 as default,
                            },
                            pdfVerticalScrollByDefault: false, // false as default
                        }}
                    />
                </Box>
                <Box sx={{ flexGrow: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '35rem', minWidth: '20rem', pt: '2rem', pl: '2rem' }}>
                    <CXFeedback
                        defaultComment={
                            doc.name === 'WebexApp-UseExample.png'
                                ? 'Looks like the customer has only activated 1 of 3 workloads. Atleast 2 need to be activated for an Incentive.'
                                : 'This is a great start. Cisco will require atleast one of the KPIs to be a defined metric. Include 1 and should be all set.'
                        }
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default CXApproval;
