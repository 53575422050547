import { Box, CircularProgress, Paper, Table, TableCell, TableRow, Toolbar, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useGetAssessmentByIdQuery, useGetEstimateQuery } from '../../../app/DataProtectionApi';
import useCommonStyles from '../../../util/commonStyles';

const IntakeScore = () => {
    const commonClasses = useCommonStyles();

    const { id } = useParams<{ id: string }>();

    const { data, error } = useGetEstimateQuery({ type: 'estimation', assessment_id: id });
    const { data: assessmentData, error: getAssessmentError } = useGetAssessmentByIdQuery(
        { type: 'verbose', assessment_id: id },
        { refetchOnMountOrArgChange: true, pollingInterval: 15000 },
    );

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '30rem', maxWidth: '40rem', flexBasis: '0', flexGrow: 1 }}>
            <Paper sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                {data !== undefined && assessmentData !== undefined ? (
                    <>
                        <Toolbar>
                            <Typography sx={{ flex: '1 1 100%', fontWeight: 'bold' }} variant="h6" component="div">
                                Idea Summary
                            </Typography>
                        </Toolbar>
                        <Table>
                            <TableRow key="idea">
                                <TableCell sx={{ padding: '1rem 2.5rem' }}>
                                    <Typography>The Idea</Typography>
                                </TableCell>
                                <TableCell sx={{ padding: '1rem 1rem' }}>
                                    <Typography align="right">{assessmentData.description}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow key="who">
                                <TableCell sx={{ padding: '1rem 2.5rem' }}>
                                    <Typography sx={{ whiteSpace: 'nowrap' }}>Who it helps</Typography>
                                </TableCell>
                                <TableCell sx={{ padding: '1rem' }}>
                                    <Typography align="right">{assessmentData.questions.Hypothesis[2].answer}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow key="goal">
                                <TableCell sx={{ padding: '1rem 2.5rem' }}>
                                    <Typography>The Goal</Typography>
                                </TableCell>
                                <TableCell sx={{ padding: '1rem' }}>
                                    <Typography align="right">{assessmentData.questions.Hypothesis[0].answer}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow key="focus">
                                <TableCell sx={{ padding: '1rem 2.5rem' }}>
                                    <Typography>Focus</Typography>
                                </TableCell>
                                <TableCell sx={{ padding: '1rem' }}>
                                    <Typography align="right">{assessmentData.focus}</Typography>
                                </TableCell>
                            </TableRow>
                        </Table>
                        <Toolbar>
                            <Typography sx={{ flex: '1 1 100%', fontWeight: 'bold' }} variant="h6" component="div">
                                Idea Scoring
                            </Typography>
                        </Toolbar>
                        <Table>
                            <TableRow key="section-a">
                                <TableCell>
                                    <Typography sx={{ margin: '0 2rem' }}>Section A</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography align="right" sx={{ margin: '0 2rem' }}>
                                        {data['Sectional Scores']['Section A']['section_a_score']}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow key="section-b">
                                <TableCell>
                                    <Typography sx={{ margin: '0 2rem' }}>Section B</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography align="right" sx={{ margin: '0 2rem' }}>
                                        {data['Sectional Scores']['Section B']['section_b_score']}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow key="section-c">
                                <TableCell>
                                    <Typography sx={{ margin: '0 2rem' }}>Section C</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography align="right" sx={{ margin: '0 2rem' }}>
                                        {data['Sectional Scores']['Section C']['section_c_score']}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow key="section-d">
                                <TableCell>
                                    <Typography sx={{ margin: '0 2rem' }}>Section D</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography align="right" sx={{ margin: '0 2rem' }}>
                                        {data['Sectional Scores']['Section D']['section_d_score']}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </Table>
                        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="h6" component="div">
                                <b>Total</b>
                            </Typography>

                            <Typography variant="h6" component="div">
                                <b>{data['Score']}</b>
                            </Typography>
                        </Toolbar>
                    </>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'column' }} className={commonClasses.centerScreen}>
                        {error || getAssessmentError ? (
                            <Typography variant="h5">Unable to load Idea Score details</Typography>
                        ) : (
                            <>
                                <CircularProgress />
                                <Typography>Your Idea Score is loading...</Typography>
                            </>
                        )}
                    </div>
                )}
            </Paper>
        </Box>
    );
};

export default IntakeScore;
