import React, { useState } from 'react';
import useCommonStyles from '../../../util/commonStyles';
import { Box, Typography, Paper, Button, CircularProgress } from '@mui/material';

import EnhancedTable from '../../../components/enhancedTable/EnhancedTable';
import Metadata from './components/MetaData';
import { columns } from './constants';
import rowsJsonData from './json/rowsData.json';
import ChartsContainer from './components/ChartsContainer';
import { useGetSubscripitonsQuery } from '../../../app/SubscriptionsApi';

const Subscriptions = () => {
    const commonClasses = useCommonStyles();
    const [resetButton, setResetButton] = useState(false);
    const [resetAction, setResetAction] = useState(false);
    // const [rowsData, setRowsData] = useState([...rowsJsonData]);
    const { data: rowsData, isLoading, isError, refetch } = useGetSubscripitonsQuery();

    const additionalButton = (id) => {
        return (
            <Button value={id} variant="contained" sx={{ margin: '5px 5px' }} fullWidth onClick={(e) => {}}>
                View Details
            </Button>
        );
    };

    const getChartItemCount = (value, key, rows) => {
        const count = rows.filter((item) => item[key] === value).length;
        return count;
    };

    const getChartData = (rows, key) => {
        const uniqueLabels = new Set(rows.map((row) => row[key]));
        const data = Array.from(uniqueLabels).map((item) => {
            return getChartItemCount(item, key, rows);
        });

        return {
            labels: [...uniqueLabels],
            data: [...data],
        };
    };

    const getTotalCustomersData = (rows) => {
        const uniqueCustomers = new Set(rows.map((row) => row.cr_party_name));
        const uniqueCustomersCount = [...uniqueCustomers].length;
        return uniqueCustomersCount;
    };

    const addIdFieldToRow = (rows) => {
        return rows.map((row, idx)=>{
            return { ...row, id: `${idx}-${row.deal_id}`}
        })
    }

    return (
        <div>
            <Typography className={commonClasses.pageTitle} sx={{ marginBottom: '1.5rem' }} variant="h4">
                My Contracts - Subscriptions
            </Typography>

            <Box>
                {rowsData && !isLoading && (
                    <Paper>
                        <EnhancedTable
                            columns={columns}
                            rows={addIdFieldToRow(rowsData)}
                            setResetButton={setResetButton}
                            resetAction={resetAction}
                            setResetAction={setResetAction}
                            collapsibleRow={(row) => <Metadata data={row} additionalButtons={additionalButton} />}
                            chartComponent={(rowsInfo, columns, updateFilters, clearFilters) => (
                                <ChartsContainer
                                    columns={columns}
                                    updateFilters={updateFilters}
                                    clearFilters={clearFilters}
                                    totalCustomersData={() => getTotalCustomersData(rowsInfo)}
                                    totalStageChartData={() => getChartData(rowsInfo, 'stage')}
                                    trackChartData={() => getChartData(rowsInfo, 'track')}
                                />
                            )}
                        />
                    </Paper>
                )}

                {isLoading && !rowsData && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 5 }}>
                        <CircularProgress />
                        <Typography marginTop={5}>Fetching Subscriptions</Typography>
                    </Box>
                )}

                {!isLoading && !rowsData && isError && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 5 }}>
                        <Button onClick={refetch}>Reload Subscriptions</Button>
                    </Box>
                )}
            </Box>
        </div>
    );
};

export default Subscriptions;
