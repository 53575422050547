import { Collapse, FormControl, MenuItem, TextField } from "@mui/material";
import React from "react";

const RenderFilter = ({
  column,
  updateFilters,
  visibleFilters,
  rows,
  filters,
}) => {
  switch (column.filterable) {
    case "search":
      return (
        <Collapse in={visibleFilters.includes(column.id)}>
          <TextField
            fullWidth
            variant="standard"
            size="small"
            label="search"
            onChange={(e) => updateFilters(column, e.target.value)}
            value={filters[column.id]}
          />
        </Collapse>
      );
    case "dropdown":
      let seenOptions = {};
      let options = [];
      rows.forEach((row) => {
        if (!(row[column.id].toString().toLowerCase() in seenOptions)) {
          seenOptions[row[column.id].toString().toLowerCase()] = 1;
          options.push(row[column.id].toString().toLowerCase());
        }
      });
      options.sort();

      return (
        <Collapse in={visibleFilters.includes(column.id)}>
          <FormControl style={{ width: "100%" }}>
            <TextField
              select
              //sx={{ visibility: visibleFilters.includes(column.id) ? 'visible' : 'hidden' }}
              width={"100%"}
              variant="standard"
              size="small"
              label="select"
              onChange={(e) => updateFilters(column, e.target.value)}
              value={filters[column.id]}
            >
              <MenuItem key="" value="">
                any
              </MenuItem>
              {options.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
              {options.length < 1 && filters[column.id] !== "" && (
                <MenuItem key={filters[column.id]} value={filters[column.id]}>
                  {filters[column.id]}
                </MenuItem>
              )}
            </TextField>
          </FormControl>
        </Collapse>
      );
    default:
      return null;
  }
};

export default RenderFilter;
