import { Table, TableCell, TableRow, Toolbar, Typography } from '@mui/material';

const BudgetaryEstimate = ({ estimateData }) => {
    return (
        <>
            <Toolbar>
                <Typography sx={{ flex: '1 1 100%', fontWeight: 'bold' }} variant="h6" component="div">
                    CONTRACT DETAILS 
                </Typography>
            </Toolbar>
            <Table>
                {Object.entries(estimateData["CONTRACT DETAILS"]).map(([lineName, lineValue], index)=>{
                    return <TableRow key={index}>
                    <TableCell>
                        <Typography sx={{ margin: '0 2rem' }}>{lineName}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography align="right" sx={{ margin: '0 2rem' }}>
                            {lineName === "List Price per Space per Month" &&  "$"}{lineValue}
                        </Typography>
                    </TableCell>
                </TableRow>
                })}
                
            </Table>
            <Toolbar>
                <Typography sx={{ flex: '1 1 100%', fontWeight: 'bold' }} variant="h6" component="div">
                    ESTIMATED MONTHLY SERVICE FEES
                </Typography>
            </Toolbar>
            <Table>
                {Object.entries(estimateData['ESTIMATED MONTHLY SERVICE FEES']).map(([lineName, lineItem]) => {
                    let varStyling = {};
                    if (lineName.includes('Total')) {
                        varStyling = { fontWeight: 'bold', margin: '1rem' };
                    } else {
                        varStyling = { margin: '0 2rem' };
                    }
                    return (
                        <TableRow>
                            <TableCell>
                                <Typography sx={varStyling}>{lineName}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography align="right" sx={varStyling}>
                                    ${lineItem?.toLocaleString()}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </Table>
            <Toolbar>
                <Typography sx={{ flex: '1 1 100%', fontWeight: 'bold' }} variant="h6" component="div">
                    TOTAL CONTRACT VALUE
                </Typography>
            </Toolbar>
            <Table>
                {Object.entries(estimateData['TOTAL CONTRACT VALUE']).map(([lineName, lineItem]) => {
                    return (
                        <TableRow>
                            <TableCell>
                                <Typography sx={{ fontWeight: 'bold', margin: '1rem' }}>{lineName}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography align="right" sx={{ fontWeight: 'bold', margin: '1rem' }}>
                                    ${lineItem?.toLocaleString()}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </Table>
        </>
    );
};

export default BudgetaryEstimate;
