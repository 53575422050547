export const capitalize = (s: string) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export const handleNone = (s: string) => {
    return s === 'None' ? null : s;
};

export const getDateFromCosmosString = (dateString: string) => {
    const months: { [key: string]: string } = {
        Jan: '01',
        Feb: '02',
        Mar: '03',
        Apr: '04',
        May: '05',
        Jun: '06',
        Jul: '07',
        Aug: '08',
        Sep: '09',
        Oct: '10',
        Nov: '11',
        Dec: '12',
    };
    const lastEditArr = dateString.split(/\s+/);
    const month = months[lastEditArr[1]];
    const day = lastEditArr[2];
    const timeUTC = lastEditArr[3];
    const year = parseInt(lastEditArr[4]);
    return new Date(`${year}-${month}-${parseInt(day) < 10 ? '0' + day : day}T${timeUTC}`);
};

export const getPresalesAssessments = () => [
    { name: 'Data Protection', path: '/pre-sales/data-protection', serviceId: 'f1f933a5-a61e-4be4-bfd3-f08d0ec7f1c6' },
    { name: 'AVA for Collaboration Spaces', path: '/pre-sales/ava-collab', serviceId: 'e11f6cd7-7989-484e-9c09-792240af01b2' },
    { name: 'Innovation Intake', path: '/innovation/intake', serviceId: 'c166003b-ec15-4410-a26b-90e15a1594bc' },
    { name: 'AI Envisioning Survey', path: '/ai/workshop', serviceId: '750dd17a-688a-4af4-b2eb-88493cf44e6d' },
];

export const getMscTools = () => [{ name: 'MSC Dashboards', path: '/msc', serviceId: '10536f63-edd2-4d4b-9134-df155790c4e7' }];
