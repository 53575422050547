import { getMscTools, getPresalesAssessments } from './utilFunctions';

export const hasPermission = (user, serviceId, permission) => {
    if (!user) {
        return null;
    }
    if (user.actions) {
        if (user.actions[serviceId]) {
            return user.actions[serviceId].actions.indexOf(permission) > -1;
        }
    }
    return false;
};

export const hasPermissionMultiple = (user, serviceToPermissionObject) => {
    if (!user) {
        return null;
    }
    for (let i = 0; i < Object.entries(serviceToPermissionObject).length; i++) {
        const [service, permission] = Object.entries(serviceToPermissionObject)[i];
        if (hasPermission(user, service, permission)) {
            return true;
        }
    }
    return false;
};

export const isPresalesUser = (user) => {
    const presalesAssessments = getPresalesAssessments();
    for (let assessment of presalesAssessments) {
        if (assessment.serviceId in user.actions) {
            return true;
        }
    }
    return false;
};

export const isMscUser = (user) => {
    const mscTools = getMscTools();
    for (let tool of mscTools) {
        if (tool.serviceId in user.actions) {
            return true;
        }
    }
    return false;
};
