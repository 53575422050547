import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { SecureRoute } from '@okta/okta-react';
import { makeStyles } from '@mui/styles';
import AiWorkshopStart from './AiAssessmentStart';
import AiWorkshopForm from './AiAssessmentForm';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
}));

const AiAssessment = ({ match }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Switch>
                <Redirect key="aiWorkshopCreateRedirect" from={`${match.url}`} exact to="/ai/workshop/create/start" />,
                <SecureRoute key="start" path={`${match.url}/create/start`} component={AiWorkshopStart} />,
                <SecureRoute key="form" path={`${match.url}/create/:id/form`} component={AiWorkshopForm} />,
            </Switch>
        </div>
    );
};

export default AiAssessment;
