import { Box, Card, Fab, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Field } from 'react-final-form';
import TextFieldWrapper from '../../components/assessments/TextFieldFinalFormWrapper';
import SelectFieldWrapper from '../../components/assessments/questions/selectField';
import { FieldArray } from 'react-final-form-arrays';

export const EditQuestion = ({ question, questionIndex, stageName, values }) => {
    const handleAddResponse = (question) => {
        const newOption = {
            id: `${question.id}_${Math.random().toString(36).slice(2).slice(0, 4)}`,
            value: '',
            score: 0,
        };
        values.questions[stageName][questionIndex].options.push(newOption);
    };

    return (
        <Card key={question.id} sx={{ p: '2rem', borderRadius: 6, display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <Box key={`${question.id}-question`} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography key={`${question.id}-question-label`} variant="h7" sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                    Question {questionIndex + 1}:
                </Typography>
                <Field
                    key={`${question.id}-question-value`}
                    disabled={false}
                    style={{ marginLeft: '1rem', width: '100%', minWidth: '10rem', maxWidth: '80rem' }}
                    component={TextFieldWrapper}
                    //label={`question.text`}
                    name={`questions[${stageName}][${questionIndex}].text`}
                    variant="standard"
                    size="normal"
                    multiline
                    minRows={1}
                    maxRows={3}
                    //placeholder="Comments"
                />
            </Box>
            <Box key={`${question.id}-question-type`} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem', minWidth: '15rem', maxWidth: '25rem' }}>
                <Typography key={`${question.id}-question-type-label`} variant="h7" sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                    Question Type:
                </Typography>
                <Field
                    key={`${question.id}-question-type-response`}
                    isDisabled={true}
                    style={{ marginLeft: '1rem' }}
                    component={SelectFieldWrapper}
                    options={['radio-button']}
                    //label={`question.text`}
                    name={`questions[${stageName}][${questionIndex}].type`}
                    variant="outlined"
                    size="small"
                    //placeholder="Comments"
                />
            </Box>
            <FieldArray name={`questions[${stageName}][${questionIndex}].options`}>
                {({ fields }) => (
                    <>
                        {fields.map((option, index) => (
                            <Box key={`${question.id}-option-${index}`} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem', marginLeft: '3rem' }}>
                                <Typography key={`${question.id}-option-${index}-label`} variant="h7" sx={{ fontWeight: 'bold' }}>
                                    Option {index + 1}:
                                </Typography>
                                <Field
                                    key={`${question.id}-option-${index}-response`}
                                    disabled={false}
                                    style={{ width: '70%', minWidth: '10rem', maxWidth: '60rem' }}
                                    component={TextFieldWrapper}
                                    label={`Response`}
                                    name={`${option}.value`}
                                    variant="standard"
                                    //size="small"
                                    multiline
                                    minRows={1}
                                    maxRows={3}
                                    //placeholder="Comments"
                                />
                                <Field
                                    key={`${question.id}-option-${index}-weight`}
                                    disabled={false}
                                    style={{ width: '10%', minWidth: '4rem', maxWidth: '12rem' }}
                                    component={TextFieldWrapper}
                                    label={`Weight`}
                                    name={`${option}.score`}
                                    variant="outlined"
                                    size="small"
                                    multiline
                                    minRows={1}
                                    maxRows={3}
                                    //placeholder="Comments"
                                />
                                <IconButton color="error" aria-label="delete" onClick={() => fields.remove(index)}>
                                    <DeleteForeverIcon />
                                </IconButton>
                            </Box>
                        ))}
                        <Fab
                            variant="extended"
                            color="secondary"
                            size="small"
                            sx={{ marginLeft: '3rem', width: '14rem', color: 'white' }}
                            onClick={() => {
                                fields.push({
                                    id: `${question.id}_${Math.random().toString(36).slice(2).slice(0, 4)}`,
                                    value: '',
                                    score: 0,
                                });
                            }}>
                            <AddIcon sx={{ mr: 1, mb: '.1rem' }} />
                            <Typography>Add Response</Typography>
                        </Fab>
                    </>
                )}
            </FieldArray>
        </Card>
    );
};
