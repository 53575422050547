
export const practiceIds = {
    Cloud: 1,
    Collaboration: 4,
    Security: 2,
    Services: 3,
};

export const actionOptions = {
    ADD: 'add',
    DELETE: 'delete',
}; 