import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';

const createData = (name: string, description: string) => {
    return { name, description };
};

const rows = [
    createData('Idea', 'Starts by identifying a need or opportunity in the market. Gather customer feedback and conduct market research to validate the idea'),
    createData(
        'Plan',
        'Once the idea is validated, works with the development team to create a plan for the product. This includes defining the scope, goals, and objectives for the product.',
    ),
    createData(
        'Design',
        'Works with the design team to create wireframes and mockups of the product. They ensure that the design meets the needs of the target audience and aligns with the goals and objectives of the product.',
    ),
    createData(
        'Develop',
        'Works closely with the development team to ensure that the product is being built according to the plan. They make sure that the product is delivered on time and within budget.',
    ),
    createData('Test', 'Works with the quality assurance team to test the product. They ensure that the product meets the requirements and is of high quality.'),
    createData(
        'Release',
        'Responsible for the release of the product. They work with the marketing team to create a launch plan and ensure that the product is properly marketed.',
    ),
    createData(
        'Support',
        'Once the product is released, responsible for providing support to customers. They gather feedback and make improvements to the product based on customer needs.',
    ),
];

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const LifecycleTable = () => {
    return (
        <TableContainer component={Paper} sx={{ borderRadius: '0px' }}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow sx={{ backgroundColor: '#114989' }}>
                        <TableCell sx={{ color: '#FFFFFF', fontSize: '1.2rem' }}>
                            <b>Lifecycle Phase</b>
                        </TableCell>
                        <TableCell sx={{ color: '#FFFFFF', fontSize: '1.2rem' }}>
                            <b>Responsibilities</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <StyledTableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell>{row.description}</TableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default LifecycleTable;
