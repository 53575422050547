import React from 'react'

import { Box } from "@mui/material"
import ExpiringSoonTable from './ExpiringSoonTable'
import RequireAttention from './RequireAttention'
import dealsRequiringAttentionData from '../json/dealsRequiringAttentionData.json'

const flexObj = {
  cr_party_name: 2,
  incentive_eligibility_status: 1,
};


const ExtraTablesContainer = () => {
  return (
    <Box sx={{ display: "flex", padding: "30px 0", minHeight: "50px", gap: 5, flexWrap: "wrap" }}>
        <Box style={{ flex: 1, minWidth: "400px"}}>
          <ExpiringSoonTable />
        </Box>

        <Box style={{ flex: 1, minWidth: "400px"}}>
          <RequireAttention rows={dealsRequiringAttentionData} title="Deals Requiring Attention" flexObj={flexObj}/>
        </Box>
    </Box>
  )
}

export default ExtraTablesContainer