import React, { useEffect } from 'react';

import { Accordion, AccordionSummary, Typography, AccordionDetails, Collapse, Autocomplete, Button, TextField } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import { SiteRow } from './SiteRow';
import { useAddSiteMutation, useDeleteSiteMutation } from '../SitesApi';
import { addSite, findSiteById, deleteSite, getSuggestions } from '../utils/library';
import { practiceIds } from '../utils/constants';

export const PracticeSiteAccordion = ({ expanded, practice, accordionId, currentData, handleChange, searchSiteList, existentPractices, revalidateSitesData }) => {
    const [isSearching, setIsSearching] = React.useState(false);
    const [suggestions, setSuggestions] = React.useState(null);
    const [selectedSite, setSelectedSite] = React.useState('');
    const [addedSitesArr, setAddedSitesArr] = React.useState([]);
    const [deleteSiteReq] = useDeleteSiteMutation();
    const [addSiteReq] = useAddSiteMutation();

    React.useEffect(() => {
        if (searchSiteList) setSuggestions([...getSuggestions(searchSiteList)]);
    }, [searchSiteList]);

    React.useEffect(() => {
        let interval;
        if (addedSitesArr.length) {
            interval = setInterval(() => {
                revalidateSitesData();
            }, 30000);
        } else if (!addedSitesArr.length) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [addedSitesArr, revalidateSitesData]);

    const deleteAddedSite = React.useCallback(
        (id) => {
            const filtered = addedSitesArr.filter((item) => item.site_id !== id);
            setAddedSitesArr(filtered);
        },
        [addedSitesArr],
    );

    const checkSiteAdded = React.useCallback(
        (updatedExistentSitesArr) => {
            if (existentPractices && practice) {
                addedSitesArr.forEach((addedItem) => {
                    updatedExistentSitesArr.forEach((existentItem) => {
                        if (addedItem.site_id === existentItem.site_id) {
                            deleteAddedSite(addedItem.site_id);
                        }
                    });
                });
            }
        },
        [addedSitesArr, deleteAddedSite, existentPractices, practice],
    );

    useEffect(() => {
        checkSiteAdded(currentData[practice]);
    }, [checkSiteAdded, currentData, practice]);

    return (
        <Accordion expanded={expanded === accordionId} onChange={handleChange(accordionId)}>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls={`${accordionId}-content`} id={`${accordionId}-header`}>
                <Typography>{practice}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {practice && existentPractices ? (
                    existentPractices[practice].map((item, index) => {
                        return (
                            <SiteRow
                                key={item.practice_site_id}
                                web_url={item.web_url}
                                deleteSite={() => deleteSite({ siteId: item.practice_site_id, deleteSiteReq: deleteSiteReq })}
                                revalidateSitesData={revalidateSitesData}
                                connectedSites={existentPractices[practice]}
                                addedSitesArr={addedSitesArr}
                                setAddedSitesArr={setAddedSitesArr}
                                practiceId={item.site_id}
                            />
                        );
                    })
                ) : (
                    <></>
                )}

                {addedSitesArr.length ? (
                    addedSitesArr.map((item, index) => (
                        <SiteRow
                            key={index}
                            web_url={item.web_url}
                            isAddPending={true}
                            deleteSite={() => {}}
                            revalidateSitesData={revalidateSitesData}
                            connectedSites={existentPractices[practice]}
                            addedSitesArr={addedSitesArr}
                            setAddedSitesArr={setAddedSitesArr}
                            practiceId={item.site_id}
                        />
                    ))
                ) : (
                    <></>
                )}

                <Collapse orientation="vertical" in={isSearching} unmountOnExit>
                    {suggestions && (
                        <Autocomplete
                            value={selectedSite}
                            onChange={(e, newVal) => {
                                setSelectedSite(newVal);
                            }}
                            id="combo-box-demo"
                            options={[...suggestions]}
                            sx={{ width: 300, marginBottom: 3 }}
                            renderInput={(params) => <TextField {...params} label="Search" />}
                        />
                    )}
                </Collapse>
                <Button
                    variant="contained"
                    onClick={() => {
                        setIsSearching(!isSearching);
                        if (selectedSite) {
                            addSite({
                                selectedSite: selectedSite,
                                findSiteById: findSiteById,
                                addSiteReq: addSiteReq,
                                setAddedSitesArr: setAddedSitesArr,
                                addedSitesArr: addedSitesArr,
                                practice: practice,
                                practiceIds: practiceIds,
                                searchSiteList: searchSiteList,
                            });
                            setSelectedSite('');
                        }
                    }}>
                    Add Site
                </Button>
            </AccordionDetails>
        </Accordion>
    );
};
