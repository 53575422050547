import React from 'react';
import { Box, Card, CardContent, CircularProgress, Stack, Switch, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import useCommonStyles from '../../../util/commonStyles';
import { useGetAssessmentByIdQuery } from '../../../app/DataProtectionApi';
import { getDateFromCosmosString } from '../../../util/utilFunctions';
import Metadata from '../components/Metadata';
import AssessmentView from './components/AssessmentView';
import Details from './components/Details';

const AssessmentDetailed = () => {
    //const [tab, setTab] = React.useState(0);
    const commonClasses = useCommonStyles();
    const [questionData, setQuestionData] = React.useState();
    const [internalView, setInternalView] = React.useState(false);

    const { id } = useParams();

    const { data, error } = useGetAssessmentByIdQuery({ type: 'verbose', assessment_id: id }, { refetchOnMountOrArgChange: true, pollingInterval: 15000 });

    React.useMemo(() => {
        if (data) {
            const modifiedData = { ...data };
            modifiedData.last_edited = getDateFromCosmosString(modifiedData.last_edited);
            modifiedData.submitted_at = getDateFromCosmosString(modifiedData.submitted_at);
            modifiedData.answered_at = getDateFromCosmosString(modifiedData.answered_at);
            setQuestionData(modifiedData);
        } else setQuestionData(data);
    }, [data]);

    const handleInternalSwitch = (event) => {
        setInternalView(event.target.checked);
    };

    return (
        <>
            {questionData !== undefined ? (
                <>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography className={commonClasses.pageTitle} variant="h4">
                            {questionData.service_name} {questionData.service_name === 'AI Envisioning Survey' ? `Results` : `Assessment`}
                        </Typography>
                        <Stack direction="row" spacing={0} alignItems="center">
                            <Switch checked={internalView} onChange={handleInternalSwitch} />
                            <Typography variant="button">Internal View</Typography>
                        </Stack>
                    </Box>
                    <Metadata data={questionData} />
                    <Details internalView={internalView} data={questionData} />
                    <Card raised={true} sx={{ flexGrow: 1, minWidth: 275, margin: '0 2px' }}>
                        <Typography sx={{ margin: '1rem 0 0 1rem' }} variant="h4">
                            Assessment Responses
                        </Typography>
                        <CardContent>
                            <AssessmentView data={questionData} />
                        </CardContent>
                    </Card>
                </>
            ) : (
                <div className={commonClasses.centerScreen}>
                    {error ? <Typography className={commonClasses.error}>Failed to get assessment. Please try again.</Typography> : <CircularProgress />}
                </div>
            )}
        </>
    );
};

export default AssessmentDetailed;
