import React from 'react';
import useCommonStyles from '../../../util/commonStyles';
import { Box, Typography } from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';
import { useGetSitesQuery, useSearchSitesQuery } from './SitesApi';
import { PracticeSiteAccordion } from './components/PracticeSiteAccordion';

const PracticeSite = () => {
    const commonClasses = useCommonStyles();
    const [expanded, setExpanded] = React.useState(false);
    const [practiseSiteOptions, setPractiseSiteOptions] = React.useState([]);
    const {
        data: practiceSites,
        isLoading: practiseSitesLoading,
        isSuccess: practiseSitesSuccess,
        refetch: refetchSitesData,
        currentData: currentSitesData,
    } = useGetSitesQuery('');
    const { data: searchSitesList } = useSearchSitesQuery('');

    const getPractiseSites = (sites) => {
        return Object.keys(sites);
    };

    React.useEffect(() => {
        if (practiseSitesSuccess) {
            setPractiseSiteOptions(getPractiseSites(practiceSites));
        }
    }, [practiseSitesSuccess, practiceSites]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div>
            <Typography className={commonClasses.pageTitle} sx={{ marginBottom: '1.5rem' }} variant="h4">
                SPOA Practice Site Management
            </Typography>
            {!practiseSitesLoading ? (
                practiseSiteOptions.map((item, index) => {
                    return (
                        <PracticeSiteAccordion
                            existentPractices={practiceSites}
                            searchSiteList={searchSitesList}
                            sitesArrData={practiceSites}
                            currentData={currentSitesData}
                            key={index}
                            practice={item}
                            accordionId={index}
                            expanded={expanded}
                            revalidateSitesData={refetchSitesData}
                            handleChange={handleChange}></PracticeSiteAccordion>
                    );
                })
            ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                    <CircularProgress />
                    <Typography align={'center'}>Fetching Sites...</Typography>
                </Box>
            )}
        </div>
    );
};

export default PracticeSite;
