import React from "react";
import { Box } from "@mui/material";

const Container = ({ minWidth = "600px", children }) => {
  return (
    <Box sx={{ width: "100%", overflowX: "auto" }}>
      <Box sx={{minWidth, width: "100%"}}>{children}</Box>
    </Box>
  );
};

export default Container;
