import * as React from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { getDownloadLink } from '../../../util/blob';
import { List, ListItem, Popover } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    metadataCard: { flexGrow: 1, minWidth: 275, margin: 2 },
    buttons: {
        margin: '2%',
    },
}));

const Metadata = ({ data, additionalButtons }) => {
    const history = useHistory();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [downloadLinks, setDownloadLinks] = React.useState({});
    const [resumeLink, setResumeLink] = React.useState();
    const openDowloadMenu = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        switch (data.service ? data.service : data.service_name) {
            case `Data Protection`:
                setDownloadLinks({
                    assessment: 'ePlus_Data_Protection_Report.docx',
                    proposalPDF: 'ePlus Managed Backup Budgetary Proposal.pdf',
                    proposalPPT: 'ePlus Managed Backup Budgetary Proposal.pptx',
                });
                setResumeLink(`/pre-sales/data-protection/create/${data.id}/form`);
                break;
            case `AVA for Collaboration Spaces`:
                setDownloadLinks({
                    assessment: 'ePlus_AVA_Report.docx',
                    proposalPDF: 'AVA_managed_spaces_proposal_final.pdf',
                    proposalPPT: 'AVA_managed_spaces_proposal_final.pptx',
                });
                setResumeLink(`/pre-sales/ava-collab/create/${data.id}/form`);
                break;
            case `Innovation Intake`:
                setDownloadLinks({
                    assessment: 'Innovation_Intake.docx',
                    proposalPDF: 'Innovation_Intake.pdf',
                    proposalPPT: 'Innovation_Intake.pptx',
                });
                setResumeLink(`/innovation/intake/create/${data.id}/form`);
                break;
            case `AI Envisioning Survey`:
                setDownloadLinks({
                    assessment: 'ePlus_AI_EnvisioningWorkshop_Survey.docx',
                });
                setResumeLink(`/ai/workshop/create/${data.id}/form`);
                break;
            default:
                break;
        }
    }, [data]);

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Card className={classes.metadataCard} raised={true}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <CardContent sx={{ flexGrow: 2 }}>
                            <Typography variant="h6">Assessment Details</Typography>
                            <Typography sx={{ fontSize: 16 }} gutterBottom>
                                <b>Customer:</b> {data.customer || data.customer_name}
                            </Typography>
                            <Typography sx={{ fontSize: 16 }} gutterBottom>
                                <b>Entered By:</b> {data.email}
                            </Typography>
                            {/* <Typography sx={{ fontSize: 16 }} gutterBottom>
                                <b>Completed:</b> {data.submitted_at}
                            </Typography> */}
                            <Typography sx={{ fontSize: 16 }} gutterBottom>
                                <b>ERR:</b> {data.ERR}
                            </Typography>
                            <Typography sx={{ fontSize: 16 }} gutterBottom>
                                <b>Contact Email:</b> {data.customer_email}
                            </Typography>
                            <Typography sx={{ fontSize: 16 }} gutterBottom>
                                <b>Description:</b> {data.description}
                            </Typography>
                        </CardContent>
                        <CardContent sx={{ flexGrow: 2 }}>
                            <Typography variant="h6">Assessment Status</Typography>
                            <Typography sx={{ fontSize: 16 }} gutterBottom>
                                <b>Status:</b> {data.status}
                            </Typography>
                            {data.status.toLowerCase() !== 'created' && data.status.toLowerCase() !== 'saved' ? (
                                <Typography sx={{ fontSize: 16 }} gutterBottom>
                                    <b>Date Completed:</b> {data.submitted_at.toLocaleString()}
                                </Typography>
                            ) : null}
                            <Typography sx={{ fontSize: 16 }} gutterBottom>
                                <b>Last Edited:</b> {data.last_edited.toLocaleString()}
                            </Typography>
                            <Typography sx={{ fontSize: 16 }} gutterBottom>
                                <b>Date Started:</b> {data.answered_at.toLocaleString()}
                            </Typography>
                        </CardContent>
                        <Box sx={{ flex: 1, flexGrow: 1, minWidth: '20rem', margin: '2rem' }}>
                            {(data.status === 'created' || data.status === 'saved') && (
                                <Button variant="contained" sx={{ margin: '5px 5px' }} fullWidth onClick={() => history.push(resumeLink)}>
                                    Resume Assessment
                                </Button>
                            )}
                            {/* <Box sx={{ display: 'flex' }}>
                                <Button variant="contained" disabled={data.status !== 'Closed'} fullWidth sx={{ margin: '10px' }}>
                                    Re-open
                                </Button>
                                <Button variant="contained" disabled={data.status === 'Closed'} fullWidth sx={{ margin: '10px 0' }}>
                                    Close
                                </Button>
                            </Box> */}
                            <Box>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    disabled={!data.blob || !downloadLinks.assessment}
                                    sx={{ margin: '5px 5px' }}
                                    onClick={async () => {
                                        const downloadUrl = await getDownloadLink(data.id, downloadLinks.assessment);
                                        window.location.href = downloadUrl;
                                    }}>
                                    Download Assessment
                                </Button>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    disabled={!data.blob || !downloadLinks.proposalPDF}
                                    sx={{ margin: '5px 5px' }}
                                    onClick={handleClick}
                                    id="download-button">
                                    {(data.service || data.service_name) === 'Innovation Intake' ? 'Download Presentation' : 'Download Budgetary Proposal'}
                                </Button>
                                <Popover
                                    id="download-menu"
                                    aria-labelledby="download-button"
                                    anchorEl={anchorEl}
                                    open={openDowloadMenu}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}>
                                    <List>
                                        <ListItem
                                            button
                                            onClick={async () => {
                                                const downloadUrl = await getDownloadLink(data.id, downloadLinks.proposalPDF);
                                                window.location.href = downloadUrl;
                                                handleClose();
                                            }}>
                                            Download as PDF
                                        </ListItem>
                                        <ListItem
                                            button
                                            onClick={async () => {
                                                const downloadUrl = await getDownloadLink(data.id, downloadLinks.proposalPPT);
                                                window.location.href = downloadUrl;
                                                handleClose();
                                            }}>
                                            Download as PowerPoint
                                        </ListItem>
                                    </List>
                                </Popover>
                                {additionalButtons && additionalButtons(data.id)}
                            </Box>
                        </Box>
                    </Box>
                </Card>
            </Box>
        </>
    );
};

export default Metadata;
