import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import useCommonStyles from '../util/commonStyles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
}));

const TableauDashboard = ({ dashboardUrl, userJWT, loading, error, height, width }) => {
    const commonClasses = useCommonStyles();
    const classes = useStyles();

    const renderDashboard = () => {
        if (dashboardUrl !== null && userJWT !== null) {
            return <tableau-viz id="tableauViz" src={dashboardUrl} token={userJWT} toolbar="hidden" hide-tabs height={height} width={width}></tableau-viz>;
        } else if (loading) {
            return <CircularProgress sx={{ alignItems: 'center', justifyContent: 'center' }} style={{ margin: '3rem auto' }} />;
        } else if (error) {
            return (
                <Typography className={commonClasses.error} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '3rem' }}>
                    Failed to load dashboard. Please refresh to try again.
                </Typography>
            );
        }
    };

    return <div className={classes.root}>{renderDashboard()}</div>;
};

export default TableauDashboard;
