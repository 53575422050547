import { configureStore } from '@reduxjs/toolkit'
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { dataProtectionApi } from './DataProtectionApi';
import { maturityModelApi } from './MaturityModelApi';
import { sitesApi } from '../modules/spoa/practiceSite/SitesApi';
import reducers from "./reducers";

export const AppHistory = createBrowserHistory();

const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(routerMiddleware(AppHistory)).concat(dataProtectionApi.middleware).concat(maturityModelApi.middleware).concat(sitesApi.middleware),
  });

export default store;