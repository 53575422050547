import React from 'react';
import { CircularProgress, Typography } from '@mui/material';

import useCommonStyles from '../../util/commonStyles';

const CheckingPermissions = ({ permission }) => {
    const commonClasses = useCommonStyles();
    // const [permissionExpired, setPermissionExpired] = React.useState(false);

    // React.useEffect(() => {
    //     const timer = setTimeout(() => setPermissionExpired(true), 6000);

    //     return () => clearTimeout(timer);
    // }, []);

    return (
        <div className={commonClasses.centerScreen} style={{ display: 'flex', flexDirection: 'column' }}>
            {permission === false ? (
                <Typography>You do not have permission to view this page</Typography>
            ) : (
                <>
                    <CircularProgress sx={{ margin: '5rem 0 2rem 0' }} />
                    <Typography>Checking Your Permissions...</Typography>
                </>
            )}
        </div>
    );
};

export default CheckingPermissions;
