import React from 'react';
import { Box, Typography, Button, Tooltip, Zoom, Backdrop, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Select from 'react-select';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import { useCreateUpdateGroupMutation, useDeleteGroupMutation, useEditUserGroupMutation } from '../../../app/DataProtectionApi';
import useCommonStyles from '../../../util/commonStyles';

const useStyles = makeStyles((theme) => ({
    groupHeader: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.background.paper,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '10px',
        height: '3rem',
        padding: '0 1rem',
    },
    groupBody: {
        //backgroundColor: '#e8e8e8',
        //borderRadius: '5px',
        display: 'flex',
    },
}));

const Group = ({ group, availableActions, users }) => {
    const classes = useStyles();
    const commonClasses = useCommonStyles();
    const [isEditing, setIsEditing] = React.useState(false);
    const [groupToDelete, setGroupToDelete] = React.useState(false);

    const [createUpdateGroup, { isLoading: creatingUpdatingGroup }] = useCreateUpdateGroupMutation();

    const handlePermissionChange = (permissions, { action, removedValue, option }) => {
        console.log(removedValue, option);

        let newActions = [...group.actions];

        if (removedValue || option) {
            if (removedValue) {
                // remove item
                console.log(group);

                const indexRemove = group.actions.indexOf(removedValue.value);
                if (indexRemove > -1) {
                    newActions.splice(indexRemove, 1);
                }
            } else {
                newActions.push(option.value);
            }
            const newGroup = {
                id: group.id,
                name: group.name,
                service_id: group.service_id,
                actions: newActions,
            };
            createUpdateGroup(newGroup);
        } else {
            console.log(action);
        }
    };

    const [editUserGroup] = useEditUserGroupMutation();

    const handleUserChange = (users, { action, removedValue, option }) => {
        console.log(removedValue, option);

        if (removedValue || option) {
            let action = null;
            let userId = null;
            if (removedValue) {
                // remove item
                action = 'remove';
                userId = removedValue.value;
            } else {
                action = 'add';
                userId = option.value;
            }
            const body = {
                action: action,
                user_id: userId,
                group_id: group.id,
            };
            editUserGroup(body);
        } else {
            console.log(action);
        }
    };

    const [deleteGroup] = useDeleteGroupMutation();

    const deleteGroupCheck = (group) => {
        return (
            <>
                {groupToDelete ? (
                    <Backdrop sx={{ color: '#fff', zIndex: 9999 }} open={true}>
                        <Paper sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                            <Typography className={commonClasses.pageTitle} sx={{ margin: '.5rem 2rem' }} variant="h6">
                                Are you sure you want to delete the group: {group.name}?
                            </Typography>
                            <Box sx={{ display: 'flex' }}>
                                <Button
                                    fullWidth
                                    onClick={() => {
                                        deleteGroup(group.id);
                                        setGroupToDelete(false);
                                    }}>
                                    Delete it
                                </Button>
                                <Button
                                    fullWidth
                                    onClick={() => {
                                        setGroupToDelete(false);
                                    }}>
                                    Cancel
                                </Button>
                            </Box>
                        </Paper>
                    </Backdrop>
                ) : null}
            </>
        );
    };

    return (
        <Box sx={{ margin: '.5rem 4rem .5rem 0', borderRadius: '10px', backgroundColor: '#e8e8e8' }}>
            <Box className={classes.groupHeader}>
                <Typography>{group.name}</Typography>
                {!isEditing ? (
                    <Box>
                        <Tooltip title="Edit Group" TransitionComponent={Zoom} arrow>
                            <Button onClick={() => setIsEditing(true)}>
                                <EditIcon sx={{ color: '#e3e3e3' }} />
                            </Button>
                        </Tooltip>

                        <Tooltip title="Delete Group" TransitionComponent={Zoom} arrow>
                            <Button
                                onClick={() => {
                                    setGroupToDelete(true);
                                }}>
                                <DeleteForeverIcon sx={{ color: '#bf0b0b' }} />
                            </Button>
                        </Tooltip>
                    </Box>
                ) : (
                    <Box>
                        <Tooltip title="Finish Editing" TransitionComponent={Zoom} arrow>
                            <Button onClick={() => setIsEditing(false)}>
                                <CheckIcon sx={{ color: '#e3e3e3' }} />
                            </Button>
                        </Tooltip>
                    </Box>
                )}
            </Box>
            <Box className={classes.groupBody}>
                <Box sx={{ width: '100%', padding: '1rem' }}>
                    <Typography>Permissions:</Typography>
                    <Select
                        isDisabled={!isEditing}
                        isMulti
                        isClearable={false}
                        isLoading={creatingUpdatingGroup}
                        name="permissions"
                        value={group.actions.map((action) => ({ value: action, label: action }))}
                        options={availableActions.map((action) => ({ value: action, label: action }))}
                        onChange={handlePermissionChange}
                        className="basic-multi-select"
                        classNamePrefix="select"
                    />
                </Box>
                <Box sx={{ width: '100%', padding: '1rem' }}>
                    <Typography>Users:</Typography>
                    <Select
                        isDisabled={!isEditing}
                        isMulti
                        isClearable={false}
                        name="users"
                        defaultValue={group.users.map((user) => ({ value: user.id, label: user.name })).sort((a, b) => a.label.localeCompare(b.label))}
                        options={users ? users.map((user) => ({ value: user.id, label: user.name })).sort((a, b) => a.label.localeCompare(b.label)) : []}
                        onChange={handleUserChange}
                        className="basic-multi-select"
                        classNamePrefix="select"
                    />
                </Box>
            </Box>
            {deleteGroupCheck(group)}
        </Box>
    );
};

export default Group;
