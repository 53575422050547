import React from 'react';
import { Checkbox, Step, StepContent, StepLabel, Stepper, Box, Button, Typography, StepConnector } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const SectionCheckboxFieldWrapper = ({ fields, options, isDisabled, tipsEnabled }) => {
    const toggle = (event, option) => {
        if (event.target.checked) {
            fields.value.answer.push(option);
        } else {
            const index = fields.value.answer.indexOf(option);
            fields.value.answer.splice(index, 1);
        }
    };

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (index) => {
        if (index === activeStep) {
            setActiveStep(options.length);
        } else {
            setActiveStep(index);
        }
    };

    return (
        <Stepper activeStep={isDisabled ? -1 : activeStep} sx={{ alignItems: 'flex-start' }} connector={<StepConnector sx={{ marginTop: '.75rem' }} />}>
            {options.map((option, index) => (
                <Step expanded={isDisabled} completed={false}>
                    <StepLabel onClick={() => handleStep(index)}>
                        <Typography variant="subtitle1">{option.section}</Typography>
                    </StepLabel>
                    <StepContent>
                        {tipsEnabled && option.hint && (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <InfoIcon />
                                <Typography variant="caption" sx={{ margin: '.25rem 0 0 1rem' }}>
                                    {option.hint}
                                </Typography>
                            </Box>
                        )}
                        {option.checkboxes.map((checkbox) => (
                            <div key={checkbox} style={{ display: 'flex', alignItems: 'center', margin: '1rem 0' }}>
                                <Checkbox
                                    aria-labelledby={`${fields.name}.${checkbox}`}
                                    value={checkbox}
                                    defaultChecked={fields.value.answer.indexOf(checkbox) > -1}
                                    onChange={(event) => toggle(event, checkbox)}
                                    disabled={isDisabled}
                                />
                                {checkbox}
                            </div>
                        ))}
                        {!isDisabled && (
                            <Box sx={{ mb: 2 }}>
                                <div>
                                    <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                                        Continue
                                    </Button>
                                    <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                                        Back
                                    </Button>
                                </div>
                            </Box>
                        )}
                    </StepContent>
                </Step>
            ))}
        </Stepper>
    );
};

export default SectionCheckboxFieldWrapper;
