import { BlobServiceClient } from '@azure/storage-blob';
import axios from 'axios';
import oktaAuth from '../auth/OktaAuth';

const GetBlobServiceClient = async () => {
    const options = {
        method: 'get',
        url: 'https://innovation-azure-pricing-api-apim.azure-api.net/azure-pricing-api-backend/GenerateSAS',
        headers: {
            Authorization: `Bearer ${oktaAuth.getIdToken()}`,
            'Access-Control-Allow-Origin': '*',
        },
    };
    const res = await axios(options);
    return new BlobServiceClient(res.data);
};

const uploadFile = async (acceptedFiles, fileName, selection, sfid, failureToReadFile, uploadError, uploadSuccess) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onabort = failureToReadFile;
    reader.onerror = failureToReadFile;
    reader.onload = async () => {
        try {
            const binaryStr = reader.result;

            const blobServiceClient = await GetBlobServiceClient();
            const containerName = 'portalupload';
            const containerClient = blobServiceClient.getContainerClient(containerName);
            const content = binaryStr;
            const blobName = '' + new Date().getTime() + '-' + fileName;
            const blockBlobClient = containerClient.getBlockBlobClient(blobName);

            await blockBlobClient.upload(content, content.byteLength);

            // TODO: Request to Backend here
            const options = {
                method: 'post',
                url: 'https://innovation-azure-pricing-api-apim.azure-api.net/azure-pricing-api-backend/UploadFile',
                headers: {
                    Authorization: `Bearer ${oktaAuth.getIdToken()}`,
                    'Access-Control-Allow-Origin': '*',
                },
                data: {
                    sfid: sfid,
                    blob_name: blobName,
                    type: selection,
                    uploaded_by: (await oktaAuth.getUser()).email,
                },
            };
            try {
                await axios(options);
            } catch (e) {
                if (e.response) {
                    if (e.response.data === `Record does not exist. Please upload spec file first.`) {
                        uploadError('SFID does not exist- upload a Production Specification first');
                        return;
                    }
                    throw e;
                }
            }

            uploadSuccess();
        } catch (e) {
            uploadError('Failed to upload ' + fileName);
        }
    };
    reader.readAsArrayBuffer(file);
};

export { uploadFile };
