import moment from 'moment';

export const getWeeks = () => {
    const firstWeek = [moment.utc().startOf('week'), moment.utc().endOf('week')];
    const secondWeek = [moment().utc().add(1, 'weeks').startOf('week'), moment().utc().add(1, 'weeks').endOf('week')];
    const thirdWeek = [moment().utc().add(2, 'weeks').startOf('week'), moment().utc().add(2, 'weeks').endOf('week')];
    const fourthWeek = [moment().utc().add(3, 'weeks').startOf('week'), moment().utc().add(3, 'weeks').endOf('week')];

    return {
        firstWeek,
        secondWeek,
        thirdWeek,
        fourthWeek,
    };
};

export const dateExistsInWeek = ({ date, week }) => {
    const d = moment.utc(date, 'YYYY-MM-DD');

    return d.isBetween(getWeeks()[week][0], getWeeks()[week][1]) || d.isSame(getWeeks()[week][0]) || d.isSame(getWeeks()[week][1]);
};

export  const summarizeNum = (num) => {
    const splitNum = num.toLocaleString().split(',');
    const millionsLength = 3,
        billionsLength = 4,
        thousandsLength = 2;

    if (splitNum.length === billionsLength) {
        return `${splitNum[0]}.${splitNum[1]}B`;
    } else if (splitNum.length === millionsLength) {
        return `${splitNum[0]}.${splitNum[1]} mil`;
    } else if (splitNum.length === thousandsLength) {
        return `${splitNum[0]}.${splitNum[1]}k`;
    } else return num;
};