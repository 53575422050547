import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box, Button, Card, Typography } from '@mui/material';
import useCommonStyles from '../../../../util/commonStyles';
import aiConclusion from './ai-thank-you.png';
import aiRoadmap from './ai-roadmap.png';

const AiWorkshopConclusion = ({ match }) => {
    const history = useHistory();
    const commonClasses = useCommonStyles();

    const { id } = useParams();

    return (
        <>
            <Typography className={commonClasses.pageTitle} variant="h4">
                Assessment Conclusion
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'stretch' }}>
                <img src={aiRoadmap} alt="Conclusion" style={{ margin: '0%', marginTop: '.5rem', width: '100%', minWidth: '780px', maxWidth: '1240px', borderRadius: '.5rem' }} />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className={commonClasses.centerScreen}>
                <Typography>This assessment has already been submitted.</Typography>
                <Button onClick={() => history.push(`/pre-sales/${id}/detailedResults`)}>View Assessment Details</Button>
            </Box>
        </>
    );
};

export default AiWorkshopConclusion;
