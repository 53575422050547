import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import { sendToastMessage } from '../../app/actions/UiAction';

const notificationsArray = [
    {
        title: 'Notification 1 Title',
        message: 'This is the notifications message, we can put whatever we want here and it will automatically resize. This is an example of a really long message',
    },
    {
        title: 'Notification 2 Title',
        message: 'This is the notifications message, we can put whatever we want here',
    },
    {
        title: 'Notification 3 Title',
        message: 'An action needs to be taken on your account, do you approve?',
        approval: true,
    },
];

const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'center',
        padding: '5px 0',
    },
    card: {
        margin: '1% 5%',
        padding: '5px',
        whiteSpace: 'pre-wrap'
    },
    buttons: {
        paddingTop: '2%',
        paddingLeft: '2%',
    }
}));

const Notifications = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [notifications, setNotifications] = React.useState(notificationsArray)

    const handleApprove = (notification) => (event) => {
        dispatch(sendToastMessage('Approval Submitted'));
        const index = notifications.indexOf(notification);
        if (index > -1) {
            let tempNotifications = [...notifications]
            tempNotifications.splice(index, 1);
            setNotifications(tempNotifications)
        }
    }

    const handleDeny = (notification) => (event) => {
        dispatch(sendToastMessage('Denial Submitted'));
        const index = notifications.indexOf(notification);
        if (index > -1) {
            let tempNotifications = [...notifications]
            tempNotifications.splice(index, 1);
            setNotifications(tempNotifications)
        }
    }

    const handleDismiss = (notification) => (event) => {
        const index = notifications.indexOf(notification);
        if (index > -1) {
            let tempNotifications = [...notifications]
            tempNotifications.splice(index, 1);
            setNotifications(tempNotifications)
        }
    }

    return (
        <React.Fragment>
            <Typography className={classes.title} variant="h6">
                Notifications
            </Typography>
            {notifications.map((notification) => {
                return (
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography variant="h6">{notification.title}</Typography>
                            <Typography variant="body2">{notification.message}</Typography>
                            {notification.approval? (
                                <Box className={classes.buttons}>
                                    <Button variant="contained" onClick={handleApprove(notification)}>Approve</Button>
                                    <Button sx={{marginLeft: '2%'}}variant="outlined" onClick={handleDeny(notification)}>Deny</Button>
                                </Box>
                            ):(<Box className={classes.buttons}>
                                <Button variant="contained" onClick={handleDismiss(notification)}>Dismiss</Button>
                            </Box>)}
                        </CardContent>
                    </Card>
                );
            })}
        </React.Fragment>
    );
};

export default Notifications;
