import React from 'react';
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import SellIcon from '@mui/icons-material/Sell';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PsychologyIcon from '@mui/icons-material/Psychology';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { hasPermission, hasPermissionMultiple } from '../../../util/permissions';

const PresalesNav = ({ currentUser }) => {
    const [presalesOpen, setPresalesOpen] = React.useState(false);

    return (
        <>
            <ListItem button key="pre-sales-tools" onClick={() => setPresalesOpen(!presalesOpen)}>
                <ListItemIcon sx={{ minWidth: '40px' }}>
                    <SellIcon />
                </ListItemIcon>
                <ListItemText primary="Pre-Sales Tools" />
                {presalesOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={presalesOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem button sx={{ pl: 3 }} component={NavLink} to="/pre-sales/overview" selected={Boolean(useRouteMatch('/pre-sales/overview'))}>
                        <ListItemIcon sx={{ minWidth: '40px' }}>
                            <ManageSearchOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Pre-sales Overview" />
                    </ListItem>
                    <ListItem button sx={{ pl: 3 }} component={NavLink} to="/pre-sales/myAssessments" selected={Boolean(useRouteMatch('/pre-sales/myAssessments'))}>
                        <ListItemIcon sx={{ minWidth: '40px' }}>
                            <ListAltIcon />
                        </ListItemIcon>
                        <ListItemText primary="My Assessments" />
                    </ListItem>
                    {hasPermission(currentUser, 'f1f933a5-a61e-4be4-bfd3-f08d0ec7f1c6', 'left_pane') && (
                        <ListItem button sx={{ pl: 3 }} component={NavLink} to="/pre-sales/data-protection">
                            <ListItemIcon sx={{ minWidth: '40px' }}>
                                <PrivacyTipIcon />
                            </ListItemIcon>
                            <ListItemText primary="Data Protection" />
                        </ListItem>
                    )}
                    {hasPermission(currentUser, 'c166003b-ec15-4410-a26b-90e15a1594bc', 'left_pane') && (
                        <ListItem button sx={{ pl: 3 }} component={NavLink} to="/innovation/intake">
                            <ListItemIcon sx={{ minWidth: '40px' }}>
                                <EmojiObjectsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Innovation Intake" />
                        </ListItem>
                    )}
                    {hasPermission(currentUser, 'e11f6cd7-7989-484e-9c09-792240af01b2', 'left_pane') && (
                        <ListItem button sx={{ pl: 3 }} component={NavLink} to="/pre-sales/ava-collab">
                            <ListItemIcon sx={{ minWidth: '40px' }}>
                                <PrivacyTipIcon />
                            </ListItemIcon>
                            <ListItemText primary="AVA" />
                        </ListItem>
                    )}
                    {hasPermission(currentUser, '750dd17a-688a-4af4-b2eb-88493cf44e6d', 'left_pane') && (
                        <ListItem button sx={{ pl: 3 }} component={NavLink} to="/ai/workshop">
                            <ListItemIcon sx={{ minWidth: '40px' }}>
                                <PsychologyIcon />
                            </ListItemIcon>
                            <ListItemText primary="AI Envisioning Survey" />
                        </ListItem>
                    )}
                    {hasPermissionMultiple(currentUser, {
                        'f1f933a5-a61e-4be4-bfd3-f08d0ec7f1c6': 'data_security_config',
                        'e11f6cd7-7989-484e-9c09-792240af01b2': 'ava_collaboration_spaces_config',
                        'c166003b-ec15-4410-a26b-90e15a1594bc': 'innovation_intake_config',
                    }) && (
                        <ListItem button sx={{ pl: 3 }} component={NavLink} to="/pre-sales/adminConfig">
                            <ListItemIcon sx={{ minWidth: '40px' }}>
                                <AdminPanelSettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Admin Config" />
                        </ListItem>
                    )}
                </List>
            </Collapse>
        </>
    );
};

export default PresalesNav;
