import React from 'react';
import { Button, Box, FormControl, MenuItem, TableCell, TableHead, TableRow, TableSortLabel, TextField, Typography, Collapse, Tooltip, Zoom } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';

const EnhancedTableHead = (props) => {
    const { order, orderBy, onRequestSort, rows, visibleFilters, updateFilters, filters, toggleVisibleFilters } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const renderFilter = (column) => {
        switch (column.filterable) {
            case 'search':
                return (
                    <Collapse in={visibleFilters.includes(column.id)}>
                        <TextField
                            fullWidth
                            //sx={{ visibility: visibleFilters.includes(column.id) ? 'visible' : 'hidden' }}
                            //sx={{ display: visibleFilters.includes(column.id) ? 'block' : 'none' }}
                            variant="standard"
                            size="small"
                            label="search"
                            onChange={(e) => updateFilters(column, e.target.value)}
                            value={filters[column.id]}
                        />
                    </Collapse>
                );
            case 'dropdown':
                let seenOptions = {};
                let options = [];
                rows.forEach((row) => {
                    if (!(row[column.id].toString().toLowerCase() in seenOptions)) {
                        seenOptions[row[column.id].toString().toLowerCase()] = 1;
                        options.push(row[column.id].toString().toLowerCase());
                    }
                });
                var collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
                options.sort(collator.compare);

                return (
                    <Collapse in={visibleFilters.includes(column.id)}>
                        <FormControl style={{ width: '100%' }}>
                            <TextField
                                select
                                //sx={{ visibility: visibleFilters.includes(column.id) ? 'visible' : 'hidden' }}
                                width={'100%'}
                                variant="standard"
                                size="small"
                                label="select"
                                onChange={(e) => updateFilters(column, e.target.value)}
                                value={filters[column.id]}>
                                <MenuItem key="" value="">
                                    any
                                </MenuItem>
                                {options.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                                {options.length < 1 && filters[column.id] !== '' && (
                                    <MenuItem key={filters[column.id]} value={filters[column.id]}>
                                        {filters[column.id]}
                                    </MenuItem>
                                )}
                            </TextField>
                        </FormControl>
                    </Collapse>
                );
            default:
                return null;
        }
    };

    return (
        <TableHead>
            <TableRow>
                {props.columns.map((column) => (
                    <TableCell
                        key={column.id}
                        sx={{ display: column.collapsed ? 'none' : 'row' }}
                        align={column.numeric ? 'right' : 'left'}
                        sortDirection={orderBy === column.id ? order : false}>
                        {column.label !== '' && (
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        sx={{ display: 'flex', flexDirection: 'row' }}
                                        direction={orderBy === column.id ? order : 'asc'}
                                        onClick={createSortHandler(column.id)}>
                                        <Typography variant="h6">{column.label}</Typography>
                                        {orderBy === column.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                    {visibleFilters.includes(column.id) ? (
                                        <Button size="small" onClick={() => toggleVisibleFilters(column)}>
                                            Clear Filter
                                        </Button>
                                    ) : (
                                        <Tooltip title="Apply Filter" TransitionComponent={Zoom} arrow>
                                            <Button size="small" onClick={() => toggleVisibleFilters(column)}>
                                                <FilterListIcon />
                                            </Button>
                                        </Tooltip>
                                    )}
                                </Box>
                                {column.filterable && renderFilter(column)}
                            </Box>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default EnhancedTableHead;
