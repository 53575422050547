import React from 'react';
import { CircularProgress, Collapse, Divider, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import HomeIcon from '@mui/icons-material/Home';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import SettingsIcon from '@mui/icons-material/Settings';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import FeedIcon from '@mui/icons-material/Feed';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import useCommonStyles from '../../../util/commonStyles';
import { hasPermission, isMscUser, isPresalesUser } from '../../../util/permissions';
import PresalesNav from './PresalesNav';
import MscNav from './MscNav';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        zIndex: 1000,
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(6)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(7)} + 1px)`,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    navBarIcon: {
        minWidth: '30px',
    },
}));

const Navbar = ({ currentUser, open }) => {
    const [cxOpen, setCxOpen] = React.useState(false);
    const commonClasses = useCommonStyles();
    const classes = useStyles();

    return (
        <>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}>
                <div className={classes.toolbar} />
                <Divider />
                {currentUser === null ? (
                    <div className={commonClasses.centerScreen} style={{ display: 'flex', flexDirection: 'column' }}>
                        <CircularProgress sx={{ margin: '5rem 0 2rem 0' }} />
                    </div>
                ) : (
                    <List>
                        <ListItem button key="home" component={NavLink} to="/" sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <ListItemIcon sx={{ minWidth: '40px' }}>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Home" sx={{ marginLeft: '0rem' }} />
                        </ListItem>
                        {isPresalesUser(currentUser) && <PresalesNav currentUser={currentUser} />}
                        {isMscUser(currentUser) && <MscNav currentUser={currentUser} />}
                        <ListItem button key="pre-sales-tools" onClick={() => setCxOpen(!cxOpen)}>
                            <ListItemIcon sx={{ minWidth: '40px' }}>
                                <FormatListBulletedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Customer Experience" />
                            {cxOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={cxOpen} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {hasPermission(currentUser, '5e87b2c3-20df-405f-8786-99f7447d84cd', 'view') && (
                                    <ListItem button sx={{ pl: 3 }} component={NavLink} to="/cx/subscriptions">
                                        <ListItemIcon sx={{ minWidth: '40px' }}>
                                            <FeedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="CX Subscriptions" />
                                    </ListItem>
                                )}
                                {hasPermission(currentUser, '5e87b2c3-20df-405f-8786-99f7447d84cd', 'view') && (
                                    <ListItem button sx={{ pl: 3 }} component={NavLink} to="/cx/lifecycle/overview">
                                        <ListItemIcon sx={{ minWidth: '40px' }}>
                                            <ChangeCircleIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Lifecycle Incentive Overview" />
                                    </ListItem>
                                )}
                                {hasPermission(currentUser, '5e87b2c3-20df-405f-8786-99f7447d84cd', 'view') && (
                                    <ListItem button sx={{ pl: 3 }} component={NavLink} to="/cx/lifecycle/detailed">
                                        <ListItemIcon sx={{ minWidth: '40px' }}>
                                            <ListAltIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Lifecycle Detailed" />
                                    </ListItem>
                                )}
                            </List>
                        </Collapse>

                        {hasPermission(currentUser, '210032ed-3b2e-4b80-ac12-7714b8e81ade', 'left_pane', 'manage_practices') && (
                            <ListItem button component={NavLink} to="/spoa/practice-Site">
                                <ListItemIcon sx={{ minWidth: '40px' }}>
                                    <SettingsIcon />
                                </ListItemIcon>
                                <ListItemText primary="SPOA Practices Admin" />
                            </ListItem>
                        )}
                        {hasPermission(currentUser, '10536f63-edd2-4d4b-9134-df155790c4e7', 'innovation_preview') && (
                            <ListItem button key="admin" component={NavLink} to="/innovation/eol-dashboard">
                                <ListItemIcon sx={{ minWidth: '40px' }}>
                                    <TrendingDownIcon />
                                </ListItemIcon>
                                <ListItemText primary="EoL Dashboard" />
                            </ListItem>
                        )}
                        {hasPermission(currentUser, '934f4955-6586-40dc-abd2-e2655b3e1e8d', 'left_pane') && (
                            <ListItem button key="admin" component={NavLink} to="/admin">
                                <ListItemIcon sx={{ minWidth: '40px' }}>
                                    <SettingsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Admin" />
                            </ListItem>
                        )}
                    </List>
                )}
            </Drawer>
        </>
    );
};

export default Navbar;
