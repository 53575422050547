import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Paper, Typography, Box, ToggleButtonGroup, ToggleButton, Button, TextField, Backdrop, CircularProgress } from '@mui/material';
import { sendToastMessage } from '../../app/actions/UiAction';
import DragDrop from './components/DragDrop';
import { uploadFile } from './Blob';
import useCommonStyles from '../../util/commonStyles';

const useStyles = makeStyles((theme) => ({
    form: {
        padding: '20px',
    },
    documentDetails: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '20px',
    },
    buttonGroup: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        paddingBottom: '20px',
    },
    fieldBox: {
        padding: '20px',
        maxWidth: '800px',
    },
    errorMessage: {
        color: 'red',
    },
}));

const Upload = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const commonClasses = useCommonStyles();
    const [fileName, setFileName] = useState('');
    //const [loading, setLoading] = useState(false);
    const [acceptedFiles, setAcceptedFiles] = useState([]);
    const [addingNewFile, setAddingNewFile] = useState(true);
    const [selection, setSelection] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [uploading, setUploading] = useState(false);
    const sfidRef = useRef('');

    const handleButtons = (event, selection) => {
        setSelection(selection);
    };

    const failureToReadFile = (e) => {
        dispatch(sendToastMessage('Failed to read file'));
    };

    const uploadSuccess = () => {
        setUploading(false);
        dispatch(sendToastMessage('Successfully uploaded ' + fileName));
        cancelForm();
    };

    const uploadError = (msg) => {
        setUploading(false);
        dispatch(sendToastMessage(msg));
    };

    const submitForm = () => {
        setErrorMessage(null);

        if (fileName === '') {
            setErrorMessage('Document is required for upload!');
            return;
        }
        if (selection === null) {
            setErrorMessage('Document Type is required!');
            return;
        }
        if (sfidRef.current.value === (undefined || '')) {
            setErrorMessage('SFID is required!');
            return;
        }

        setUploading(true);

        uploadFile(acceptedFiles, fileName, selection, sfidRef.current.value, failureToReadFile, uploadError, uploadSuccess);
    };

    const cancelForm = () => {
        setErrorMessage(null);
        setSelection(null);
        setFileName('');
        setAcceptedFiles([]);
        setAddingNewFile(true);
        sfidRef.current.value = '';
    };

    const DisplayError = () => {
        return (
            <TextField
                className={classes.errorMessage}
                fullWidth
                sx={{ input: { color: 'red' } }}
                error
                id="outlined-error"
                defaultValue={errorMessage}
                inputProps={{ readOnly: true }}></TextField>
        );
    };

    return (
        <div>
            <Typography className={commonClasses.pageTitle} variant="h4">
                File Upload
            </Typography>
            <Paper className={classes.form}>
                {uploading ? (
                    <Backdrop sx={{ color: '#fff', zIndex: 999 }} open={uploading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                ) : (
                    <></>
                )}
                <Typography variant="h5">Add Document</Typography>
                <DragDrop
                    fileName={fileName}
                    //loading={loading}
                    setAcceptedFiles={setAcceptedFiles}
                    setFileName={setFileName}
                    addingNewFile={addingNewFile}
                    setAddingNewFile={setAddingNewFile}
                />
                <Typography variant="h5">Document Details</Typography>
                <Box className={classes.documentDetails}>
                    <Typography variant="h6">Specification Type</Typography>
                    <ToggleButtonGroup className={classes.buttonGroup} value={selection} exclusive onChange={handleButtons} variant="text" aria-label="text button group">
                        <ToggleButton value="spec">Production</ToggleButton>
                        <ToggleButton value="spec-contingency">Contingency</ToggleButton>
                        <ToggleButton value="quote">Quote</ToggleButton>
                    </ToggleButtonGroup>
                    <Typography variant="h6">Salesforce ID</Typography>
                    <TextField id="outlined-basic" label="sfid" variant="outlined" inputRef={sfidRef} defaultValue={sfidRef.current !== null ? sfidRef.current.value : null} />
                </Box>
                {errorMessage === null ? null : (
                    <Box className={classes.fieldBox}>
                        <DisplayError />
                    </Box>
                )}
                <Box className={classes.buttonGroup}>
                    <Button onClick={submitForm} variant="contained" size="large" style={{ margin: '5px' }}>
                        Upload
                    </Button>
                    <Button onClick={cancelForm} variant="outlined" size="large" style={{ margin: '5px' }}>
                        Cancel
                    </Button>
                </Box>
            </Paper>
        </div>
    );
};

export default Upload;
