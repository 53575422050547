import React from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import AddComment from './AddComment';
import Comment from './Comment';

interface IProps {
    defaultComment: string;
}
const CXFeedback = ({ defaultComment }: IProps) => {
    const [isCommenting, setIsCommenting] = React.useState(false);
    const [comments, setComments] = React.useState([
        {
            user: 'Patty Frenette',
            commented_at: new Date().toLocaleString('en-US'),
            comment: defaultComment,
        },
    ]);

    return (
        <>
            <Typography variant="h6">Feedback</Typography>
            {comments.map((comment) => (
                <Comment user={comment.user} commented_at={comment.commented_at} comment={comment.comment} />
            ))}
            {isCommenting ? (
                <AddComment isCommenting={isCommenting} setIsCommenting={setIsCommenting} comments={comments} setComments={setComments} />
            ) : (
                <Button onClick={() => setIsCommenting(!isCommenting)}>Add Comment</Button>
            )}
        </>
    );
};

export default CXFeedback;
