import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Fab, List, ListItem, ListItemText, Popover, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';
import CheckingPermissions from '../../base/CheckingPermissions';
import { getPresalesAssessments } from '../../../util/utilFunctions';

const useStyles = makeStyles((theme) => ({
    newButton: {
        color: theme.palette.background.paper,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
}));

const NewAssessment = ({ currentUser }) => {
    const history = useHistory();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openNewAssessmentMenu = Boolean(anchorEl);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const listOptions = () => {
        let newAssessmentOptions = [];
        getPresalesAssessments().forEach((assessment) => {
            Object.entries(currentUser.actions).forEach(([serviceId, details]) => {
                if (details.service_name === assessment.name && details.actions.indexOf('left_pane') > -1) {
                    newAssessmentOptions.push(
                        <ListItem button key={serviceId} onClick={() => history.push(assessment.path)}>
                            <ListItemText primary={assessment.name} />
                        </ListItem>,
                    );
                }
            });
        });
        console.log(newAssessmentOptions);
        return newAssessmentOptions.length > 0 ? (
            newAssessmentOptions
        ) : (
            <ListItem key="empty">
                <ListItemText primary={`It looks like you don't have permission to create assessments`} />
            </ListItem>
        );
    };

    return (
        <Fab variant="extended" color="secondary" size="small" sx={{ margin: '0 2rem', minWidth: '14rem' }}>
            <AddIcon className={classes.newButton} sx={{ marginBottom: '.1rem', marginRight: '.3rem' }} />
            <Typography className={classes.newButton} onClick={handleMenu}>
                New Assessment
            </Typography>

            <Popover
                id="menu-new-assessment"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={openNewAssessmentMenu}
                onClose={handleClose}>
                <div className={classes.toolbar}>
                    <>{currentUser ? <List>{listOptions()}</List> : <CheckingPermissions />}</>
                </div>
            </Popover>
        </Fab>
    );
};

const mapStateToProps = (state) => {
    return { currentUser: state.User.user };
};

export default connect(mapStateToProps)(NewAssessment);
