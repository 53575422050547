import React from 'react';
import { SecureRoute } from '@okta/okta-react';
import cxInnovation from './innovation/innovation';
import cxSubscriptions from './subscriptions/subscriptions';
import LifecycleDetailed from './lifecycle/detailed/DetailedView';
import { hasPermission } from '../../util/permissions';
import CheckingPermissions from '../base/CheckingPermissions';
import Overview from './lifecycle/overview/Overview';

const CXRoutes = (currentUser) => [
    hasPermission(currentUser, '5e87b2c3-20df-405f-8786-99f7447d84cd', 'view') ? (
        <SecureRoute key={'cx-inno'} path="/cx/innovation" component={cxInnovation} />
    ) : (
        <SecureRoute
            key={'cx-inno'}
            path="/cx/innovation"
            render={() => <CheckingPermissions permission={hasPermission(currentUser, '5e87b2c3-20df-405f-8786-99f7447d84cd', 'view')} />}
        />
    ),
    hasPermission(currentUser, '5e87b2c3-20df-405f-8786-99f7447d84cd', 'view') ? (
        <SecureRoute key={'cx-subs'} path="/cx/subscriptions" component={cxSubscriptions} />
    ) : (
        <SecureRoute
            key={'cx-subs'}
            path="/cx/subscriptions"
            render={() => <CheckingPermissions permission={hasPermission(currentUser, '5e87b2c3-20df-405f-8786-99f7447d84cd', 'view')} />}
        />
    ),
    hasPermission(currentUser, '5e87b2c3-20df-405f-8786-99f7447d84cd', 'view') ? (
        <SecureRoute key={'cx-lifecycle-detailed'} path="/cx/lifecycle/detailed" component={LifecycleDetailed} />
    ) : (
        <SecureRoute
            key={'cx-lifecycle-detailed'}
            path="/cx/lifecycle/detailed"
            render={() => <CheckingPermissions permission={hasPermission(currentUser, '5e87b2c3-20df-405f-8786-99f7447d84cd', 'view')} />}
        />
    ),
    hasPermission(currentUser, '5e87b2c3-20df-405f-8786-99f7447d84cd', 'view') ? (
        <SecureRoute key={'cx-lifecycle-overview'} path="/cx/lifecycle/overview" component={Overview} />
    ) : (
        <SecureRoute
            key={'cx-lifecycle-detailed'}
            path="/cx/lifecycle/overview"
            render={() => <CheckingPermissions permission={hasPermission(currentUser, '5e87b2c3-20df-405f-8786-99f7447d84cd', 'view')} />}
        />
    ),
];

export default CXRoutes;
