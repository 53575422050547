import React from 'react';
import { Tab, Tabs } from '@mui/material';

const ConfigTabs = ({ tab, setTab, configData }) => {
    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    const handleTab = (event, value) => {
        setTab(value);
    };

    return (
        <Tabs value={tab} onChange={handleTab} variant="fullWidth">
            {configData.map((tabItem, index) => {
                return <Tab key={`dp-tab-${index}`} label={tabItem.config_name} {...a11yProps(tab)} />;
            })}
        </Tabs>
    );
};

export default ConfigTabs;
