import React from 'react';
import { FormControl, Radio, RadioGroup } from '@mui/material';

const RadioButtonFieldWrapper = ({ fields, options, isDisabled }) => {
    const [value, setValue] = React.useState(fields.value.answer);

    const handleChange = (event) => {
        console.log(event);
        fields.value.answer = event.target.value;
        setValue(event.target.value);
    };

    React.useEffect(() => {
        //pass
    }, [fields.value.answer]);

    return (
        <FormControl>
            <RadioGroup value={value} onChange={handleChange}>
                {options.map((option) => (
                    <div key={option.id ?? option} style={{ display: 'flex', alignItems: 'center' }}>
                        <Radio
                            key={option.id ?? option}
                            aria-labelledby={`${fields.name}.${option.value ?? option}`}
                            value={JSON.stringify(option)}
                            //defaultChecked={fields.value.answer === option}
                            //onChange={(event) => toggle(event, option)}
                            disabled={isDisabled}
                        />
                        {option.value ?? option}
                    </div>
                ))}
            </RadioGroup>
        </FormControl>
    );
};

export default RadioButtonFieldWrapper;
