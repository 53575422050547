import React from 'react';
import { Collapse, Fab, List, ListItem, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Field } from 'react-final-form';
import { makeStyles } from '@mui/styles';
import { TransitionGroup } from 'react-transition-group';
import { composeValidators, required } from '../../../util/finalFormValidators';

const useStyles = makeStyles((theme) => ({
    newButton: {
        color: theme.palette.background.paper,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
}));

const MultipleTextFieldWrapper = ({ fields, options, isDisabled, maxFields, question, ...rest }) => {
    const classes = useStyles();
    const [countBoxes, setCountBoxes] = React.useState(0);

    React.useEffect(() => {
        if (fields.value.length === 0) {
            fields.value.push('');
        }
        setCountBoxes(fields.value.length);
    }, [fields.value]);

    const addResponse = () => {
        fields.value.push('');
        setCountBoxes(countBoxes + 1);
    };

    const updateResponse = (event, index) => {
        fields.value[index] = event.target.value;
    };

    const deleteResponse = (index) => {
        fields.value.splice(index, 1);
        setCountBoxes(countBoxes - 1);
    };

    return (
        <>
            <List>
                <TransitionGroup>
                    {[...Array(countBoxes)].map((x, i) => (
                        <Collapse key={`${fields.name}-${i}`}>
                            <ListItem key={`${fields.name}-${i}`} sx={{ display: 'flex', marginBottom: '1rem' }}>
                                <Field
                                    key={`${fields.name}-${i}`}
                                    style={{ maxWidth: '100%', width: '100%' }}
                                    disabled={isDisabled}
                                    isDisabled={isDisabled}
                                    //viewed={question.viewed}
                                    //className={formClasses.formField}
                                    name={fields.name}
                                    //value={fields.value[i]}
                                    //value={`${question.id}.value.answer`}
                                    render={(props) => {
                                        const showError = ((props.meta.submitError && !props.meta.dirtySinceLastSubmit) || props.meta.error) && props.meta.touched;
                                        return (
                                            <TextField
                                                name={props.name}
                                                key={fields.value[i].toString()}
                                                helperText={showError ? props.meta.error || props.meta.submitError : undefined}
                                                error={showError}
                                                onChange={(event) => updateResponse(event, i)}
                                                defaultValue={fields.value[i].toString()}
                                                disabled={isDisabled}
                                                multiline
                                                fullWidth
                                                placeholder={i === 0 ? 'Enter response here' : 'Enter response here - Must remove if unused'}
                                                validate={composeValidators(required)}
                                                minRows={props.input.minRows}
                                            />
                                        );
                                    }}
                                    variant="outlined"
                                    multiline
                                    minRows={1}
                                    placeholder="Enter Response Here"
                                    //onChange={(event) => updateResponse(event, i)}
                                    validate={composeValidators(required)}
                                />
                                {!isDisabled && (
                                    <Fab
                                        key={`${fields.name}-${i}-delete`}
                                        sx={{
                                            margin: '.5rem',
                                            backgroundColor: '#FF4646',
                                            '&:hover': { backgroundColor: '#FF0000' },
                                            visibility: countBoxes > 1 ? 'visible' : 'hidden',
                                        }}
                                        size="small"
                                        onClick={() => deleteResponse(i)}>
                                        <DeleteForeverIcon className={classes.newButton} />
                                    </Fab>
                                )}
                            </ListItem>
                        </Collapse>
                    ))}
                </TransitionGroup>
            </List>
            {countBoxes < maxFields && !isDisabled && (
                <Fab variant="extended" color="secondary" size="small" sx={{ margin: '0 1rem 1rem 1rem' }} onClick={() => addResponse()}>
                    <AddIcon className={classes.newButton} sx={{ marginBottom: '.1rem', marginRight: '.3rem' }} />
                    <Typography className={classes.newButton}>Add another</Typography>
                </Fab>
            )}
        </>
    );
};

export default MultipleTextFieldWrapper;
