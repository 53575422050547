import React from 'react';
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { NavLink } from 'react-router-dom';
import { hasPermission } from '../../../util/permissions';

const MscNav = ({ currentUser }: { currentUser: CurrentUser }) => {
    const [mscOpen, setMscOpen] = React.useState(false);

    return (
        <>
            <ListItem button key="pre-sales-tools" onClick={() => setMscOpen(!mscOpen)}>
                <ListItemIcon sx={{ minWidth: '40px' }}>
                    <ManageAccountsIcon />
                </ListItemIcon>
                <ListItemText primary="Managed Services" />
                {mscOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={mscOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {hasPermission(currentUser, '10536f63-edd2-4d4b-9134-df155790c4e7', 'view') && (
                        <ListItem button sx={{ pl: 3 }} component={NavLink} to="/msc/overview-dashboard">
                            <ListItemIcon sx={{ minWidth: '40px' }}>
                                <QueryStatsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Utilization" />
                        </ListItem>
                    )}
                    {hasPermission(currentUser, '10536f63-edd2-4d4b-9134-df155790c4e7', 'view') && (
                        <ListItem button sx={{ pl: 3 }} component={NavLink} to="/msc/service-desk-dashboard">
                            <ListItemIcon sx={{ minWidth: '40px' }}>
                                <MiscellaneousServicesIcon />
                            </ListItemIcon>
                            <ListItemText primary="Service Desk" />
                        </ListItem>
                    )}
                    {hasPermission(currentUser, '10536f63-edd2-4d4b-9134-df155790c4e7', 'preview') && (
                        <ListItem button sx={{ pl: 3 }} component={NavLink} to="/msc/field-notices-dashboard">
                            <ListItemIcon sx={{ minWidth: '40px' }}>
                                <SmsFailedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Field Notices" />
                        </ListItem>
                    )}
                    {hasPermission(currentUser, '10536f63-edd2-4d4b-9134-df155790c4e7', 'preview') && (
                        <ListItem button sx={{ pl: 3 }} component={NavLink} to="/msc/technology-breakdown-dashboard">
                            <ListItemIcon sx={{ minWidth: '40px' }}>
                                <AltRouteIcon />
                            </ListItemIcon>
                            <ListItemText primary="Technology Breakdown" />
                        </ListItem>
                    )}
                    {hasPermission(currentUser, '10536f63-edd2-4d4b-9134-df155790c4e7', 'innovation_preview') && (
                        <ListItem button sx={{ pl: 3 }} component={NavLink} to="/msc/rls-security-dashboard">
                            <ListItemIcon sx={{ minWidth: '40px' }}>
                                <AltRouteIcon />
                            </ListItemIcon>
                            <ListItemText primary="RLS Security" />
                        </ListItem>
                    )}
                </List>
            </Collapse>
        </>
    );
};

export default MscNav;
