import React from 'react';
import { useDispatch, connect } from 'react-redux';
import { Snackbar } from '@mui/material';
import { closeToastMessage } from '../app/actions/UiAction';

const Toast = ({ toastMessage }) => {
    const dispatch = useDispatch();

    const closeToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(closeToastMessage());
    };

    return <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={toastMessage !== null} message={toastMessage} autoHideDuration={3000} onClose={closeToast} />;
};

const mapStateToProps = (state) => ({
    toastMessage: state.Ui.toastMessage,
});

export default connect(mapStateToProps)(Toast);
