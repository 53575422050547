import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, CircularProgress, Typography } from '@mui/material';
import useCommonStyles from '../../../../util/commonStyles';
import { useGetEstimateQuery } from '../../../../app/DataProtectionApi';
import BudgetaryEstimate from './BudgetaryEstimate';

const DataProtectionBudgetaryEstimate = ({ match, style }) => {
    const commonClasses = useCommonStyles();
    const [estimateData, setEstimateData] = React.useState();

    const { id } = useParams();

    const { data, error } = useGetEstimateQuery({ type: 'estimation', assessment_id: id });

    React.useMemo(() => {
        setEstimateData(data);
    }, [data]);

    return (
        <Card sx={{ marginLeft: '1%', ...style }}>
            {estimateData !== undefined ? (
                <>
                    <Typography sx={{ margin: '1rem', flex: '1 1 100%', fontWeight: 'bold' }} variant="h5">
                        BaaS - Budgetary Estimate
                    </Typography>
                    <BudgetaryEstimate estimateData={estimateData} />
                </>
            ) : (
                <div style={{ display: 'flex', flexDirection: 'column' }} className={commonClasses.centerScreen}>
                    {error ? (
                        <Typography variant="h5">Unable to load BaaS Budgetary Estimate</Typography>
                    ) : (
                        <>
                            <CircularProgress />
                            <Typography>Your Budgetary Estimate is loading...</Typography>
                        </>
                    )}
                </div>
            )}
        </Card>
    );
};

export default DataProtectionBudgetaryEstimate;
