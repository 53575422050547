import React from 'react';
import { Card, CardMedia, Typography } from '@mui/material';
import dataSecurityNextSteps from '../../../../assets/dataSecurityNextSteps.png';

const DataProtectionInfo = () => {
    return (
        <div style={{ width: '100%' }}>
            <Typography sx={{ margin: '1rem', flex: '1 1 100%', fontWeight: 'bold' }} variant="h5" component="div">
                Next Steps...
            </Typography>
            <Card sx={{ margin: '.5%', boxShadow: 'none' }}>
                <CardMedia>
                    <img src={dataSecurityNextSteps} alt="NextSteps" style={{ margin: '0%', width: '100%', minWidth: '780px', maxWidth: '1240px' }} />
                </CardMedia>
            </Card>
        </div>
    );
};

export default DataProtectionInfo;
