import React from 'react';
import { Box, Card, Typography } from '@mui/material';

const ChartInfoCard = ({ title, children, bgColor }) => {
    return (
        <Card
            sx={{
                width: "100%",
                maxWidth: 380,
                height: 230,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                borderRadius: 8,
                backgroundColor: bgColor,
                padding: ".5rem 0",
                lineHeight: 2.5
            }}
            raised={true}
        >
            <Box sx={{ height: '20%', marginBotton: ".8rem",  }}>
                <Typography fontWeight={600} fontSize={26} color={'#FFFFFF'}>
                    {title}
                </Typography>
            </Box>
            <Box sx={{ height: '80%',  width: "96%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                {children}
            </Box>
        </Card>
    );
};

export default ChartInfoCard