import * as React from 'react';
import { Box, Button, Card, CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DataProtectionBudgetaryEstimate from '../../data_protection/components/DataProtectionBudgetaryEstimate';
import DataProtectionInfo from '../../data_protection/components/DataProtectionInfo';
import AVACollabInfo from '../../ava_collab/components/AVACollabInfo';
import { useUpdateAssessmentStatusMutation, useUpdateNoteMutation } from '../../../../app/DataProtectionApi';
import { Field, Form } from 'react-final-form';
import TextFieldWrapper from '../../../../components/assessments/TextFieldFinalFormWrapper';
import DRaaSBudgetaryEstimate from '../../data_protection/components/DRaaSBudgetaryEstimate';
import AVACollabBudgetaryEstimate from '../../ava_collab/components/AVACollabBudgetaryEstimate';
import IntakeScore from '../../../innovation/assessmentDetailed/IntakeScore';

import InnovationIntakeInfo from '../../../innovation/intake_assessment/components/InnovationIntakeInfo';
import { getPresalesAssessments } from '../../../../util/utilFunctions';
import AIWorkshopScore from '../../../aiWorkshop/aiAssessment/AIWorkshopScore';

const useStyles = makeStyles((theme) => ({
    detailsCard: { flexGrow: 1, minWidth: 275, margin: '1rem 2px', padding: '1%' },
    buttons: {
        margin: '2%',
    },
}));

const Details = ({ data, internalView }) => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(null);
    const [isEditing, setIsEditing] = React.useState(false);

    const [updateStatus, { isLoading: updatingStatus }] = useUpdateAssessmentStatusMutation();

    const handleStatusChange = async (status) => {
        setLoading(status);
        await updateStatus({ status, body: { assessment_id: data.id } });
        setLoading('complete');
    };

    const getEstimate = (data) => {
        switch (data?.service_name) {
            case 'Data Protection':
                return (
                    <>
                        <DataProtectionBudgetaryEstimate style={{ minWidth: '30rem', maxWidth: '40rem', flexBasis: '0', flexGrow: 1 }} />
                        <DRaaSBudgetaryEstimate style={{ minWidth: '30rem', maxWidth: '40rem', flexBasis: '0', flexGrow: 1 }} />
                    </>
                );
            case 'AVA for Collaboration Spaces':
                return <AVACollabBudgetaryEstimate style={{ minWidth: '30rem', maxWidth: '40rem', flexBasis: '0', flexGrow: 1 }} />;
            case 'Innovation Intake':
                return <IntakeScore />;
            default:
                return <></>;
        }
    };

    const getInfo = (data) => {
        switch (data?.service_name) {
            case 'Data Protection':
                return <DataProtectionInfo />;
            case 'AVA for Collaboration Spaces':
                return <AVACollabInfo />;
            case 'Innovation Intake':
                return <InnovationIntakeInfo />;
            case 'AI Envisioning Survey':
                return <AIWorkshopScore />;
            default:
                return (
                    <Box sx={{ margin: '1rem' }}>
                        <Typography variant="h5">Estimate not available</Typography>
                        <Typography sx={{ mt: 2 }}>No Estimate or Scoring Calculation has been created for this assessment type</Typography>
                    </Box>
                );
        }
    };

    const externalContent = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'stretch' }}>
                <>
                    {data.status === 'created' || data.status === 'saved' ? (
                        <>
                            {data.service_name === 'Innovation Intake' && (
                                <Card sx={{ margin: '1%', width: '98%', maxWidth: '40rem' }}>
                                    <Typography sx={{ margin: '1rem', flex: '1 1 100%', fontWeight: 'bold' }} variant="h5">
                                        Idea Score
                                    </Typography>
                                    <Typography sx={{ textAlign: 'center', padding: '0 .75rem' }} variant="h6">
                                        An Idea Score has not been generated because the assessment has not been submitted
                                    </Typography>
                                </Card>
                            )}
                            {(data.service_name === 'Data Protection' || data.service_name === 'AVA for Collaboration Spaces') && (
                                <Card sx={{ margin: '1%', width: '98%', maxWidth: '40rem' }}>
                                    <Typography sx={{ margin: '1rem', flex: '1 1 100%', fontWeight: 'bold' }} variant="h5">
                                        Budgetary Estimate
                                    </Typography>
                                    <Typography sx={{ textAlign: 'center', padding: '0 .75rem' }} variant="h6">
                                        A Budgetary Estimate has not been generated because the assessment has not been submitted
                                    </Typography>
                                </Card>
                            )}
                        </>
                    ) : (
                        <>{getEstimate(data)}</>
                    )}
                </>

                <Box sx={{ marginLeft: '1%', flex: '2 3 0' }}>
                    <Card>
                        <>{getInfo(data)}</>
                    </Card>
                </Box>
            </div>
        );
    };

    const [updateNote, { isLoading: updatingNote }] = useUpdateNoteMutation();

    const handleNote = async (values) => {
        setIsEditing(!isEditing);
        updateNote({ assessment_id: data.id, note: values.note, service_id: getPresalesAssessments().find((assessment) => assessment.name === data?.service_name).serviceId });
    };

    const internalContent = () => {
        let note = null;
        let user = null;
        let timestamp = null;
        if (data.sensitive !== null && data.sensitive.notes) {
            const notesArray = data.sensitive.notes;
            note = notesArray[notesArray.length - 1].text;
            user = notesArray[notesArray.length - 1].created_by;
            timestamp = notesArray[notesArray.length - 1].created_at;
        }
        return (
            <>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', maxWidth: '70rem' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, padding: '.5rem 1rem' }}>
                            <Typography>
                                <b>Last Edited By:</b>
                            </Typography>
                            <Typography>{user}</Typography>
                            <Typography>
                                <b>Last Edited At:</b>
                            </Typography>
                            <Typography>{timestamp}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 4 }}>
                            <Form
                                initialValues={{ note: note }}
                                onSubmit={handleNote}
                                render={({ handleSubmit, values, valid, form }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Box>
                                            <Field
                                                fullWidth
                                                disabled={!isEditing}
                                                name={`note`}
                                                component={TextFieldWrapper}
                                                variant="outlined"
                                                multiline
                                                minRows={4}
                                                placeholder="Enter any notes here"
                                            />

                                            {!isEditing && (
                                                <Button
                                                    fullWidth
                                                    onClick={() => {
                                                        setIsEditing(!isEditing);
                                                    }}>
                                                    Edit
                                                </Button>
                                            )}
                                            {isEditing && (
                                                <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '5px' }}>
                                                    <Button fullWidth type="submit" disabled={!valid || updatingNote} variant="contained" sx={{ marginRight: '5px' }}>
                                                        Submit
                                                    </Button>
                                                    <Button
                                                        fullWidth
                                                        variant="outlined"
                                                        sx={{ marginLeft: '5px' }}
                                                        onClick={() => {
                                                            form.reset();
                                                            setIsEditing(!isEditing);
                                                        }}>
                                                        Cancel
                                                    </Button>
                                                </Box>
                                            )}
                                        </Box>
                                    </form>
                                )}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ maxWidth: '20rem', flexGrow: 1, padding: '0 1rem' }}>
                        <Button
                            fullWidth
                            variant="contained"
                            disabled={!data.status.toLowerCase().includes('submitted') || updatingStatus || loading === 'complete'}
                            sx={{ margin: '0 5px 5px 5px' }}
                            onClick={() => handleStatusChange('accepted')}>
                            {loading === 'accepted' ? <CircularProgress size={20} /> : 'Approve for Next Stage'}
                        </Button>
                        <Button
                            fullWidth
                            variant="contained"
                            disabled={!data.status.toLowerCase().includes('submitted') || updatingStatus || loading === 'complete'}
                            sx={{ margin: '5px 5px' }}
                            onClick={() => handleStatusChange('rejected')}>
                            {loading === 'rejected' ? <CircularProgress size={20} /> : 'Reject'}
                        </Button>
                    </Box>
                </Box>
            </>
        );
    };

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Card className={classes.detailsCard} raised={true}>
                    {internalView ? internalContent() : externalContent()}
                </Card>
            </Box>
        </>
    );
};

export default Details;
