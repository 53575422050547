import React, { useState } from "react";
import { Paper, Box, Typography } from "@mui/material";
import HeadRowItem from "./HeadRowItem";



const HeadRow = ({
  visibleFilters,
  toggleVisibleFilters,
  columns,
  filters,
  updateFilters,
  rows,
  flexObj,
  onRequestSort,
  orderBy,
  order,
}) => {
  return (
    <Paper
      sx={{
        display: "flex",
        width: "100%",
        margin: "10px 0",
        padding: "20px 15px",
        alignItems: "center",
        boxSizing: "border-box",
      }}
    >
      {columns.map((column, idx) => {
        return (
          <HeadRowItem
            column={column}
            flex={flexObj[column.id]}
            key={idx}
            visibleFilters={visibleFilters}
            toggleVisibleFilters={() => toggleVisibleFilters(column)}
            filters={filters}
            updateFilters={updateFilters}
            rows={rows}
            onRequestSort={onRequestSort}
            orderBy={orderBy}
            order={order}
          />
        );
      })}
    </Paper>
  );
};

export default HeadRow;
