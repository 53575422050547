import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box, Button, Card, Typography } from '@mui/material';
import useCommonStyles from '../../../../util/commonStyles';
import AVACollabBudgetaryEstimate from './AVACollabBudgetaryEstimate';
import AVACollabInfo from './AVACollabInfo';

const AVACollabConclusion = ({ match }) => {
    const history = useHistory();
    const commonClasses = useCommonStyles();

    const { id } = useParams();

    return (
        <>
            <Typography className={commonClasses.pageTitle} variant="h4">
                Assessment Conclusion
            </Typography>
            <div style={{ margin: '1% 1%' }}>
                <Typography variant="h6">Thank you for completing the AVA for Collab Spaces Assessment.</Typography>
            </div>
            <Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'stretch' }}>
                    <AVACollabBudgetaryEstimate style={{ minWidth: '30rem', maxWidth: '40rem', flexBasis: '0', flexGrow: 1 }} />
                    <Box sx={{ margin: '1%', flex: '5 5 0', maxWidth: '82rem' }}>
                        <Card>
                            <AVACollabInfo />
                        </Card>
                    </Box>
                </Box>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className={commonClasses.centerScreen}>
                <Typography>This assessment has already been submitted.</Typography>
                <Button onClick={() => history.push(`/pre-sales/${id}/detailedResults`)}>View Assessment Details</Button>
            </Box>
        </>
    );
};

export default AVACollabConclusion;
