import React from 'react';
import { Box, Slide, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

interface IProps {
    message: string;
    showRibbon: boolean;
    setHidden: React.Dispatch<React.SetStateAction<boolean>>;
    color: 'green' | 'yellow' | 'red';
}
const Ribbon = ({ message, showRibbon, setHidden, color }: IProps) => {
    const bgColors = {
        green: '#67d380',
        red: 'pink',
        yellow: '#FFC23C',
    };

    return (
        <Slide direction="left" in={showRibbon}>
            <Box
                sx={{
                    width: '90%',
                    padding: '.6rem 1rem',
                    paddingRight: '3.5rem',
                    // border: '1px solid rgba(224, 224, 224, 1)',
                    cursor: 'pointer',
                    borderRadius: '6px',
                    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                    backgroundColor: bgColors[color],
                    color: '#fff',
                    position: 'relative',
                    margin: '.5rem',
                }}>
                <ClearIcon
                    sx={{ position: 'absolute', right: '.6rem', top: '50%', transform: 'translateY(-50%)', zIndex: 14, cursor: 'pointer' }}
                    onClick={() => setHidden(false)}
                />

                <Typography variant="caption" display="block" fontSize={16}>
                    {message}
                </Typography>
            </Box>
        </Slide>
    );
};

export default Ribbon;
