import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { SecureRoute } from '@okta/okta-react';
import { makeStyles } from '@mui/styles';
import InnovationIntakeForm from './InnovationIntakeForm';
import InnovationIntakeStart from './InnovationIntakeStart';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
}));

const InnovationIntake = ({ match }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Switch>
                <Redirect key="innovationCreateRedirect" from={`${match.url}`} exact to="/innovation/intake/create/start" />,
                <SecureRoute key="start" path={`${match.url}/create/start`} component={InnovationIntakeStart} />,
                <SecureRoute key="form" path={`${match.url}/create/:id/form`} component={InnovationIntakeForm} />,
            </Switch>
        </div>
    );
};

export default InnovationIntake;
