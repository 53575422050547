import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Slider } from '@mui/material';

const SliderFieldWrapper: React.FunctionComponent<FieldRenderProps<number>> = ({ input: { name, onChange, value, ..._restInput }, _meta, ...rest }) => {
    const [initVal] = React.useState(value);

    return (
        <Slider
            defaultValue={initVal}
            onChangeCommitted={(_, val) => {
                onChange(val);
            }}
            {...rest}
            aria-labelledby={name}
        />
    );
};

export default SliderFieldWrapper;
