import { Box, Button, CircularProgress, Fab, Paper, Tooltip, Typography, Switch } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import React from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import AssessmentPages from '../../../components/assessments/AssessmentPages';
import AssessmentTabs from '../../../components/assessments/AssessmentTabs';
import useCommonStyles from '../../../util/commonStyles';
import { useGetHeaderQuery, useSaveAssessmentMutation, useSubmitAssessmentMutation } from '../../../app/DataProtectionApi';
import { sendToastMessage } from '../../../app/actions/UiAction';
import AiWorkshopConclusion from './components/AiWorkshopConclusion';

const AiWorkshopForm = () => {
    //const [tab, setTab] = React.useState(0);
    const dispatch = useDispatch();
    const commonClasses = useCommonStyles();
    const [stage, setStage] = React.useState(0);
    const [activeStep, setActiveStep] = React.useState(0);
    //const [completed, setCompleted] = React.useState();
    const [questionData, setQuestionData] = React.useState();
    const [initialQuestionValues, setInitialQuestionValues] = React.useState({});
    const [tipsEnabled, setTipsEnabled] = React.useState(false);
    const [conclusion, setConclusion] = React.useState(false);

    const { id } = useParams();

    const { data: rawQuestionData, isFetching: isGettingQuestions, error: getQuestionsError } = useGetHeaderQuery(id, { refetchOnMountOrArgChange: true });

    const getAnswer = (question) => {
        if (question.answer !== null) {
            return _.cloneDeep(question.answer);
        } else if (question.type === 'checkbox' || question.type === 'section-checkbox' || question.type === 'multiple-free-text') {
            return [];
        } else if (question.type === 'slider' || question.type === 'vertical-slider') {
            return Math.min(...question.options);
        } else {
            return '';
        }
    };

    React.useMemo(() => {
        let tempValues = {};
        if (rawQuestionData !== undefined) {
            Object.entries(rawQuestionData.questions).map(([key, stageData], index) => {
                stageData.map((question) => {
                    tempValues[question.id] = {};
                    tempValues[question.id].value = {
                        score: question.score,
                        answer: getAnswer(question),
                    };
                    tempValues[question.id].note = question.comment;
                    return null;
                });
                return null;
            });
            setQuestionData(rawQuestionData);
            setInitialQuestionValues(tempValues);
        }
    }, [rawQuestionData]);

    const handleViewed = () => {
        let tempQuestionData = _.cloneDeep(questionData);
        tempQuestionData.questions[Object.keys(tempQuestionData.questions)[stage]][activeStep]['viewed'] = true;
        setQuestionData(tempQuestionData);
    };

    const handleStage = (nextStage) => {
        if (nextStage < Object.entries(questionData.questions).length) {
            setStage(nextStage);
            setActiveStep(0);
        }
    };

    const cleanResponses = (values) => {
        let tempQuestionData = _.cloneDeep(questionData);
        Object.entries(questionData.questions).map(([key, stageData], index) => {
            stageData.map((question, index) => {
                tempQuestionData.questions[key][index]['answer'] = values[question.id].value.answer;
                tempQuestionData.questions[key][index]['comment'] = values[question.id].note;
                tempQuestionData.questions[key][index]['score'] = values[question.id].value.score;
                return null;
            });
            return null;
        });
        return tempQuestionData;
    };

    const validateResponses = (data) => {
        let valid = true;
        Object.entries(data.questions).forEach(([key, stageData], stageIndex) => {
            stageData.forEach((question, stepIndex) => {
                if (question.answer === '' && !('optional' in question)) {
                    question.viewed = true;
                    setQuestionData(data);
                    if (valid) {
                        setStage(stageIndex);
                        setActiveStep(stepIndex);
                    }
                    valid = false;
                } else if (Array.isArray(question.answer) && (question.answer.length === 0 || question.answer.includes('')) && !('optional' in question)) {
                    question.viewed = true;
                    setQuestionData(data);
                    if (valid) {
                        setStage(stageIndex);
                        setActiveStep(stepIndex);
                    }
                    valid = false;
                }
            });
        });
        return valid;
    };

    const [saveAssessment, { isLoading: saving }] = useSaveAssessmentMutation();

    const saveProgress = (values) => {
        const cleanedData = cleanResponses(values);
        saveAssessment(cleanedData)
            .unwrap()
            .then((res) => {
                dispatch(sendToastMessage('Your assessment has been saved.'));
            })
            .catch((error) => dispatch(sendToastMessage('Failed to save assessment.')));
    };

    const [submitAssessment, { isLoading: submitting }] = useSubmitAssessmentMutation();

    const onSubmit = async (values) => {
        const cleanedData = cleanResponses(values);
        const validated = validateResponses(cleanedData);
        if (validated) {
            submitAssessment(cleanedData)
                .unwrap()
                .then((res) => {
                    dispatch(sendToastMessage('Your assessment has been submitted.'));
                    setConclusion(true);
                })
                .catch((error) => dispatch(sendToastMessage('Failed to submit assessment.')));
        }
    };

    // const validation = (values) => {
    //     const errors = {};
    //     Object.entries(values).map(([id, val], index) => {
    //         if (val.value.answer === '') {
    //             errors[id] = 'Required';
    //         } else if (Array.isArray(val.value.answer) && val.value.answer.length === 0) {
    //             errors[id] = 'Required';
    //         }
    //     });
    //     return errors;
    // };

    return (
        <>
            {questionData !== undefined && !isGettingQuestions ? (
                <>
                    {(questionData.status !== 'created' && questionData.status !== 'saved') || conclusion ? (
                        <AiWorkshopConclusion />
                    ) : (
                        <Form
                            initialValues={initialQuestionValues}
                            onSubmit={onSubmit}
                            //validate={validation}
                            mutators={{
                                ...arrayMutators,
                            }}
                            render={({ handleSubmit, valid, values }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography className={commonClasses.pageTitle} variant="h4">
                                            AI Envisioning Workshop Survey
                                        </Typography>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', alignItems: 'center' }}>
                                            <Switch onClick={() => setTipsEnabled(!tipsEnabled)}></Switch>
                                            <Typography sx={{ marginRight: '2rem' }}>Tips</Typography>
                                            <Tooltip title="Save Progress" aria-label="save">
                                                <span>
                                                    <Fab size="small" color="primary" onClick={() => saveProgress(values)} disabled={null}>
                                                        {saving ? <CircularProgress size={20} style={{ color: 'white' }} /> : <SaveIcon />}
                                                    </Fab>
                                                </span>
                                            </Tooltip>
                                            <Tooltip title="Submit Questions" aria-label="submit">
                                                <span>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="secondary"
                                                        aria-label="submit"
                                                        style={{
                                                            margin: '.5rem 1rem',
                                                            color: 'white',
                                                        }}
                                                        disabled={submitting || !valid}>
                                                        {submitting ? <CircularProgress size={20} style={{ color: 'white' }} /> : 'Submit'}
                                                    </Button>
                                                </span>
                                            </Tooltip>
                                        </Box>
                                    </Box>

                                    <Paper>
                                        <Box>
                                            <AssessmentTabs stage={stage} setStage={setStage} setActiveStep={setActiveStep} questionData={questionData} values={values} />
                                            <AssessmentPages
                                                stage={stage}
                                                handleStage={handleStage}
                                                activeStep={activeStep}
                                                setActiveStep={setActiveStep}
                                                handleViewed={handleViewed}
                                                values={values}
                                                questionData={questionData}
                                                tipsEnabled={tipsEnabled}
                                            />
                                        </Box>
                                    </Paper>
                                </form>
                            )}
                        />
                    )}
                </>
            ) : (
                <div className={commonClasses.centerScreen}>
                    {getQuestionsError ? <Typography className={commonClasses.error}>Failed to get assessment questions. Please try again.</Typography> : <CircularProgress />}
                </div>
            )}
        </>
    );
};

export default AiWorkshopForm;
