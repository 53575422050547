import React from 'react';
import { Step, StepButton, StepContent, Box, Button, Typography, StepLabel } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Field } from 'react-final-form';
import useFormStyles from '../../util/formStyles';
import TextFieldWrapper from './TextFieldFinalFormWrapper';
import { makeStyles, createStyles } from '@mui/styles';
import RenderQuestion from './RenderQuestion';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
        button: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
        slider: {
            padding: theme.spacing(5, 6, 0, 4),
        },
        dropdown: {
            padding: theme.spacing(2, 6, 0, 1),
            width: '15%',
        },
        entryPadding: {
            padding: theme.spacing(2, 0, 0, 0),
        },
        leftRightPadding: {
            padding: theme.spacing(0, 1, 0, 1),
        },
    }),
);

const maturityModelSliderMarks = [
    {
        value: 1,
        label: '1 - None',
    },
    {
        value: 2,
        label: '2 - Researching',
    },
    {
        value: 3,
        label: '3 - Planned',
    },
    {
        value: 4,
        label: '4 - Partially',
    },
    {
        value: 5,
        label: '5 - Absolutely',
    },
];

export const sliderDefaults = {
    step: 1,
    marks: maturityModelSliderMarks,
    min: 1,
    max: 5,
    color: 'secondary',
    valueLabelDisplay: 'on',
};

const identity = (value) => value;

const AssessmentStep = (props) => {
    const { formFieldName, index, questions, values, tipsEnabled, questionData, stage, activeStep, handleNext, handleBack, handleStep, handleViewed, isRendered, ...rest } = props;
    const classes = useStyles();
    const formClasses = useFormStyles();

    // opening the step will be considered as viewing it
    React.useEffect(() => {
        return () => {
            if (activeStep === index && !questions[index].viewed) {
                handleViewed();
            }
        };
    }, [activeStep, index, handleViewed, questions]);

    const unanswered = (question) => {
        const answer = values[question.id].value.answer;
        if (answer === '' || answer === undefined) {
            return true;
        } else if (Array.isArray(answer) && (answer.length === 0 || answer.includes(''))) {
            return true;
        }
        return false;
    };

    const isLastRenderedQuestion = () => {
        const remainingQuestions = questions.slice(index + 1);
        if (remainingQuestions.length === 0) {
            return true;
        }
        return remainingQuestions.every((question) => {
            return isRendered(question) ? false : true;
        });
    };

    const renderButton = () => {
        if (isLastRenderedQuestion() && !(stage + 1 < Object.entries(questionData.questions).length)) {
            return (
                <Button variant="contained" color="primary" type="submit" onClick={() => handleNext(activeStep)} className={classes.button}>
                    Submit Assessment
                </Button>
            );
        } else if (activeStep !== questions.length - 1) {
            return (
                <Button variant="contained" color="primary" type="button" onClick={() => handleNext(activeStep)} className={classes.button}>
                    Next
                </Button>
            );
        } else if (stage + 1 < Object.entries(questionData.questions).length) {
            return (
                <Button variant="contained" color="primary" type="button" onClick={() => handleNext(activeStep)} className={classes.button}>
                    Next Section
                </Button>
            );
        }
    };

    return (
        <Step {...{ ...rest, index }} completed={false}>
            <StepButton onClick={handleStep(index)}>
                <StepLabel error={questions[index].viewed && unanswered(questions[index]) && activeStep !== index}>
                    <Typography variant="h6">{questions[index].text}</Typography>
                </StepLabel>
            </StepButton>
            <StepContent>
                {tipsEnabled && questions[index].hint && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <InfoIcon />
                        <Typography variant="caption" sx={{ margin: '.25rem 0 0 1rem' }}>
                            {questions[index].hint}
                        </Typography>
                    </Box>
                )}
                <RenderQuestion question={questions[index]} tipsEnabled={tipsEnabled} isRequired={!('optional' in questions[index])} />
                <div className={classes.entryPadding}>
                    <Box p={1}>
                        <Field
                            parse={identity}
                            style={{ maxWidth: '100%', width: '100%' }}
                            className={formClasses.formField}
                            name={`${questions[index].id}.note`}
                            component={TextFieldWrapper}
                            variant="outlined"
                            multiline
                            maxRows={4}
                            placeholder="Additional Notes for Question"
                        />
                    </Box>
                </div>
                <div className={classes.actionsContainer}>
                    <div>
                        <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                            Back
                        </Button>
                        {renderButton()}
                    </div>
                </div>
            </StepContent>
        </Step>
    );
};

export default AssessmentStep;
