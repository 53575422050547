import { Security } from '@okta/okta-react';
import React, { Suspense } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import { toRelativeUrl } from '@okta/okta-auth-js';
import oktaAuth from './modules/auth/OktaAuth';
import { connect } from 'react-redux';
import { Box, Button, CircularProgress, ThemeProvider, Typography } from '@mui/material';
import AuthRoutes from './modules/auth/Routes';
import UploadRoutes from './modules/upload/Routes';
import Base from './modules/base/Base';
import HomeRoutes from './modules/home/Routes';
import useCommonStyles from './util/commonStyles';
import AppTheme from './util/appTheme';
import './App.css';
import PresalesRoutes from './modules/presales/Routes';
import SPOARoutes from './modules/spoa/Routes';
import CXRoutes from './modules/cx/Routes';
import AdminRoutes from './modules/admin/Routes';
import MscRoutes from './modules/msc/Routes';
import InnovationRoutes from './modules/innovation/Routes';
import AiRoutes from './modules/aiWorkshop/Routes';
import AssessmentEditorRoutes from './modules/assessmentEditor/Routes';

const App = ({ currentUser }) => {
    const commonClasses = useCommonStyles();
    const history = useHistory();
    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        history.replace(toRelativeUrl(originalUri, window.location.origin));
    };

    return (
        <>
            <ThemeProvider theme={AppTheme}>
                <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
                    <Base>
                        <Suspense
                            fallback={
                                <div className={commonClasses.centerScreen}>
                                    <CircularProgress />
                                </div>
                            }>
                            <Switch>
                                {[
                                    ...AuthRoutes(),
                                    ...UploadRoutes(),
                                    ...HomeRoutes(),
                                    ...PresalesRoutes(currentUser),
                                    ...CXRoutes(currentUser),
                                    ...SPOARoutes(currentUser),
                                    ...MscRoutes(currentUser),
                                    ...InnovationRoutes(currentUser),
                                    ...AiRoutes(currentUser),
                                    ...AssessmentEditorRoutes(currentUser),
                                    ...AdminRoutes(currentUser),
                                ]}
                                <div className={commonClasses.centerScreen}>
                                    {currentUser === null ? (
                                        <CircularProgress sx={{ alignItems: 'center', justifyContent: 'center' }} style={{ margin: '3rem auto' }} />
                                    ) : (
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <Typography sx={{ fontFamily: "'Segoe UI', Verdana, Helvetica, sans-serif", fontSize: '4rem', fontWeight: '300', color: '#6E6E6E' }}>
                                                404
                                            </Typography>
                                            <Typography sx={{ margin: '.5rem 0 1rem 0', color: '#2C2C2C' }}>The page your looking for doesn't seem to exist.</Typography>
                                            <Button variant="contained" onClick={() => history.push('/')}>
                                                Back to Home
                                            </Button>
                                        </Box>
                                    )}
                                </div>
                            </Switch>
                        </Suspense>
                    </Base>
                </Security>
            </ThemeProvider>
        </>
    );
};

const mapStateToProps = (state) => {
    return { currentUser: state.User.user };
};

export default connect(mapStateToProps)(App);
//<Switch>{[...EstimateRoutes(), ...UploadRoutes(), ...HomeRoutes(), ...AuthRoutes()]}</Switch>
