import { Box, Typography } from '@mui/material';
import React from 'react';

interface IProps {
    user: string;
    commented_at: string;
    comment: string;
}
const Comment = ({ user, commented_at, comment }: IProps) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', m: 1, p: 1, borderRadius: '1rem', backgroundColor: '#eff3f8' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                <Typography variant="caption">{user}</Typography>
                <Typography variant="caption">{commented_at}</Typography>
            </Box>

            <Typography variant="body2" sx={{ width: '100%', whiteSpace: 'pre-wrap', p: 1 }}>
                {comment}
            </Typography>
        </Box>
    );
};

export default Comment;
