import React from 'react';
import { Box, Button, CircularProgress, InputLabel, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Select from 'react-select';
import { Form, Field } from 'react-final-form';
import TextFieldWrapper from '../../../components/assessments/TextFieldFinalFormWrapper';
import { composeValidators, required } from '../../../util/finalFormValidators';
import { closeDrawer } from '../../../app/actions/UiAction';
import { useDispatch } from 'react-redux';
import UserIcon from '../../../assets/UserIcon.png';
import { useEditUserGroupMutation, useGetGroupsQuery } from '../../../app/DataProtectionApi';

const useStyles = makeStyles((theme) => ({
    input: {
        margin: '2% 5%',
        minWidth: '6rem',
    },
}));

const User = ({ user }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [userData] = React.useState(user);

    const { data: groupsData } = useGetGroupsQuery();

    const updateUser = (data) => {
        console.log('clicked submit');
        console.log(data);
        closeUserDrawer();
    };

    const closeUserDrawer = () => {
        dispatch(closeDrawer());
    };

    const [editUserGroup, { isLoading: editingUserGroup }] = useEditUserGroupMutation();

    const handleGroupChange = (groups, { action, removedValue, option }) => {
        console.log(removedValue, option);

        if (removedValue || option) {
            let action = null;
            let groupId = null;
            if (removedValue) {
                // remove item
                action = 'remove';
                groupId = removedValue.value;
            } else {
                action = 'add';
                groupId = option.value;
            }
            const body = {
                action: action,
                user_id: userData.id,
                group_id: groupId,
            };
            editUserGroup(body);
        } else {
            console.log(action);
        }
    };

    return (
        <Form
            initialValues={{ name: userData.name }}
            onSubmit={updateUser}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="h5" sx={{ textAlign: 'center' }}>
                            Edit User
                        </Typography>
                        <div style={{ padding: '2rem' }}>
                            <img src={UserIcon} alt="User" style={{ maxWidth: '15rem' }} />
                        </div>
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <Box fullWidth className={classes.input} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'baseline' }}>
                                <InputLabel className={classes.input} sx={{ flexGrow: 1 }}>
                                    Name
                                </InputLabel>
                                <Field
                                    style={{ flexGrow: 1 }}
                                    name="name"
                                    component={TextFieldWrapper}
                                    variant="outlined"
                                    placeholder="Name"
                                    validate={composeValidators(required)}
                                    disabled
                                />
                            </Box>
                            <Box fullWidth className={classes.input} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'baseline' }}>
                                <InputLabel className={classes.input} sx={{ flexGrow: 1 }}>
                                    Groups
                                </InputLabel>
                                <Field
                                    name="groups"
                                    render={() => (
                                        <div style={{ flexGrow: 1 }}>
                                            <Select
                                                isMulti
                                                style={{ width: '100%' }}
                                                name="groups"
                                                value={Object.entries(userData.group_details).map(([groupId, group]) => ({ value: group.id, label: group.name }))}
                                                options={groupsData ? groupsData.map((group) => ({ value: group.id, label: group.name })) : []}
                                                onChange={handleGroupChange}
                                                isLoading={editingUserGroup}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                            />
                                        </div>
                                    )}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            aria-label="submit"
                            style={{
                                margin: '.5rem 1rem',
                                color: 'white',
                            }}
                            //disabled={submitting}
                            disabled>
                            {submitting ? <CircularProgress size={20} style={{ color: 'white' }} /> : 'Update'}
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            style={{
                                margin: '.5rem 1rem',
                            }}
                            disabled={submitting}
                            onClick={closeUserDrawer}>
                            Cancel
                        </Button>
                    </Box>
                </form>
            )}
        />
    );
};

export default User;
