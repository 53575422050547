import React, { useState } from "react";

import Container from "./Container";
import RowItem from "./RowItem";
import HeadRow from "./HeadRow";

import { Pagination, Box, Typography, Paper } from "@mui/material";



const Index = ({ rows, title, flexObj }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(4);
  const [visibleFilters, setVisibleFilters] = useState([]);
  const [ rowData, setRowData ] = useState(rows)
  const [filters, setFilters] = useState(
    columns.reduce((previousValue, currentValue) => {
      if (currentValue.filterable) {
        previousValue[currentValue.id] = "";
      }
      return previousValue;
    }, {})
  );
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("last_edited");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };


  const handlePageChange = (event, page) => {
    setPage(() => page);
  };

  const getCurrentView = (data) => {
    const endVal = page * perPage;
    const startVal = endVal - perPage;
    const currentItems = data.slice(startVal, endVal);

    return currentItems;
  };

  const toggleVisibleFilters = (column) => {
    if (visibleFilters.includes(column.id)) {
      updateFilters(column, "");
      setVisibleFilters(
        visibleFilters.filter((filter) => filter !== column.id)
      );
    } else {
      // updateFilters(column, '');
      setVisibleFilters([...visibleFilters, column.id]);
    }
  };

  const updateFilters = React.useCallback(
    (column, value) => {
      let newFilters = { ...filters };
      newFilters[column.id] = value;
      setFilters(newFilters);
      setPage(1);
    },
    [filters]
  );

  const filterResults = React.useCallback(() => {
    const newRows = [];
    rows.forEach((row, index) => {
      // if all of the filters don't match, exclude it from the table
      const exclude = Object.entries(filters).find(
        ([filterName, filterValue]) =>
          !row[filterName]
            .toString()
            .toLowerCase()
            .includes(String(filterValue).toLowerCase())
      );
      if (!exclude) {
        newRows.push(row);
      }
    });
    setRowData(newRows);
  }, [filters, rows]);

  React.useEffect(() => {
    filterResults();
  }, [filters, filterResults]);

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

const getComparator = (order, orderBy) => {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
};

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};


  return (
    <>
      <Typography variant="h5" fontWeight={600} paddingBottom={1.5}>{title.toUpperCase()}</Typography>
      <Container minWidth="400px">
        <HeadRow
          columns={columns}
          visibleFilters={visibleFilters}
          toggleVisibleFilters={(column) => toggleVisibleFilters(column)}
          filters={filters}
          updateFilters={updateFilters}
          rows={rowData}
          flexObj={flexObj}
          onRequestSort={handleRequestSort}
          orderBy={orderBy}
          order={order}
        />
        {getCurrentView(stableSort(rowData, getComparator(order, orderBy))).map((row, idx) => {
          return (
            <RowItem columns={columns} row={row} flexObj={flexObj} key={idx} />
          );
        })}
      </Container>

     <Box sx={{display: 'flex', justifyContent: 'flex-end', padding: "12px 10px", boxSizing: "border-box",}}>
      <Pagination
        page={page}
        count={Math.ceil(rowData.length / perPage)}
        variant="outlined"
        color="primary"
        onChange={handlePageChange}
      />
     </Box>
    </>
  );
};

export default Index;

const columns = [
  {
    id: "cr_party_name",
    numeric: false,
    label: "Deal Name",
    filterable: "search",
  },
  {
    id: "incentive_eligibility_status",
    numeric: false,
    label: "Status",
    filterable: "dropdown",
  },
];


