import React from "react";
import { Paper, Box, Typography } from "@mui/material";

const RowItem = ({ columns, row, flexObj }) => {
  return (
    <Paper
      sx={{
        display: "flex",
        width: "100%",
        margin: "10px 0",
        padding: "22.5px 10px",
        alignItems: "center",
        boxSizing: "border-box",
      }}
    >
      {columns.map((column, idx) => {
        return (
          <Box key={idx} sx={{ flex: flexObj[column.id] }}>
            <Typography sx={{ fontSize: '0.875rem'}}>{row[column.id]}</Typography>
          </Box>
        );
      })}
    </Paper>
  );
};

export default RowItem;
