import { Box, Button, TextField, Typography } from '@mui/material';
import React from 'react';
import oktaAuth from '../../../../auth/OktaAuth';

interface Comment {
    user: string;
    commented_at: string;
    comment: string;
}
interface IProps {
    isCommenting: boolean;
    setIsCommenting: React.Dispatch<React.SetStateAction<boolean>>;
    comments: Comment[];
    setComments: React.Dispatch<React.SetStateAction<Comment[]>>;
}
const AddComment = ({ isCommenting, setIsCommenting, comments, setComments }: IProps) => {
    const [comment, setComment] = React.useState<string>();

    const handleAddComment = () => {
        if (comment) {
            oktaAuth.getUser().then((user) => {
                if (user.name) {
                    const commentObj = { user: user.name, commented_at: new Date().toLocaleString('en-US'), comment };
                    setComments([...comments, commentObj]);
                }
            });
        }
        setIsCommenting(!isCommenting);
    };
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <TextField
                fullWidth
                multiline
                minRows={5}
                maxRows={10}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setComment(event.target.value);
                }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'row', m: 2 }}>
                <Button variant="outlined" sx={{ mr: 1 }} onClick={() => setIsCommenting(!isCommenting)}>
                    Discard
                </Button>
                <Button variant="contained" sx={{ ml: 1 }} disabled={!comment} onClick={handleAddComment}>
                    Add Comment
                </Button>
            </Box>
        </Box>
    );
};

export default AddComment;
