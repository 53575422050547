import React from 'react';
import { Typography, Tab, Tabs } from '@mui/material';
import useCommonStyles from '../../util/commonStyles';
import TabPanel from '../../components/TabPanel';
import GroupsManagement from './components/GroupsManagement';
import UserManagement from './components/UserManagement';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
}));

const Admin = () => {
    const commonClasses = useCommonStyles();
    const classes = useStyles();
    const [stage, setStage] = React.useState(0);

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    const selectStage = (event, value) => {
        setStage(value);
    };

    return (
        <div className={classes.root}>
            <Typography className={commonClasses.pageTitle} variant="h4">
                Admin Panel
            </Typography>
            <Tabs value={stage} onChange={selectStage} variant="fullWidth">
                <Tab key={`admin-tab-groups`} label={'Groups'} {...a11yProps(stage)} />
                <Tab key={`admin-tab-users`} label={'Users'} {...a11yProps(stage)} />
            </Tabs>
            <TabPanel value={stage} index={0}>
                <GroupsManagement />
            </TabPanel>
            <TabPanel value={stage} index={1}>
                <UserManagement />
            </TabPanel>
        </div>
    );
};

export default Admin;
