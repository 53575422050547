import { Box, Typography } from '@mui/material';
import React from 'react';
import BOStepper from './components/BOStepper';
import CSPStepper from './components/CSPStepper';
import Ribbon from './components/Ribbon';
import UseStepper from './components/UseStepper';

const LifecycleDetailed = () => {
    const [disclaimerRibbon, setDisclaimerRibbon] = React.useState(true);
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#eff3f8' }}>
            <Ribbon
                message="This view exists only as a demo for a potential UI and is not a fully functioning application"
                showRibbon={disclaimerRibbon}
                setHidden={setDisclaimerRibbon}
                color="yellow"
            />
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', p: 1 }}>
                <Typography variant="h4">Geico</Typography>
                <Typography>Sales Order Number: 4722532-1</Typography>
                <Typography>Cisco Deal ID: 52914736</Typography>
            </Box>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', pt: 2 }}>
                <Typography variant="h4">USE Lifecycle</Typography>
                <UseStepper />
            </Box>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', pt: 2 }}>
                <Typography variant="h4">ADOPT Lifecycle</Typography>
                <CSPStepper />
                <BOStepper />
            </Box>
        </Box>
    );
};

export default LifecycleDetailed;
