import React from 'react';
import { Tab, Tabs } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const AssessmentTabs = ({ stage, setStage, setActiveStep, questionData, values }) => {
    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    const selectStage = (event, value) => {
        setStage(value);
        setActiveStep(-1);
    };

    React.useEffect(() => {
        //pass
    }, [questionData]);

    const unanswered = (question) => {
        const answer = values[question.id].value.answer;
        if (answer === '' || answer === undefined) {
            return true;
        } else if (Array.isArray(answer) && answer.length === 0) {
            return true;
        }
        return false;
    };

    return (
        <Tabs value={stage} onChange={selectStage} variant="fullWidth">
            {Object.entries(questionData.questions).map(([key, value], index) => {
                return (
                    <Tab
                        sx={{ borderColor: 'red' }}
                        key={`dp-tab-${index}`}
                        textColor={'error'}
                        label={
                            value.find((question) => {
                                return question.viewed && unanswered(question) && !('optional' in question) && stage !== index;
                            }) ? (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <ErrorOutlineIcon sx={{ paddingBottom: '.2rem', marginRight: '.5rem', color: '#FF0000' }} />
                                    {key}
                                </div>
                            ) : (
                                key
                            )
                        }
                        {...a11yProps(stage)}
                    />
                );
            })}
        </Tabs>
    );
};

export default AssessmentTabs;
