  export const columns = [
    {
        id: 'cr_party_name',
        numeric: false,
        label: 'Customer',
        filterable: 'search',
    },
    {
        id: 'stage',
        numeric: false,
        label: 'Stage',
        filterable: 'dropdown',
    },
    {
        id: 'partner_region',
        numeric: false,
        label: 'Region',
        filterable: 'dropdown',
    },
    {
        id: 'track',
        numeric: false,
        label: 'Track',
        filterable: 'dropdown',
    },
    {
        id: 'sub-track',
        numeric: false,
        label: 'Sub-Track',
        filterable: 'dropdown',
    },
];

export const chartColorsThemeArr = [
    "#12498a",
    "#00d4a6",
    "#fbbf1c",
    "#368cf1",
    "#787c81",
    "#80608e",
    "#09a0b2",
    "#60c704",
    "#fa1a1a",
]