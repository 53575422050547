const initialState = {
    toastMessage: null,
    rightDrawerContent: { rightDrawerContent: null },
    favorites: [],
};

export default function UiReducer(state = initialState, action) {
    switch (action.type) {
        case 'TOAST_MESSAGE':
            return {
                ...state,
                toastMessage: action.payload,
            };
        case 'OPEN_DRAWER':
            return {
                ...state,
                rightDrawerContent: action.payload,
            };
        default:
            return state;
    }
}
