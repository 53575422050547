import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import oktaAuth from '../../auth/OktaAuth';

export const sitesApi = createApi({
    reducerPath: 'sitesApi',
    tagTypes: ['PracticeSites'],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_SPOA_URL || `https://innovation-portal-data-security.azure-api.net/spoa-practice/`,
        prepareHeaders: (headers) => {
            // , { getState }
            // const token = (getState() as RootState).auth.token;
            const idToken = oktaAuth.getIdToken();
            // If we have a token set in state, let's assume that we should be passing it.
            if (idToken) {
                headers.set('authorization', `Bearer ${idToken}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getSites: builder.query({
            query: () => 'practicesites',
            providesTags: ['PracticeSites'],
        }),
        searchSites: builder.query({
            query: () => 'sites',
        }),
        deleteSite: builder.mutation({
            query: ({ id }) => ({
                url: `/practicesites?practice_site_id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['PracticeSites'],
        }),
        addSite: builder.mutation({
            query: ({ body }) => ({
                url: 'practicesites',
                method: 'POST',
                body: { ...body },
            }),
            invalidatesTags: ['PracticeSites'],
        }),
    }),
});

export const { useGetSitesQuery, useSearchSitesQuery, useDeleteSiteMutation, useAddSiteMutation } = sitesApi;
