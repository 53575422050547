import React, { useState } from 'react';

import { Box, Typography, Button, Tooltip, Zoom, TableSortLabel } from '@mui/material';
import { FilterList } from '@mui/icons-material';
import RenderFilter from './RenderFilter';
import { visuallyHidden } from '@mui/utils';

const HeadRowItem = ({ flex, column, visibleFilters, toggleVisibleFilters, filters, updateFilters, rows, onRequestSort, orderBy, order }) => {
    const [isHovering, setIsHovering] = useState(false);

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <Box sx={{ flex: flex }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <TableSortLabel
                        active={orderBy === column.id}
                        sx={{ display: 'flex', flexDirection: 'row' }}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={createSortHandler(column.id)}>
                        <Typography variant="h6">{column.label}</Typography>
                        {orderBy === column.id ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                    {visibleFilters.includes(column.id) ? (
                        <Button size="small" onClick={() => toggleVisibleFilters(column)}>
                            Clear Filter
                        </Button>
                    ) : (
                        <Tooltip title="Apply Filter" TransitionComponent={Zoom} arrow>
                            <Button size="small" onClick={() => toggleVisibleFilters(column)}>
                                <FilterList />
                            </Button>
                        </Tooltip>
                    )}
                </Box>
            </Box>
            <Box>{column.filterable && <RenderFilter column={column} updateFilters={updateFilters} visibleFilters={visibleFilters} filters={filters} rows={rows} />}</Box>
        </Box>
    );
};

export default HeadRowItem;
