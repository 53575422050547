import React, { FC, useState } from 'react';

import { InputAdornment, Box, FormControl, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import useCommonStyles from '../../../../util/commonStyles';
import ChartsContainer from './components/ChartsContainer';
import { columns } from './constants';
import EnhancedTable from '../../../../components/enhancedTable/EnhancedTable';
import rowsData from './json/rowsData.json';
import ExtraTablesContainer from './components/ExtraTablesContainer';
import { dateExistsInWeek, summarizeNum } from './utils';
import Ribbon from '../detailed/components/Ribbon';

const Overview = () => {
    const commonClasses = useCommonStyles();
    const [resetButton, setResetButton] = useState(false);
    const [resetAction, setResetAction] = useState(false);
    const [showRibbon, setShowRibbon] = useState(true);

    const addIdFieldToRow = (rows) => {
        return rows.map((row, idx) => {
            return { ...row, id: `${idx}-${row.deal_id}` };
        });
    };

    const getExpiryChartData = (jsonData) => {
        const weeks = ['firstWeek', 'secondWeek', 'thirdWeek', 'fourthWeek'];

        const weeksCountArr = weeks.map((week) => {
            const dataWithinWeek = jsonData.filter((item) => {
                return dateExistsInWeek({
                    date: item.deal_incentive_expiry_date,
                    week: week,
                });
            });

            return dataWithinWeek.length;
        });

        return {
            labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
            data: weeksCountArr,
        };
    };

    const getIncentiveAvailableData = (data) => {
        const total = removeDollarSignFromIncentiveAmount(data).reduce((total, obj) => {
            return total + obj.max_estimated_incentives_amount;
        }, 0);

        return summarizeNum(total);
    };

    const removeDollarSignFromIncentiveAmount = (rows) => {
        if (rows.length) {
            return rows.map((row) => {
                if (String(row.max_estimated_incentives_amount).includes('$')) {
                    const numIncentiveAmount = Number(row.max_estimated_incentives_amount.slice(1));
                    return { ...row, max_estimated_incentives_amount: numIncentiveAmount };
                }
                return row;
            });
        }
        return rows;
    };

    const addDollarSignToIncentiveAmount = (rows) => {
        return rows.map((row) => {
            return { ...row, max_estimated_incentives_amount: `$${row.max_estimated_incentives_amount}` };
        });
    };

    return (
        <div>
            <Ribbon
                message="This view exists only as a demo for a potential UI and is not fully functioning application"
                color="yellow"
                showRibbon={showRibbon}
                setHidden={setShowRibbon}
            />

            <Box sx={{ padding: '0 15px' }}>
                <EnhancedTable
                    columns={columns}
                    rows={rowsData}
                    setResetButton={setResetButton}
                    resetAction={resetAction}
                    setResetAction={setResetAction}
                    bgColor="#FFFFFF"
                    // collapsibleRow={(row) => <Metadata data={row} additionalButtons={additionalButton} />}
                    chartComponent={(rowsInfo, columns, updateFilters, clearFilters, setRowData, filters) => (
                        <>
                            <ChartsContainer
                                columns={columns}
                                updateFilters={updateFilters}
                                clearFilters={clearFilters}
                                expirtyChartData={() =>   getExpiryChartData(rowsInfo)}
                                dealsInProgressCardData={() => 5}
                                incentiveAvailableCardData={() => getIncentiveAvailableData(rowsInfo)}
                                setRowData={setRowData}
                                rowsInfo={rowsInfo}
                                rowsData={rowsData}
                                filters={filters}
                            />

                            <ExtraTablesContainer />
                        </>
                    )}
                    extraFilters={(rowsInfo, setRowData) => {
                        return (
                            <Box sx={{ padding: '30px 0' }}>
                                <FormControl variant="standard" sx={{ backgroundColor: '#FFFFFF', borderRadius: '4px' }}>
                                    <TextField
                                        onChange={(e) => {
                                            const filteredRows = rowsData.filter((row) => row.cr_party_name.toLowerCase().includes(e.target.value.toLowerCase()));
                                            setRowData(filteredRows);
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        placeholder="Search"
                                        size="small"
                                    />
                                </FormControl>
                            </Box>
                        );
                    }}
                />
            </Box>
        </div>
    );
};

export default Overview;
