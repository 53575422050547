import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import { InputLabel, TextField } from '@mui/material';
interface IProps {
    multiline?: boolean;
    minLines?: number;
    maxLines?: number;
    name: string;
    value: string;
    handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fieldName: string) => void;
}
const CustomizedInputBase = ({ name, value, handleChange, multiline = false, minLines = 1, maxLines = 1 }: IProps) => {
    return (
        <TextField
            fullWidth
            label={name}
            variant="outlined"
            value={value}
            onChange={(event) => handleChange(event, name)}
            multiline={multiline}
            minRows={minLines}
            maxRows={maxLines}
            sx={{
                backgroundColor: '#eff3f8',
                '& label': { paddingLeft: (theme) => theme.spacing(2) },
                '& input': { paddingLeft: (theme) => theme.spacing(3.5), borderRadius: '.75rem' },
                '& fieldset': {
                    paddingLeft: (theme) => theme.spacing(2.5),

                    borderWidth: 0,
                },
            }}
        />
    );
};

export default CustomizedInputBase;
