import React from 'react';
import { SecureRoute } from '@okta/okta-react';
import DataProtection from './data_protection/DataProtection';
import AvaPresales from './ava_collab/AvaPresales';
import AssessmentDetailed from './assessmentDetailed/AssessmentDetailed';
import AdminConfig from './adminConfig/AdminConfig';
import PresalesOverview from './assessmentOverview/PresalesOverview';
import MyOverview from './assessmentOverview/MyOverview';
import { hasPermission, hasPermissionMultiple } from '../../util/permissions';
import CheckingPermissions from '../base/CheckingPermissions';
import InnovationIntake from '../innovation/intake_assessment/InnovationIntake';

const PresalesRoutes = (currentUser) => [
    <SecureRoute key={'pre-sales-overview'} path="/pre-sales/overview" component={PresalesOverview} />,
    <SecureRoute key={'pre-sales-overview'} path="/pre-sales/myAssessments" component={MyOverview} />,
    <SecureRoute key="detailedResults" path="/pre-sales/:id/detailedResults" component={AssessmentDetailed} />,
    hasPermission(currentUser, 'f1f933a5-a61e-4be4-bfd3-f08d0ec7f1c6', 'create_assessment') ? (
        <SecureRoute key={'pre-sales-data-protection'} path="/pre-sales/data-protection" component={DataProtection} />
    ) : (
        <SecureRoute
            key={'pre-sales-data-protection'}
            path="/pre-sales/data-protection"
            render={() => <CheckingPermissions permission={hasPermission(currentUser, 'f1f933a5-a61e-4be4-bfd3-f08d0ec7f1c6', 'create_assessment')} />}
        />
    ),
    hasPermission(currentUser, 'c166003b-ec15-4410-a26b-90e15a1594bc', 'create_assessment') ? (
        <SecureRoute key={'innovation-intake'} path="/innovation/intake" component={InnovationIntake} />
    ) : (
        <SecureRoute
            key={'innovation-intake'}
            path="/innovation/intake"
            render={() => <CheckingPermissions permission={hasPermission(currentUser, 'c166003b-ec15-4410-a26b-90e15a1594bc', 'create_assessment')} />}
        />
    ),
    hasPermission(currentUser, 'e11f6cd7-7989-484e-9c09-792240af01b2', 'create_assessment') ? (
        <SecureRoute key={'pre-sales-ava-collab'} path="/pre-sales/ava-collab" component={AvaPresales} />
    ) : (
        <SecureRoute
            key={'pre-sales-ava-collab'}
            path="/pre-sales/ava-collab"
            render={() => <CheckingPermissions permission={hasPermission(currentUser, 'e11f6cd7-7989-484e-9c09-792240af01b2', 'create_assessment')} />}
        />
    ),
    hasPermissionMultiple(currentUser, {
        'f1f933a5-a61e-4be4-bfd3-f08d0ec7f1c6': 'data_security_config',
        'e11f6cd7-7989-484e-9c09-792240af01b2': 'ava_collaboration_spaces_config',
        'c166003b-ec15-4410-a26b-90e15a1594bc': 'innovation_intake_config',
        '750dd17a-688a-4af4-b2eb-88493cf44e6d': 'ai_workshop_config',
    }) ? (
        <SecureRoute key={'pre-sales-admin-config'} path="/pre-sales/adminConfig" component={AdminConfig} />
    ) : (
        <SecureRoute
            key={'pre-sales-admin-config'}
            path="/pre-sales/adminConfig"
            render={() => <CheckingPermissions permission={hasPermission(currentUser, 'f1f933a5-a61e-4be4-bfd3-f08d0ec7f1c6', 'data_security_config')} />}
        />
    ),
];

export default PresalesRoutes;
