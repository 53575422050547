import React from 'react';
import { SecureRoute } from '@okta/okta-react';
import { hasPermission, isPresalesUser } from '../../util/permissions';
import CheckingPermissions from '../base/CheckingPermissions';
import AiAssessment from './aiAssessment/AiAssessment';

const allowedRoutes = (currentUser) => {
    return [
        hasPermission(currentUser, '750dd17a-688a-4af4-b2eb-88493cf44e6d', 'create_assessment') ? (
            <SecureRoute key={'ai-workshop'} path="/ai/workshop" component={AiAssessment} />
        ) : (
            <SecureRoute
                key={'ai-workshop'}
                path="/ai/workshop"
                render={() => <CheckingPermissions permission={hasPermission(currentUser, '750dd17a-688a-4af4-b2eb-88493cf44e6d', 'create_assessment')} />}
            />
        ),
    ];
};

const AiRoutes = (currentUser) => [currentUser && isPresalesUser(currentUser) ? [...allowedRoutes(currentUser)] : null];

export default AiRoutes;
