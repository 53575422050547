import React from 'react';
import { SecureRoute } from '@okta/okta-react';
import { hasPermission, isMscUser } from '../../util/permissions';
import CheckingPermissions from '../base/CheckingPermissions';
import MscOverviewDashboard from './MscOverviewDashboard';
import ServiceDeskOverviewDashboard from './ServiceDeskOverviewDashboard';
import FieldNoticesDashboard from './FieldNoticesDashboard';
import TechnologyBreakdownDashboard from './TechnologyBreakdownDashboard';
import RLSSecurityDashboard from './RLSSecurityDashboard';

const allowedRoutes = (currentUser) => {
    return [
        hasPermission(currentUser, '10536f63-edd2-4d4b-9134-df155790c4e7', 'view') ? (
            <SecureRoute key={'msc-overview'} path="/msc/overview-dashboard" component={MscOverviewDashboard} />
        ) : (
            <SecureRoute
                key={'msc-overview'}
                path="/msc/overview-dashboard"
                render={() => <CheckingPermissions permission={hasPermission(currentUser, '10536f63-edd2-4d4b-9134-df155790c4e7', 'view')} />}
            />
        ),
        hasPermission(currentUser, '10536f63-edd2-4d4b-9134-df155790c4e7', 'view') ? (
            <SecureRoute key={'service-desk-overview'} path="/msc/service-desk-dashboard" component={ServiceDeskOverviewDashboard} />
        ) : (
            <SecureRoute
                key={'service-desk-overview'}
                path="/msc/service-desk-dashboard"
                render={() => <CheckingPermissions permission={hasPermission(currentUser, '10536f63-edd2-4d4b-9134-df155790c4e7', 'view')} />}
            />
        ),
        hasPermission(currentUser, '10536f63-edd2-4d4b-9134-df155790c4e7', 'preview') ? (
            <SecureRoute key={'field-notices'} path="/msc/field-notices-dashboard" component={FieldNoticesDashboard} />
        ) : (
            <SecureRoute
                key={'field-notices'}
                path="/msc/field-notices-dashboard"
                render={() => <CheckingPermissions permission={hasPermission(currentUser, '10536f63-edd2-4d4b-9134-df155790c4e7', 'preview')} />}
            />
        ),
        hasPermission(currentUser, '10536f63-edd2-4d4b-9134-df155790c4e7', 'preview') ? (
            <SecureRoute key={'technology-breakdown'} path="/msc/technology-breakdown-dashboard" component={TechnologyBreakdownDashboard} />
        ) : (
            <SecureRoute
                key={'technology-breakdown'}
                path="/msc/technology-breakdown-dashboard"
                render={() => <CheckingPermissions permission={hasPermission(currentUser, '10536f63-edd2-4d4b-9134-df155790c4e7', 'preview')} />}
            />
        ),
        hasPermission(currentUser, '10536f63-edd2-4d4b-9134-df155790c4e7', 'innovation_preview') ? (
            <SecureRoute key={'rls-security'} path="/msc/rls-security-dashboard" component={RLSSecurityDashboard} />
        ) : (
            <SecureRoute
                key={'rls-security'}
                path="/msc/rls-security-dashboard"
                render={() => <CheckingPermissions permission={hasPermission(currentUser, '10536f63-edd2-4d4b-9134-df155790c4e7', 'innovation_preview')} />}
            />
        ),
    ];
};

const MscRoutes = (currentUser) => [currentUser && isMscUser(currentUser) ? [...allowedRoutes(currentUser)] : null];

export default MscRoutes;
