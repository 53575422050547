import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Slider } from '@mui/material';

const VerticalSliderFieldWrapper: React.FunctionComponent<FieldRenderProps<number>> = ({ input: { name, onChange, value, ..._restInput }, _meta, ...rest }) => {

    const [initVal] = React.useState(value);

    return (
            <Slider
            defaultValue={initVal}
            onChangeCommitted={(_, val) => {
                onChange(val);
            }}
            aria-labelledby={name}
            orientation="vertical"
            sx={{
                height: 300,
                marginLeft: '3%',
                marginBottom: '1rem'
            }}
            {...rest}
        />
        
    );
};

export default VerticalSliderFieldWrapper;
