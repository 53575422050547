import { Box, Button, CircularProgress, Collapse, Paper, Table, TableCell, TableRow, Toolbar, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useGetAssessmentByIdQuery, useGetEstimateQuery } from '../../../app/DataProtectionApi';
import useCommonStyles from '../../../util/commonStyles';
import { useState } from 'react';

const BreakdownRow = (name, value) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <Typography sx={{ flex: '1 1 100%', textAlign: 'left' }} variant="h5" component="div">
                {name}:
            </Typography>
            <Typography sx={{ flex: '1 1 100%', textAlign: 'center' }} variant="h5" component="div">
                {value}%
            </Typography>
        </Box>
    );
};

const AIWorkshopScore = () => {
    const commonClasses = useCommonStyles();
    const [expanded, setExpanded] = useState(false);

    const { id } = useParams();

    const { data, error } = useGetEstimateQuery({ type: 'estimation', assessment_id: id });
    const { data: assessmentData, error: getAssessmentError } = useGetAssessmentByIdQuery(
        { type: 'verbose', assessment_id: id },
        { refetchOnMountOrArgChange: true, pollingInterval: 15000 },
    );

    const getConclusionColor = (conclusion) => {
        switch (conclusion) {
            case 'AI Mature':
                return;
            default:
                return 'rgba(0, 0, 0, 0.87)';
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', margin: 'auto', minWidth: '30rem', maxWidth: '70rem', flexBasis: '0', flexGrow: 1 }}>
            {data !== undefined && assessmentData !== undefined ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', padding: '2rem', gap: '1rem', pb: 0 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography sx={{ flex: '1 1 100%', textAlign: 'center' }} variant="h5" component="div">
                            Conclusion: <b>{data.Conclusion}</b>
                        </Typography>
                        <Typography sx={{ flex: '1 1 100%', textAlign: 'center' }} variant="h5" component="div">
                            Overall Score: <b>{data.Score}%</b>
                        </Typography>
                    </Box>
                    <Collapse in={expanded} sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignSelf: 'center', maxWidth: '20rem' }}>
                        {BreakdownRow('Strategy', data['Sectional Scores']['Strategy'])}
                        {BreakdownRow('Infrastructure', data['Sectional Scores']['Infrastructure'])}
                        {BreakdownRow('Data', data['Sectional Scores']['Data'])}
                        {BreakdownRow('Governance', data['Sectional Scores']['Governance'])}
                        {BreakdownRow('Talent', data['Sectional Scores']['Talent'])}
                        {BreakdownRow('Culture', data['Sectional Scores']['Culture'])}
                    </Collapse>
                    <Button
                        sx={{ flex: '1 1 100%', textAlign: 'center' }}
                        variant="text"
                        onClick={() => {
                            setExpanded(!expanded);
                        }}>
                        {expanded ? `Hide Breakdown` : `See Breakdown`}
                    </Button>
                </Box>
            ) : (
                <div style={{ display: 'flex', flexDirection: 'column' }} className={commonClasses.centerScreen}>
                    {error || getAssessmentError ? (
                        <Typography variant="h5">Unable to load AI Envisioning Survey Score details</Typography>
                    ) : (
                        <>
                            <CircularProgress />
                            <Typography>Your AI Envisioning Survey Score is loading...</Typography>
                        </>
                    )}
                </div>
            )}
        </Box>
    );
};

export default AIWorkshopScore;
