import React from 'react';
import { Collapse, TableBody, TableCell, TableRow, Chip } from '@mui/material';

const EnhancedTableBody = (props) => {
    const { selected, toggleSelected, rows, page, rowsPerPage, order, orderBy } = props;

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };

    const getComparator = (order, orderBy) => {
        return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
    };

    // This method is created for cross-browser compatibility, if you don't
    // need to support IE11, you can use Array.prototype.sort() directly
    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };

    const renderCell = (column, value, bg, color) => {
        if(column.type === 'date') return Date.parse(value)
        else if (column.type === 'pill') return <Chip label={value} sx={{ backgroundColor: bg || '', color: color || ''}}/>
        else if (column.type === "currency") return `${column.currency}${Number(value).toLocaleString()}`
        return value
   }

    return (
        <TableBody>
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                        rows.slice().sort(getComparator(order, orderBy)) */}
            {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const pill = props.getPillAttributes ? props.getPillAttributes(row) : undefined


                    return (
                        <>
                            <TableRow key={labelId} id={labelId} hover onClick={() => toggleSelected(row.id)} tabIndex={-1}>
                                {props.columns.map((column) => (
                                    <TableCell align={column.numeric ? 'right' : 'left'}>{renderCell(column, row[column.id], pill?.pillBg, pill?.pillColor)}</TableCell>
                                ))}
                            </TableRow>
                            {props.collapsibleRow && (
                                <TableRow>
                                    <TableCell key={`${row.id}-collapsible`} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                                        <Collapse in={selected === row.id} unmountOnExit>
                                            {props.collapsibleRow(row)}
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            )}
                        </>
                    );
                })}
            {rows.length < 1 && (
                <TableRow>
                    {/* <TableCell colSpan={2} /> */}
                    <TableCell colSpan={Object.entries(props.columns).length} style={{ width: "100%"}} align="center">No Results Found</TableCell>
                    {/* <TableCell colSpan={2} /> */}
                </TableRow>
            )}
            {emptyRows > 0 && (
                <TableRow
                    style={{
                        height: 53 * emptyRows,
                    }}>
                    <TableCell colSpan={6} />
                </TableRow>
            )}
        </TableBody>
    );
};

export default EnhancedTableBody;
