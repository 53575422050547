import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import TabPanel from '../../../../components/TabPanel';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
    }),
);

const Pages = ({ tab, pageContent }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {pageContent.map((page, index) => {
                return (
                    <TabPanel key={`dp-page-${index}`} value={tab} index={index}>
                        {page}
                    </TabPanel>
                );
            })}
        </div>
    );
};

export default Pages;
