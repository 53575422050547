import React from 'react';
import { SecureRoute } from '@okta/okta-react';
import { hasPermission, isMscUser } from '../../util/permissions';
import CheckingPermissions from '../base/CheckingPermissions';
import EoLDashboard from './eolDashboard';

const allowedRoutes = (currentUser) => {
    return [
        hasPermission(currentUser, '10536f63-edd2-4d4b-9134-df155790c4e7', 'innovation_preview') ? (
            <SecureRoute key={'eol-dashboard'} path="/innovation/eol-dashboard" component={EoLDashboard} />
        ) : (
            <SecureRoute
                key={'eol-dashboard'}
                path="/innovation/eol-dashboard"
                render={() => <CheckingPermissions permission={hasPermission(currentUser, '10536f63-edd2-4d4b-9134-df155790c4e7', 'innovation_preview')} />}
            />
        ),
    ];
};

const InnovationRoutes = (currentUser) => [currentUser && isMscUser(currentUser) ? [...allowedRoutes(currentUser)] : null];

export default InnovationRoutes;
