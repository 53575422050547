import React, { useRef } from 'react';

import ChartInfoCard from './ChartInfoCard';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { chartColorsThemeArr } from '../constants';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ExpiryBarChart = ({ chartData, handleClick }) => {
    const chartRef = useRef(null);

    const data = {
        labels: chartData.labels,
        datasets: [
            {
                data: chartData.data,
                backgroundColor: chartColorsThemeArr,
                fontSize: '5px',
                barPercentage: 0.6,
                maxBarThickness: 40,
                borderSkipped: false,
            },
        ],
    };

    return (
        <ChartInfoCard title={'EXPIRING 4 WEEKS'} bgColor={'#80608e'}>
            <div style={{ width: "100%", height: "100%" }}>
                <Bar
                    options={options}
                    data={data}
                    ref={chartRef}
                    onClick={() => {
                        const barTitle = chartRef.current.tooltip.dataPoints[0].label;
                        handleClick(barTitle);
                    }}
                    width={"100%"}
                />
            </div>
        </ChartInfoCard>
    );
};

export default ExpiryBarChart;

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'right',
            display: false,
        },

        title: {
            display: false,
            text: '',
        },
    },
};
