import React from 'react';
import { Checkbox } from '@mui/material';

const CheckboxFieldWrapper = ({ fields, options, isDisabled }) => {
    const toggle = (event, option) => {
        if (event.target.checked) {
            fields.value.answer.push(option);
        } else {
            const index = fields.value.answer.indexOf(option);
            fields.value.answer.splice(index, 1);
        }
    };

    return (
        <>
            {options.map((option) => (
                <>
                    <div key={option} style={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox
                            aria-labelledby={`${fields.name}.${option}`}
                            value={option}
                            defaultChecked={fields.value.answer.indexOf(option) > -1}
                            onChange={(event) => toggle(event, option)}
                            disabled={isDisabled}
                        />
                        {option}
                    </div>
                </>
            ))}
        </>
    );
};

export default CheckboxFieldWrapper;
