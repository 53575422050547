import React from 'react';
import { Button, InputLabel, Box, CircularProgress, Collapse, Fab, Typography, InputAdornment } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Form, Field } from 'react-final-form';
import TextFieldWrapper from '../../../components/assessments/TextFieldFinalFormWrapper';
import { composeValidators, required } from '../../../util/finalFormValidators';
import Group from './Group';
import { useCreateUpdateGroupMutation, useGetGroupsQuery, useGetServicePermissionsQuery, useGetUsersQuery } from '../../../app/DataProtectionApi';
import useCommonStyles from '../../../util/commonStyles';
import { useDispatch } from 'react-redux';
import { closeDrawer, openDrawer } from '../../../app/actions/UiAction';

const useStyles = makeStyles((theme) => ({
    services: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.paper,
        display: 'flex',
        alignItems: 'center',
        height: '3rem',
        margin: '1rem 4rem 0 0',
        padding: '0 1rem',
        borderRadius: 10,
        zIndex: 1000,
        // backgroundColor: theme.palette.background.paper,
        // overflowY: 'auto',
        // paddingBottom: theme.spacing(6),
    },
    addButton: {
        color: theme.palette.background.paper,
    },
}));

const GroupsManagement = () => {
    const dispatch = useDispatch();
    const commonClasses = useCommonStyles();
    const classes = useStyles();
    const [selected, setSelected] = React.useState(null);

    const { data: servicePermissions, error: getServicePermissionsError } = useGetServicePermissionsQuery();

    const { data: groupsData, error: getGroupsError } = useGetGroupsQuery();

    const { data: usersData } = useGetUsersQuery();

    const toggleSelected = (name) => {
        if (name === selected) {
            setSelected(null);
        } else {
            setSelected(name);
        }
    };

    const [createUpdateGroup, { isLoading: isCreatingUpdating }] = useCreateUpdateGroupMutation();

    const createGroup = (group) => {
        group['name'] = group['service_name'] + ' ' + group['name'];
        createUpdateGroup(group);
        closeGroupDrawer();
    };

    const closeGroupDrawer = () => {
        dispatch(closeDrawer());
    };

    const addGroup = (serviceId, serviceName) => {
        return (
            <Form
                initialValues={{ name: '', service_id: serviceId, service_name: serviceName, actions: [] }}
                onSubmit={createGroup}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography variant="h5" sx={{ textAlign: 'center' }}>
                                Create Group
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '96%', padding: '2rem' }}>
                                <Box fullWidth className={classes.input} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'baseline' }}>
                                    <InputLabel className={classes.input} sx={{ flexGrow: 1 }}>
                                        Group Name
                                    </InputLabel>
                                    <Field
                                        style={{ flexGrow: 1 }}
                                        name="name"
                                        component={TextFieldWrapper}
                                        variant="outlined"
                                        placeholder="Name"
                                        validate={composeValidators(required)}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">{serviceName}</InputAdornment>,
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                aria-label="submit"
                                style={{
                                    margin: '.5rem 1rem',
                                    color: 'white',
                                }}
                                disabled={submitting}>
                                {submitting ? <CircularProgress size={20} style={{ color: 'white' }} /> : 'Create'}
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                style={{
                                    margin: '.5rem 1rem',
                                }}
                                disabled={submitting}
                                onClick={closeGroupDrawer}>
                                Cancel
                            </Button>
                        </Box>
                    </form>
                )}
            />
        );
    };

    const openGroupDrawer = (serviceId, serviceName) => {
        dispatch(openDrawer({ rightDrawerContent: addGroup(serviceId, serviceName) }));
    };

    return (
        <>
            {servicePermissions ? (
                Object.entries(servicePermissions).map(([serviceId, serviceData]) => {
                    return (
                        <>
                            <Box boxShadow={3} className={classes.services} onClick={() => toggleSelected(serviceData.service_name)}>
                                <Typography variant="h6" style={{ textDecoration: 'none', color: 'inherit', flexGrow: 1 }}>
                                    {serviceData.service_name}
                                </Typography>
                            </Box>
                            <Collapse in={selected === serviceData.service_name} unmountOnExit>
                                <Box sx={{ margin: '0 1rem' }}>
                                    {groupsData && !isCreatingUpdating ? (
                                        groupsData.map((group) =>
                                            group.service_id === serviceId ? <Group group={group} availableActions={serviceData.actions} users={usersData} /> : null,
                                        )
                                    ) : (
                                        <div className={commonClasses.centerScreen}>
                                            {getGroupsError ? (
                                                <Typography className={commonClasses.error}>Failed to get groups. Please try again.</Typography>
                                            ) : (
                                                <CircularProgress sx={{ margin: '5rem' }} />
                                            )}
                                        </div>
                                    )}
                                    <Fab variant="extended" color="secondary">
                                        <Typography className={classes.addButton} onClick={() => openGroupDrawer(serviceId, serviceData.service_name)}>
                                            Add Group
                                        </Typography>
                                    </Fab>
                                </Box>
                            </Collapse>
                        </>
                    );
                })
            ) : (
                <div className={commonClasses.centerScreen}>
                    {getServicePermissionsError ? (
                        <Typography className={commonClasses.error}>Failed to get services. Please try again.</Typography>
                    ) : (
                        <CircularProgress sx={{ marginTop: '5rem' }} />
                    )}
                </div>
            )}
        </>
    );
};

export default GroupsManagement;
