import React from 'react';
import { useDropzone } from 'react-dropzone';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Button } from '@mui/material';
//import ControlledDismissAlert from 'components/ControlledDismissAlert';
//import prettyBytes from 'pretty-bytes';
import FileDetails from './FileDetails';

const DragDrop = ({ fileName, setAcceptedFiles, setFileName, addingNewFile, setAddingNewFile }) => {
    const [fileError, setFileError] = React.useState(null);

    const maxFileSize = 100000000;

    const onDropAccepted = React.useCallback(
        (acceptedFiles) => {
            setAddingNewFile(false);
            setFileName(acceptedFiles[0].name);
            setFileError(null);
        },
        [setFileName, setAddingNewFile],
    );

    const onDropRejected = React.useCallback((rejectedFiles) => {
        setFileError(rejectedFiles[0].errors[0]);
    }, []);

    const { acceptedFiles, getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDropAccepted,
        onDropRejected,
        multiple: false,
        maxSize: maxFileSize,
    });

    React.useEffect(() => setAcceptedFiles(acceptedFiles), [acceptedFiles, setAcceptedFiles]);

    const style = {
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '800px',
        padding: '20px',
        margin: '30px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
        display: addingNewFile ? 'flex' : 'none',
    };

    const addNewFile = async () => {
        setAddingNewFile(true);
        setAcceptedFiles([]);
        setFileName('');
        open();
    };

    const fileDragNDrop = (
        <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <UploadFileIcon fontSize="large" />

            {isDragActive ? <p>Drop the files here ...</p> : <p>Drag &apos;n&apos; drop files here, or click to select files</p>}
        </div>
    );
    //<BsCloudUpload size="4rem" />
    const renderError = () => {
        if (fileError) {
            let { code } = fileError;
            if (code === 'file-too-large') {
                //message = `File is larger than ${prettyBytes(maxFileSize)}`;
                code = 'File is Too Large';
            }
            return <div>Error</div>;
        }
        return null;
    };

    return (
        <>
            {acceptedFiles.length > 0 && !addingNewFile ? (
                <>
                    <FileDetails file={acceptedFiles[0]} fileName={fileName} setFileName={setFileName} />
                    <Button variant="secondary" type="button" onClick={addNewFile}>
                        Select New File
                    </Button>
                </>
            ) : null}

            {fileDragNDrop}
            {renderError()}
        </>
    );
};

export default DragDrop;
