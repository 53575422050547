import React from 'react';
import { FormControl, MenuItem, TextField } from '@mui/material';

const SelectFieldWrapper = ({ input: { name, onChange, value, ..._restInput }, _meta, showLabel, options, isDisabled, ...rest }) => {
    return (
        <FormControl fullWidth>
            <TextField select disabled={isDisabled} fullWidth label={showLabel} value={value} {...rest}>
                {options.map((option) => (
                    <MenuItem
                        key={option}
                        value={option}
                        onClick={() => {
                            onChange(option);
                        }}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
        </FormControl>
    );
};

export default SelectFieldWrapper;
