import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import RenderQuestion from '../../../../components/assessments/RenderQuestion';
import TextFieldWrapper from '../../../../components/assessments/TextFieldFinalFormWrapper';

const useStyles = makeStyles((theme) => ({
    questions: {
        margin: '2rem 1rem',
    },
    answers: {
        display: 'flex',
        flexDirection: 'row',
        margin: '0 2rem',
    },
}));

const AssessmentView = ({ data }) => {
    const classes = useStyles();
    const [initialQuestionValues, setInitialQuestionValues] = React.useState({});

    const getAnswer = (question) => {
        if (question.answer !== null) {
            return _.cloneDeep(question.answer);
        } else if (question.type === 'checkbox' || question.type === 'section-checkbox' || question.type === 'multiple-free-text') {
            return [];
        } else if (question.type === 'slider' || question.type === 'vertical-slider') {
            return Math.min(...question.options);
        } else {
            return '';
        }
    };

    React.useMemo(() => {
        let tempValues = {};
        if (data !== undefined) {
            Object.entries(data.questions).map(([key, stageData], index) => {
                stageData.map((question) => {
                    tempValues[question.id] = {};
                    tempValues[question.id].value = {
                        score: question.score,
                        answer: getAnswer(question),
                    };
                    tempValues[question.id].note = question.comment;
                    return null;
                });
                return null;
            });
            setInitialQuestionValues(tempValues);
        }
    }, [data]);

    const onSubmit = (e) => {
        //debugger;
    };

    const validation = (e) => {
        return {};
    };

    return (
        <Form
            initialValues={initialQuestionValues}
            onSubmit={onSubmit}
            validate={validation}
            mutators={{
                ...arrayMutators,
            }}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    {Object.entries(data.questions).map(([key, stageData], index) => {
                        return (
                            <div key={key}>
                                <Typography sx={{ margin: '1rem' }} variant="h5">
                                    {key}
                                </Typography>
                                {stageData.map((question) => {
                                    return (
                                        <div key={question.id} className={classes.questions}>
                                            <Typography sx={{ width: '50%' }}>{question.text}</Typography>
                                            <Box className={classes.answers}>
                                                <Box sx={{ flexGrow: 3 }}>
                                                    <RenderQuestion question={question} disabled={true} validation={false} />
                                                </Box>
                                                <Field
                                                    disabled={true}
                                                    style={{ marginLeft: '1rem', minWidth: '40%' }}
                                                    component={TextFieldWrapper}
                                                    name={`${question.id}.note`}
                                                    variant="outlined"
                                                    multiline
                                                    minRows={3}
                                                    maxRows={6}
                                                    placeholder="Comments"
                                                    value={question.comment}
                                                />
                                            </Box>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </form>
            )}></Form>
    );
};

export default AssessmentView;
