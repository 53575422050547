/* eslint-disable no-nested-ternary */
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useCommonStyles from '../../util/commonStyles';
import { sendToastMessage } from '../../app/actions/UiAction';
import ErrForm from './ErrForm';
import AssessmentDetailsForm from './AssessmentDetailsForm';
import profileConfidentRecovery from '../../assets/profileConfidentRecovery.png';
import avaprofile from '../../assets/AVA_pricing_tool.png';
import { useValidateERRMutation } from '../../app/DataProtectionApi';
import aiIntro from '../../assets/ai-readiness-assessment.png';

const AssessmentStart = ({ assessmentRoute, serviceId, useAddHeaderMutation, customFields }) => {
    const dispatch = useDispatch();
    const commonClasses = useCommonStyles();
    const history = useHistory();
    const [validERR, setValidERR] = React.useState(false);
    const [ERRData, setERRData] = React.useState();
    const [submitted, setSubmitted] = React.useState(false);

    const [submitHeader, { isLoading: submitting, error: submitHeaderError, data }] = useAddHeaderMutation();

    const submit = async ({ name, customer_id, description, email, contactEmail, errNumber }) => {
        submitHeader({
            customer_id,
            name,
            description,
            email,
            service_id: serviceId,
            customer_email: contactEmail,
            ERR: errNumber,
        })
            .unwrap()
            .then((res) => {
                setSubmitted(true);
            })
            .catch((error) => {
                dispatch(sendToastMessage('Failed to create assessment.'));
            });
    };

    const [errValidation, { isLoading: validating, error: validationError }] = useValidateERRMutation();

    const validateERR = async ({ errNumber }) => {
        errValidation(errNumber)
            .unwrap()
            .then((res) => {
                setValidERR(true);
                setERRData({ errNumber, ...res });
            })
            .catch((error) => {
                dispatch(sendToastMessage('Sorry the ERR number you entered is not recognized. Please review your information and try again.'));
                setSubmitted(false);
                setValidERR(false);
            });

        // dispatch(sendToastMessage('ERR not valid.'));
    };

    //const [createCustomer, { isLoading: creatingCustomer, error: customerCreationFailed }] = useAddCustomerMutation();

    // TODO: updated field name from maturity_header_id
    const assessmentHeaderId = React.useMemo(() => data?.id, [data]);

    return (
        <div className={commonClasses.centerScreen} style={{ width: '100%' }}>
            {!validERR && <ErrForm validateERR={validateERR} validating={validating} validationError={validationError} />}
            {ERRData !== undefined && !submitted && (
                <AssessmentDetailsForm submit={submit} submitting={submitting} customFields={customFields} ERRData={ERRData} submitHeaderError={submitHeaderError} />
            )}
            {submitted && (
                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    {assessmentRoute?.includes('ava-collab') ? (
                        <img src={avaprofile} alt="AVA Overview" style={{ margin: '0%', width: '100%', maxWidth: '1600px', minHeight: '20vh', maxHeight: '65vh' }} />
                    ) : (
                        <>
                            {serviceId === '750dd17a-688a-4af4-b2eb-88493cf44e6d' ? (
                                <img src={aiIntro} alt="Introduction" style={{ margin: '1rem', width: '96%', minWidth: '780px', maxWidth: '1240px', borderRadius: '.5rem' }} />
                            ) : (
                                <img
                                    src={profileConfidentRecovery}
                                    alt="Confident Recovery"
                                    style={{ margin: '0%', width: '100%', maxWidth: '1600px', minHeight: '20vh', maxHeight: '65vh' }}
                                />
                            )}
                        </>
                    )}
                    <Button variant="contained" sx={{ margin: '1rem' }} onClick={() => history.push(`/${assessmentRoute}/create/${assessmentHeaderId}/form`)}>
                        <Typography>Begin My {serviceId === '750dd17a-688a-4af4-b2eb-88493cf44e6d' ? `Survey` : `Assessment`}</Typography>
                    </Button>
                </Box>
            )}
        </div>
    );
};

export default AssessmentStart;
