import React from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { Button, Box } from '@mui/material';
import EnhancedTable from '../../../components/enhancedTable/EnhancedTable';
import { openDrawer } from '../../../app/actions/UiAction';
import User from './User';
import { useGetUsersQuery } from '../../../app/DataProtectionApi';

const COLUMNS = [
    {
        id: 'name',
        numeric: false,
        label: 'Name',
        filterable: 'search',
    },
    {
        id: 'action',
        numeric: false,
        label: '',
        filterable: false,
    },
];

const UserManagement = () => {
    const dispatch = useDispatch();
    const [userData, setUserData] = React.useState();

    const { data: usersData } = useGetUsersQuery();

    const openUserDrawer = React.useCallback(
        (user) => {
            dispatch(openDrawer({ rightDrawerContent: <User user={user} /> }));
        },
        [dispatch],
    );
    React.useMemo(() => {
        let copyUsersData = _.cloneDeep(usersData);
        var tempUserData = copyUsersData.map((user) => {
            console.log(user);
            user['action'] = (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: 0 }}>
                    <Button sx={{ margin: '-.5rem 0' }} onClick={() => openUserDrawer(user)}>
                        Edit
                    </Button>
                    <Button sx={{ margin: '-.5rem 0' }}>Delete</Button>
                </Box>
            );
            return user;
        });
        setUserData(tempUserData.sort((a, b) => a.name.localeCompare(b.name)));
    }, [usersData, openUserDrawer]);

    return (
        <>
            <EnhancedTable rows={userData} columns={COLUMNS} defaultRowsPerPage={25} defaultRowsPerPageOptions={[25, 50, 100]} setResetButton={() => {}} />
        </>
    );
};

export default UserManagement;
