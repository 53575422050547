import React from 'react';
import { Typography, Box, Button, CircularProgress, Backdrop, InputAdornment } from '@mui/material';
import _ from 'lodash';
import useCommonStyles from '../../../util/commonStyles';
import ConfigTabs from './components/ConfigTabs';
import ConfigPages from './components/ConfigPages';
import { Field, Form } from 'react-final-form';
import TextFieldWrapper from '../../../components/assessments/TextFieldFinalFormWrapper';
import { composeValidators, isNumber, required } from '../../../util/finalFormValidators';
import { useGetConfigsMutation, useUpdateConfigMutation } from '../../../app/DataProtectionApi';
import { connect, useDispatch } from 'react-redux';
import { sendToastMessage } from '../../../app/actions/UiAction';
import { useHistory } from 'react-router-dom';
import { hasPermission } from '../../../util/permissions';

const SERVICE_IDS = [
    'f1f933a5-a61e-4be4-bfd3-f08d0ec7f1c6',
    'e11f6cd7-7989-484e-9c09-792240af01b2',
    'c166003b-ec15-4410-a26b-90e15a1594bc',
    '750dd17a-688a-4af4-b2eb-88493cf44e6d',
];

const AdminConfig = ({ currentUser }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const commonClasses = useCommonStyles();
    const [tab, setTab] = React.useState(0);
    const [isEditing, setIsEditing] = React.useState(false);
    const [isFetchingConfigs, setIsFetchingConfigs] = React.useState(false);
    const [indexEditing, setIndexEditing] = React.useState(0);
    const [configData, setConfigData] = React.useState([]);
    const [refetch, setRefetch] = React.useState(0);

    // const {
    //     data: rawConfigData,
    //     isFetching: isGettingConfigs,
    //     error: getConfigsError,
    // } = useGetConfigsQuery('e11f6cd7-7989-484e-9c09-792240af01b2', { refetchOnMountOrArgChange: true });

    const [updateConfig, { isLoading: isUpdating }] = useUpdateConfigMutation();

    const [getConfig] = useGetConfigsMutation();

    React.useMemo(() => {
        setRefetch(refetch);
        let tempData = [];
        let countResponses = 0;

        setIsFetchingConfigs(true);

        let fetchConfigs = new Promise((resolve, reject) => {
            SERVICE_IDS.forEach((id, index, array) => {
                getConfig(id)
                    .unwrap()
                    .then((res) => {
                        //setConfigData([...configData, ...res]);
                        res.forEach((config) => {
                            tempData.push(config);
                        });
                    })
                    .finally(() => {
                        countResponses = countResponses + 1;
                        if (countResponses === array.length) resolve();
                    });
            });
        });

        fetchConfigs.then(() => {
            const compare = (a, b) => {
                if (a.config_name.toLowerCase() < b.config_name.toLowerCase()) {
                    return -1;
                }
                if (a.config_name.toLowerCase() > b.config_name.toLowerCase()) {
                    return 1;
                }
                return 0;
            };
            tempData.sort(compare);
            setConfigData(tempData);
            setIsFetchingConfigs(false);
        });
    }, [refetch, getConfig]);

    React.useEffect(() => {}, [configData]);

    const submitConfig = async (values, { getState }, ...args) => {
        let tempConfigData = _.cloneDeep(configData);
        const configObject = tempConfigData[indexEditing];
        const configId = configObject.id;

        Object.entries(values[configId]).forEach(([key, val]) => {
            configObject.values[key].value = val.value * 1;
        });

        updateConfig(configObject)
            .then((res) => {
                dispatch(sendToastMessage('Configuration has been updated successfully.'));
                setRefetch(refetch + 1);
            })
            .catch((error) => {
                dispatch(sendToastMessage('Failed to update configuration.'));
            });

        setIsEditing(!isEditing);
        setIndexEditing(0);
    };

    const pageContent = () => {
        // const initialValues = {
        //     'to be deleted': { 'Upfront Brik charge to customer, %': { value: '0.06' }, 'Price per GB, $': { value: '10.0' } },
        //     'to be deleted 2': { 'Upfront Brik charge to customer': { value: '10995' } },
        // };
        let initialValues = {};
        configData.forEach((config) => {
            initialValues[config.id] = {};
            Object.entries(config.values).forEach(([variableName, variableValue], index) => {
                initialValues[config.id][variableName] = variableValue;
                initialValues[config.id][variableName] = variableValue;
            });
        });

        console.log(configData);

        return configData.map((config, index) => {
            return (
                <Form
                    initialValues={initialValues}
                    onSubmit={submitConfig}
                    render={({ handleSubmit, values, valid, form }) => (
                        <form onSubmit={handleSubmit}>
                            <Box sx={{ textAlign: 'center' }}>
                                {Object.entries(config.values).map(([variableName, variableValue], index) => {
                                    return values[config.id][variableName].active ? (
                                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', margin: '1rem' }}>
                                            <Typography sx={{ marginRight: '1rem' }}>{variableName}</Typography>
                                            <Field
                                                style={{ width: '15%', marginRight: '35%' }}
                                                disabled={!isEditing}
                                                name={`${config.id}.${variableName}.value`}
                                                validate={composeValidators(required, isNumber)}
                                                component={TextFieldWrapper}
                                                variant="outlined"
                                                multiline
                                                maxRows={1}
                                                numberField={true}
                                                placeholder="Enter a Number"
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">{values[config.id][variableName].unit === 'usd' ? '$' : null}</InputAdornment>,
                                                    endAdornment: (
                                                        <InputAdornment position="end">{values[config.id][variableName].unit === 'percentage' ? '%' : null}</InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Box>
                                    ) : null;
                                })}
                                {!isEditing && (
                                    <>
                                        <Button
                                            variant="contained"
                                            sx={{ margin: '1rem' }}
                                            onClick={() => {
                                                setIsEditing(!isEditing);
                                                setIndexEditing(index);
                                            }}>
                                            Edit
                                        </Button>
                                        {config.service_id === '750dd17a-688a-4af4-b2eb-88493cf44e6d' &&
                                            hasPermission(currentUser, '750dd17a-688a-4af4-b2eb-88493cf44e6d', 'edit_assessment') && (
                                                <Button
                                                    variant="contained"
                                                    sx={{ margin: '1rem' }}
                                                    onClick={() => {
                                                        history.push(`/assessmentEditor/${config.service_id}`);
                                                    }}>
                                                    Modify Assessment
                                                </Button>
                                            )}
                                    </>
                                )}
                                {isEditing && (
                                    <Box sx={{ margin: '1rem' }}>
                                        <Button type="submit" disabled={!valid} variant="contained" sx={{ margin: '1rem' }}>
                                            Submit
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            sx={{ margin: '1rem' }}
                                            onClick={() => {
                                                form.reset();
                                                setIsEditing(!isEditing);
                                                setIndexEditing(0);
                                            }}>
                                            Cancel
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                            {isUpdating && (
                                <Backdrop sx={{ color: '#fff', zIndex: 999 }} open={isUpdating}>
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                            )}
                        </form>
                    )}
                />
            );
        });
    };

    return (
        <>
            <Typography className={commonClasses.pageTitle} variant="h4">
                Admin Config
            </Typography>
            {configData.length !== 0 ? (
                <>
                    <Box sx={{ padding: '2%' }}>
                        <ConfigTabs tab={tab} setTab={setTab} configData={configData} />
                        <ConfigPages tab={tab} pageContent={pageContent()} />
                    </Box>
                </>
            ) : (
                <div className={commonClasses.centerScreen}>
                    {!isFetchingConfigs && configData.length === 0 ? (
                        <Typography className={commonClasses.error}>Failed to get Configurations. Please try again.</Typography>
                    ) : (
                        <CircularProgress />
                    )}
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return { currentUser: state.User.user };
};

export default connect(mapStateToProps)(AdminConfig);
