import { SecureRoute } from '@okta/okta-react';
import { hasPermission, isMscUser } from '../../util/permissions';
import CheckingPermissions from '../base/CheckingPermissions';
import EditAssessment from './EditAssessment';

const allowedRoutes = (currentUser) => {
    return [
        hasPermission(currentUser, '750dd17a-688a-4af4-b2eb-88493cf44e6d', 'edit_assessment') ? (
            <SecureRoute key={'edit-ai-workshop'} path="/assessmentEditor/:id" component={EditAssessment} />
        ) : (
            <SecureRoute
                key={'assessment-editor'}
                path="/assessmentEditor/:id"
                render={() => <CheckingPermissions permission={hasPermission(currentUser, '750dd17a-688a-4af4-b2eb-88493cf44e6d', 'edit_assessment')} />}
            />
        ),
    ];
};

const AssessmentEditorRoutes = (currentUser) => [currentUser && isMscUser(currentUser) ? [...allowedRoutes(currentUser)] : null];

export default AssessmentEditorRoutes;
