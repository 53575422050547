import React from 'react';
import { Paper, Typography } from '@mui/material';
import { useAddHeaderMutation } from '../../../app/DataProtectionApi';
import CustomAssessmentStart from './components/CustomAssessmentStart';
import useCommonStyles from '../../../util/commonStyles';

const SERVICE_ID = `c166003b-ec15-4410-a26b-90e15a1594bc`;

const InnovationIntakeStart = ({ match }) => {
    const commonClasses = useCommonStyles();

    return (
        <>
            <Typography className={commonClasses.pageTitle} variant="h4">
                Innovation Intake Assessment
            </Typography>
            <Paper>
                <CustomAssessmentStart match={match} assessmentRoute={'innovation/intake'} serviceId={SERVICE_ID} useAddHeaderMutation={useAddHeaderMutation} />
            </Paper>
        </>
    );
};

export default InnovationIntakeStart;
