import React from 'react';
import { Table, TableContainer, TablePagination } from '@mui/material';
import EnhancedTableHead from './components/EnhancedTableHead';
import EnhancedTableBody from './components/EnhancedTableBody';

const EnhancedTable = ({
    rows,
    columns,
    setResetButton,
    resetAction,
    setResetAction,
    collapsibleRow,
    defaultRowsPerPage = 10,
    defaultRowsPerPageOptions = [5, 10, 25],
    chartComponent = null,
    extraFilters = null,
    bgColor = '',
    tableMinWidth=750,
    getPillAttributes=undefined
}) => {
    const [rowData, setRowData] = React.useState(rows);
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('last_edited');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);
    const [selected, setSelected] = React.useState(null);
    const [visibleFilters, setVisibleFilters] = React.useState([]);
    const [filters, setFilters] = React.useState(
        columns.reduce((previousValue, currentValue) => {
            if (currentValue.filterable) {
                previousValue[currentValue.id] = '';
            }
            return previousValue;
        }, {}),
    );

    const toggleVisibleFilters = (column) => {
        if (visibleFilters.includes(column.id)) {
            updateFilters(column, '');
            setVisibleFilters(visibleFilters.filter((filter) => filter !== column.id));
        } else {
            // updateFilters(column, '');
            setVisibleFilters([...visibleFilters, column.id]);
        }
    };

    const updateFilters = React.useCallback(
        (column, value) => {
            let newFilters = { ...filters };
            newFilters[column.id] = value;
            setFilters(newFilters);
            setPage(0);
        },
        [filters],
    );
    const clearFilters = React.useCallback(() => {
        //TODO: Bug here
        setFilters(
            columns.reduce((previousValue, currentValue) => {
                if (currentValue.filterable) {
                    previousValue[currentValue.id] = '';
                }
                return previousValue;
            }, {}),
        );
        setVisibleFilters([]);
        setOrder('desc');
        setOrderBy('last_edited');
    }, [columns]);
    

    const filterResults = React.useCallback(() => {
        const newRows = [];
        rows.forEach((row, index) => {
            // if all of the filters don't match, exclude it from the table
            const exclude = Object.entries(filters).find(([filterName, filterValue]) =>{
                const filterType = columns.find((col)=>col.id === filterName).filterable

                if(filterType === "dropdown" && filterValue !== "") return row[filterName].toString().toLowerCase() !== String(filterValue).toLowerCase()
                else  return !row[filterName].toString().toLowerCase().includes(String(filterValue).toLowerCase())

            });
            if (!exclude) {
                newRows.push(row);
            }
        });
        setRowData(newRows);
    }, [filters, rows]);

    React.useEffect(() => {
        filterResults();
    }, [filters, filterResults]);

    React.useEffect(() => {
        visibleFilters.length < 1 ? setResetButton(false) : setResetButton(true);
    }, [visibleFilters, setResetButton]);

    React.useEffect(() => {
        if (resetAction) {
            clearFilters();
            setResetAction(false);
        }
    }, [resetAction, clearFilters, setResetAction]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const toggleSelected = (name) => {
        if (name === selected) {
            setSelected(null);
        } else {
            setSelected(name);
        }
    };

    return (
        <>
            {chartComponent ? chartComponent(rowData, columns, updateFilters, clearFilters, setRowData, filters) : null}
            {extraFilters ? extraFilters(rowData, setRowData) : null}
            <TableContainer sx={{ backgroundColor: bgColor }}>
                <Table sx={{ minWidth: tableMinWidth }} aria-labelledby="tableTitle" size={"medium"}>
                    <EnhancedTableHead
                        columns={columns}
                        rows={rowData}
                        rowData={rowData}
                        setRowData={setRowData}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        visibleFilters={visibleFilters}
                        updateFilters={updateFilters}
                        filters={filters}
                        toggleVisibleFilters={toggleVisibleFilters}
                    />
                    <EnhancedTableBody
                        selected={selected}
                        toggleSelected={(name) => toggleSelected(name)}
                        rows={rowData}
                        columns={columns}
                        collapsibleRow={collapsibleRow}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        order={order}
                        orderBy={orderBy}
                        getPillAttributes={getPillAttributes}
                    />
                </Table>
            </TableContainer>
            <TablePagination
                sx={{ backgroundColor: bgColor }}
                rowsPerPageOptions={defaultRowsPerPageOptions}
                component="div"
                count={rowData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};

export default EnhancedTable;
