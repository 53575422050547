import { useMemo, useState } from 'react';
import { useGetAssessmentEditQuery, useUpdateAssessmentEditMutation } from '../../app/DataProtectionApi';
import { useParams } from 'react-router-dom';
import useCommonStyles from '../../util/commonStyles';
import { Box, Button, CircularProgress, Tooltip, Typography } from '@mui/material';
import AssessmentTabs from '../../components/assessments/AssessmentTabs';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import _ from 'lodash';
import TextFieldWrapper from '../../components/assessments/TextFieldFinalFormWrapper';
import { EditQuestion } from './EditQuestion';

const EditAssessment = () => {
    const commonClasses = useCommonStyles();
    const [stage, setStage] = useState(0);

    const { id } = useParams();

    const { data: questionData, isLoading, isError } = useGetAssessmentEditQuery(id);

    const handleStage = (nextStage) => {
        if (nextStage < Object.entries(questionData.questions).length) {
            setStage(nextStage);
        }
    };

    const [updateAssessment, { isLoading: submitting }] = useUpdateAssessmentEditMutation();

    const onSubmit = (formVals) => {
        updateAssessment({ id: id, body: formVals });
    };

    const validation = (e) => {
        return {};
    };

    const stageName = questionData ? Object.keys(questionData.questions)[stage] : undefined;
    return (
        <>
            {questionData !== undefined && !isLoading ? (
                <>
                    <Form
                        initialValues={questionData}
                        onSubmit={onSubmit}
                        validate={validation}
                        mutators={{
                            ...arrayMutators,
                        }}
                        render={({ handleSubmit, valid, values }) => (
                            <form onSubmit={handleSubmit}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography className={commonClasses.pageTitle} variant="h4">
                                        Edit Assessment: {values.name}
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', alignItems: 'center' }}>
                                        <Tooltip title="Save Updated Assessment" aria-label="submit">
                                            <span>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="secondary"
                                                    aria-label="submit"
                                                    style={{
                                                        margin: '.5rem 1rem',
                                                        color: 'white',
                                                    }}
                                                    disabled={submitting || !valid}>
                                                    {submitting ? <CircularProgress size={20} style={{ color: 'white' }} /> : 'Save Updates'}
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    </Box>
                                </Box>
                                <AssessmentTabs stage={stage} setStage={setStage} setActiveStep={() => undefined} questionData={questionData} values={undefined} />
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '90%', maxWidth: '80rem', margin: 'auto', gap: '2rem', mt: '1rem' }}>
                                    {Object.values(questionData.questions)[stage].map((question, index) => (
                                        <EditQuestion key={`${question.id}-edit`} question={question} questionIndex={index} stageName={stageName} values={values} />
                                    ))}
                                </Box>
                            </form>
                        )}></Form>
                </>
            ) : (
                <div className={commonClasses.centerScreen}>
                    {isError ? <Typography className={commonClasses.error}>Failed to get assessment questions. Please try again.</Typography> : <CircularProgress />}
                </div>
            )}
        </>
    );
};

export default EditAssessment;
