import { Typography } from '@mui/material';
import { Field } from 'react-final-form';
import { composeValidators, required, isNumber } from '../../util/finalFormValidators';
import SliderFieldWrapper from './questions/sliderField';
import { makeStyles, createStyles } from '@mui/styles';
import SelectFieldWrapper from './questions/selectField';
import SectionCheckboxFieldWrapper from './questions/sectionCheckboxField';
import { FieldArray } from 'react-final-form-arrays';
import CheckboxFieldWrapper from './questions/checkboxField';
import RadioButtonFieldWrapper from './questions/radioButtonField';
import TextFieldWrapper from './TextFieldFinalFormWrapper';
import MultipleTextFieldWrapper from './questions/multipleTextField';
import VerticalSliderFieldWrapper from './questions/verticalSlider';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
        button: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
        slider: {
            padding: theme.spacing(5, 6, 0, 4),
        },
        dropdown: {
            padding: theme.spacing(3, 3, 0, 2),
            width: '40%',
        },
        sectionCheckbox: {
            margin: theme.spacing(1, 3, 0, 2),
            alignContent: 'center',
        },
        checkbox: {
            margin: theme.spacing(1, 3, 0, 2),
        },
        entryPadding: {
            padding: theme.spacing(2, 0, 0, 0),
        },
        leftRightPadding: {
            padding: theme.spacing(0, 1, 0, 1),
        },
    }),
);

const identity = (value) => value;

const RenderQuestion = ({ question, disabled, tipsEnabled, isRequired = true, validation = true }) => {
    const classes = useStyles();

    switch (question.type) {
        case 'slider':
            const sliderMarks = question.options.map((mark, index) => ({ value: mark, label: question.hasOwnProperty('labels') ? question.labels[index] : mark }));
            const sliderDefaults = {
                min: Math.min(...question.options),
                max: Math.max(...question.options),
                step: null,
                marks: sliderMarks,
                color: 'secondary',
                valueLabelDisplay: 'on',
            };
            return (
                <div className={classes.slider}>
                    <Field
                        parse={identity}
                        {...sliderDefaults}
                        disabled={disabled}
                        label={`${question.id}-slider`}
                        name={`${question.id}.value.answer`}
                        validate={composeValidators(required)}
                        render={SliderFieldWrapper}
                    />
                </div>
            );
        case 'vertical-slider':
            const verticalSliderMarks = question.options.map((mark, index) => ({ value: mark, label: question.hasOwnProperty('labels') ? question.labels[index] : mark }));
            const verticalSliderDefaults = {
                min: Math.min(...question.options),
                max: Math.max(...question.options),
                step: null,
                marks: verticalSliderMarks,
                color: 'secondary',
                valueLabelDisplay: 'on',
            };
            return (
                <div className={classes.slider}>
                    <Field
                        parse={identity}
                        {...verticalSliderDefaults}
                        disabled={disabled}
                        label={`${question.id}-slider`}
                        name={`${question.id}.value.answer`}
                        validate={composeValidators(required)}
                        render={VerticalSliderFieldWrapper}
                    />
                </div>
            );
        case 'dropdown':
            return (
                <div className={classes.dropdown}>
                    <Field
                        parse={identity}
                        disabled={disabled}
                        label={`${question.id}-dropdown`}
                        name={`${question.id}.value`}
                        options={question.options}
                        showLabel={'Dropdown'}
                        isDisabled={disabled}
                        render={SelectFieldWrapper}
                    />
                </div>
            );
        case 'section-checkbox':
            return (
                <div className={classes.sectionCheckbox}>
                    <FieldArray
                        parse={identity}
                        disabled={disabled}
                        label={`${question.id}-section-checkbox`}
                        name={`${question.id}.value`}
                        options={question.options}
                        isDisabled={disabled}
                        tipsEnabled={tipsEnabled}
                        component={SectionCheckboxFieldWrapper}
                    />
                </div>
            );
        case 'checkbox':
            return (
                <div className={classes.checkbox}>
                    <FieldArray
                        parse={identity}
                        disabled={disabled}
                        label={`${question.id}-checkbox`}
                        name={`${question.id}.value`}
                        options={question.options}
                        isDisabled={disabled}
                        component={CheckboxFieldWrapper}
                    />
                </div>
            );
        case 'radio-button':
            return (
                <div className={classes.checkbox}>
                    <FieldArray
                        parse={identity}
                        disabled={disabled}
                        label={`${question.id}-radio-button`}
                        name={`${question.id}.value`}
                        options={question.options}
                        isDisabled={disabled}
                        component={RadioButtonFieldWrapper}
                        validate={composeValidators(required)}
                    />
                </div>
            );
        case 'free-text':
            return (
                <Field
                    parse={identity}
                    style={{ maxWidth: '100%', width: '100%' }}
                    disabled={disabled}
                    isDisabled={disabled}
                    viewed={question.viewed}
                    //className={formClasses.formField}
                    name={`${question.id}.value.answer`}
                    //value={`${question.id}.value.answer`}
                    component={TextFieldWrapper}
                    variant="outlined"
                    multiline
                    minRows={3}
                    placeholder="Enter Response Here"
                    validate={composeValidators(required)}
                />
            );
        case 'multiple-free-text':
            return (
                <div className={classes.checkbox}>
                    <FieldArray
                        parse={identity}
                        disabled={disabled}
                        label={`${question.id}-multiple-free-text`}
                        name={`${question.id}.value.answer`}
                        maxFields={question.max}
                        isDisabled={disabled}
                        question={question}
                        component={MultipleTextFieldWrapper}
                    />
                </div>
            );
        case 'integer':
            return (
                <Field
                    parse={identity}
                    style={{ maxWidth: '10rem', width: '100%' }}
                    disabled={disabled}
                    isDisabled={disabled}
                    viewed={question.viewed}
                    name={`${question.id}.value.answer`}
                    component={TextFieldWrapper}
                    variant="outlined"
                    multiline
                    maxRows={1}
                    numberField={true}
                    placeholder="Enter a Number"
                    validate={validation ? (isRequired ? composeValidators(required, isNumber) : composeValidators(isNumber)) : null}
                />
            );
        default:
            return <Typography>Unsupported Question Type</Typography>;
    }
};

export default RenderQuestion;
