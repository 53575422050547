import React, { useState, useMemo, useCallback } from 'react';

import DealsInProgress from './DealsInProgress';
import IncentiveAvailableCard from './IncentiveAvailableCard';
import ExpiryBarChart from './ExpiryBarChart';
import { Box, Button } from '@mui/material'
import moment from 'moment';
import { dateExistsInWeek } from '../utils';

const ChartsContainer = ({ dealsInProgressCardData, incentiveAvailableCardData, expirtyChartData, columns, updateFilters, clearFilters, setRowData, rowsInfo, rowsData, filters }) => {
    const [isFiltering, setIsFiltering] = useState(false);
    const [isFilteringExpiry, setIsFilteringExpiry] = useState(false);

    const handleIsFiltering = () => {
        if (!isFiltering) setIsFiltering(true);
    };

    const getColumn = (columnLabel) => {
        return columns.find((column) => column.label === columnLabel);
    };

    const dealsInProgressCardInfo = dealsInProgressCardData();
    const incentiveAvailableCardInfo = incentiveAvailableCardData();
    const expirtyChartInfo = useMemo(()=> expirtyChartData(), [JSON.stringify(rowsInfo)]);


    const weekFilterToTitle = {
        'Week 1': 'firstWeek',
        'Week 2': 'secondWeek',
        'Week 3': 'thirdWeek',
        'Week 4': 'fourthWeek',
    };

    const filterByExpiry = (rows, title) => {
        const mutatedRows = rows.filter((row) => {
            return dateExistsInWeek({ date: row.deal_incentive_expiry_date, week: weekFilterToTitle[title] });
        });

        setRowData(mutatedRows);
    };

    return (
        <>
            {isFiltering && (
                <Box>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            clearFilters();
                            if (isFilteringExpiry) {
                                setRowData(rowsData);
                                setIsFiltering(false);
                                setIsFilteringExpiry(false);
                            } else {
                                clearFilters();
                                setIsFiltering(false);
                            }
                        }}
                        size="large">
                        Clear Filters
                    </Button>
                </Box>
            )}

            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', padding: '20px 15px', alignItems: 'center', justifyContent: 'center', }}>
                {incentiveAvailableCardData && <IncentiveAvailableCard amount={incentiveAvailableCardInfo} />}

                {expirtyChartData && (
                    <ExpiryBarChart
                        chartData={expirtyChartInfo}
                        handleClick={(title) => {
                            filterByExpiry(rowsInfo, title);
                            handleIsFiltering();
                            setIsFilteringExpiry(true);
                        }}
                    />
                )}

                {dealsInProgressCardData && <DealsInProgress count={dealsInProgressCardInfo} />}
            </Box>
        </>
    );
};

export default ChartsContainer;
