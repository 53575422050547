import React, { useRef } from 'react';
import ChartInfoCard from './ChartInfoCard';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { chartColorsThemeArr } from '../constants';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
ChartJS.defaults.color = '#fff';
ChartJS.defaults.font.size = 17;

const TrackPieChart = ({ chartData, handleClick }) => {
    const chartRef = useRef(null);

    const data = {
      labels: chartData.labels,
      datasets: [
          {
              //label: '',
              data: chartData.data,
              backgroundColor: chartColorsThemeArr,
              //borderWidth: 1,
              fontSize: '5px',
          },
      ],
  };

    return (
        <ChartInfoCard title={'TRACK'} bgColor={'#787c81'}>
            <div style={{ width: 100, display: 'flex', justifyContent: 'center' }}>
                <Pie
                    options={options}
                    data={data}
                    style={{ width: '300px', height: '' }}
                    ref={chartRef}
                    onClick={() => {
                        const barTitle = chartRef.current.tooltip.dataPoints[0].label;
                        handleClick(barTitle);
                    }}
                />
            </div>
        </ChartInfoCard>
    );
};

export default TrackPieChart;

export const options = {
  responsive: false,
  plugins: {
      legend: {
          position: 'right',
          display: true,
          labels: {
            boxWidth: 15,
            boxHeight: 10
          }
      },
      title: {
          display: false,
          text: '',
      },
  },
};
