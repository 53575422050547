import React from 'react';

import { Typography, Box } from '@mui/material';
import { RemoveCircle } from '@mui/icons-material';

import { actionOptions } from '../utils/constants';

export const SiteRow = ({ deleteSite, web_url, revalidateSitesData, isAddPending = false, practiceId, connectedSites, addedSitesArr, setAddedSitesArr }) => {
    const [isPendingAction, setIsPendingAction] = React.useState(false);
    const [action, setAction] = React.useState('');
    const [isSiteSuccessfullyAdded] = React.useState(false);

    const handlePendingAction = (action) => {
        setIsPendingAction(true);
        setAction(action);
    };

    const removeSiteFromAddedSitesArr = React.useCallback(() => {
        const filtered = addedSitesArr.filter((item) => item.site_id !== practiceId);
        setAddedSitesArr(filtered);
    }, [addedSitesArr, practiceId, setAddedSitesArr]);

    React.useEffect(() => {
        let interval;
        if (isPendingAction) {
            interval = setInterval(() => {
                revalidateSitesData();
            }, 30000);
        } else if (!isPendingAction) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [isPendingAction, revalidateSitesData]);

    React.useEffect(() => {
        if (isSiteSuccessfullyAdded) {
            removeSiteFromAddedSitesArr();
        }
    }, [isSiteSuccessfullyAdded, removeSiteFromAddedSitesArr]);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2.5 }}>
            <Typography>{web_url}</Typography>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                {isPendingAction && <Typography sx={{ color: '#f89e60' }}>Pending {action}</Typography>}
                {isAddPending && <Typography sx={{ color: '#f89e60' }}>Pending {actionOptions.ADD}</Typography>}
                {!isPendingAction && !isAddPending && (
                    <RemoveCircle
                        sx={{ fill: 'red', cursor: 'pointer' }}
                        onClick={() => {
                            deleteSite();
                            handlePendingAction(actionOptions.DELETE);
                        }}
                    />
                )}
            </Box>
        </Box>
    );
};
