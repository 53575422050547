import React, { useState } from 'react';

import { Typography, Box, FormControl, TextField, Paper, MenuItem } from '@mui/material';

import EnhancedTable from '../../../../../components/enhancedTable/EnhancedTable';
import rowsData from '../json/expiryData.json';
import { dateExistsInWeek } from '../utils';

const columns = [
    {
        id: 'cr_party_name',
        numeric: false,
        label: 'Deal Name',
        filterable: 'search',
    },
    {
        id: 'incentive_eligibility_status',
        numeric: false,
        label: 'Status',
        filterable: 'dropdown',
        type: 'pill'
    },
];

const options = [
    {
        value: "",
        label: "any"
    },
    {
        value: 'firstWeek',
        label: 'Week 1',
    },
    {
        value: 'secondWeek',
        label: 'Week 2',
    },
    {
        value: 'thirdWeek',
        label: 'Week 3',
    },
    {
        value: 'fourthWeek',
        label: 'Week 4',
    },
];

const ExpiringSoonTable = () => {
    const [resetButton, setResetButton] = useState(false);
    const [resetAction, setResetAction] = useState(false);

    const addIdFieldToRow = (rows) => {
        return rows.map((row, idx) => {
            return { ...row, id: `${idx}-${row.deal_id}` };
        });
    };


    const getPillAttributes = (row) => {
        const daysLeftToExpiry = Number(row.incentive_eligibility_status.split(" ")[2])

        if(daysLeftToExpiry <= 10) return {
            pillBg: "#d32f2f",
            pillColor: "#FFFFFF"
        }
        else return {
            pillBg: "",
            pillColor: ""
        }
    }

    return (
        <>
            <EnhancedTable
                tableMinWidth={400}
                defaultRowsPerPage={5}
                bgColor="#FFFFFF"
                columns={columns}
                rows={addIdFieldToRow(rowsData)}
                setResetButton={setResetButton}
                resetAction={resetAction}
                setResetAction={setResetAction}
                getPillAttributes={getPillAttributes}
                extraFilters={(rowsInfo, setRowsInfo) => {
                    return (
                        <Box sx={{ paddingBottom: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h5" fontWeight={600} sx={{ flex: 1 }}>
                                EXPIRING SOON
                            </Typography>
                            <FormControl variant="standard" sx={{ flex: 1, maxWidth: '200px', backgroundColor: '#FFFFFF', borderRadius: '4px' }}>
                                <TextField select placeholder="Filter" size="small" onChange={(e)=>{
                                    if(e.target.value === ""){
                                        setRowsInfo(rowsData)
                                    }
                                    else {
                                        const mutatedRows = rowsData.filter((row) => {
                                            return dateExistsInWeek({ date: row.deal_incentive_expiry_date, week: e.target.value });
                                        });


                                        setRowsInfo(mutatedRows)
                                    }

                                }}>
                                    {options.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </Box>
                    );
                }}
            />
        </>
    );
};

export default ExpiringSoonTable;
