import React from 'react';

import ChartInfoCard from './ChartInfoCard';
import { Typography } from '@mui/material';

const IncentiveAvailableCard = ({amount}) => {
    return (
        <ChartInfoCard title={'INCENTIVE AVAILABLE'} bgColor="#09a0b2">
            <Typography variant="h3" fontWeight={600} color={'#FFFFFF'}>
                ${amount}
            </Typography>
        </ChartInfoCard>
    );
};

export default IncentiveAvailableCard;
