import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { SecureRoute } from '@okta/okta-react';
import { makeStyles } from '@mui/styles';
import AvaPresalesForm from './AvaPresalesForm';
import AvaPresalesStart from './AvaPresalesStart';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
}));

const AvaPreSales = ({ match }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Switch>
                <Redirect key="AVACreateRedirect" from={`${match.url}`} exact to="/pre-sales/ava-collab/create/start" />,
                <SecureRoute key="start" path={`${match.url}/create/start`} component={AvaPresalesStart} />,
                <SecureRoute key="form" path={`${match.url}/create/:id/form`} component={AvaPresalesForm} />,
            </Switch>
        </div>
    );
};

export default AvaPreSales;
