import { Paper, Stepper, Box, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import AssessmentStep from './AssessmentStep';
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
        button: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
        slider: {
            padding: theme.spacing(5, 1, 0, 1),
        },
        entryPadding: {
            padding: theme.spacing(2, 0, 0, 0),
        },
        leftRightPadding: {
            padding: theme.spacing(0, 1, 0, 1),
        },
    }),
);

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const AssessmentPages = ({ stage, handleStage, activeStep, setActiveStep, questionData, values, handleViewed, tipsEnabled }) => {
    const classes = useStyles();

    const handleNext = React.useCallback(
        (step) => {
            // increment step
            setActiveStep((prevActiveStep) => prevActiveStep + 1);

            //if last question in stage
            if (step === questionData.questions[Object.keys(questionData.questions)[stage]].length - 1) {
                // if we aren't the final stage
                if (stage + 1 < Object.entries(questionData.questions).length) {
                    handleStage(stage + 1);
                }
            }
        },
        [stage, questionData.questions, handleStage, setActiveStep], //[currentTabValue, saveProgress, setTabValue, stageData.answer_list.length, values],
    );

    const handleBack = React.useCallback(() => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }, [setActiveStep]);

    const handleStep = React.useCallback(
        (step) => () => {
            setActiveStep(step);
        },
        [setActiveStep],
    );

    const isRendered = (question) => {
        if (!('depends_on' in question)) {
            return true;
        }
        return question.depends_on.every((dependency) => {
            const responses = values[dependency.id].value.answer;
            const match = dependency.responses.map((response) => {
                return responses.indexOf(response) !== -1;
            });
            if (!(match.indexOf(true) !== -1)) {
                return false;
            }
            return true;
        });
    };

    return (
        <div className={classes.root}>
            {Object.entries(questionData.questions).map(([key, stageData], index) => {
                return (
                    <TabPanel key={`dp-page-${index}`} value={stage} index={index}>
                        <Stepper nonLinear activeStep={activeStep} orientation="vertical">
                            {stageData.map((question, questionIndex) => {
                                // If no question dependencies render question
                                return isRendered(question) ? (
                                    <AssessmentStep
                                        key={question.id}
                                        formFieldName={question.id}
                                        handleStep={handleStep}
                                        handleNext={handleNext}
                                        handleBack={handleBack}
                                        handleViewed={handleViewed}
                                        questionData={questionData}
                                        stage={stage}
                                        values={values}
                                        index={questionIndex}
                                        questions={stageData.map((question) => question)}
                                        tipsEnabled={tipsEnabled}
                                        activeStep={activeStep}
                                        isRendered={isRendered}
                                    />
                                ) : null;
                            })}
                        </Stepper>
                        {activeStep === stageData.length && (
                            <Paper square elevation={0} className={classes.resetContainer}>
                                {/* {isSuccess ? (
                            <>
                                <Typography>Submission Success, please continue to next section</Typography>
                            </>
                        ) : (
                            <>
                                <Typography>Please make sure all your answers are correct before submitting as you can not go back later</Typography>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    disabled={invalid || submitting}
                                    className={classes.button}
                                    style={{
                                        color: 'white',
                                    }}
                                >
                                    Submit
                                </Button>
                            </>
                        )}
                        {submitStageError ? <Typography className={commonClasses.error}>Failed to submit. Please try again.</Typography> : null} */}
                            </Paper>
                        )}
                    </TabPanel>
                );
            })}
        </div>
    );
};

export default AssessmentPages;
