import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Button, Card, Collapse, Paper, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import VerifiedIcon from '@mui/icons-material/Verified';
import DemoFileUpload from './DemoFileUpload';
import { makeStyles } from '@mui/styles';
import prettyBytes from 'pretty-bytes';
import { useDispatch } from 'react-redux';
import { openDrawer } from '../../../../../app/actions/UiAction';
import CSPCreationTool from './CSPCreationTool';
import CXApproval from './CXApproval';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? <Check className="QontoStepIcon-completedIcon" /> : <div className="QontoStepIcon-circle" />}
        </QontoStepIconRoot>
    );
}

const steps = ['Build CSP', 'CX Approval', 'Submit to Customer', 'Customer Approval', 'Submit to Cisco', 'Cisco Approval'];
const docs = [
    {
        uri: require('../../../../../assets/GEICO - Collaboration Advanced- Customer Success Plan (4722532).pdf'),
        name: 'GEICO - Collaboration Advanced- Customer Success Plan (4722532).pdf',
        size: 400421,
    },
    {
        uri: require('../../../../../assets/GEICO - Collaboration Advanced- Customer Success Plan (4722532).pdf'),
        name: 'GEICO - CSP Approval.msg',
        size: 643000,
    },
];

const CSPStepper = () => {
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = React.useState(0);
    const [workCollapsed, setWorkCollapsed] = React.useState(true);
    const [cspFiles, setCspFiles] = React.useState<File[]>([]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleCSPCreate = () => {
        dispatch(openDrawer({ rightDrawerContent: <CSPCreationTool />, widthDrawer: 900 }));
    };

    return (
        <>
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '90%',
                    maxWidth: '100rem',
                    minWidth: '50rem',
                    borderRadius: '1rem',
                    mt: 1,
                    mb: 2,
                    pt: 2,
                    pb: 1,
                }}>
                <Tooltip followCursor placement="top" title="Click to collapse CSP stage details">
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                        }}>
                        <Typography variant="h5">Customer Success Plan</Typography>
                        <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />} sx={{ width: '100%' }} onClick={() => setWorkCollapsed(!workCollapsed)}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                </Tooltip>
                <Collapse in={workCollapsed} sx={{ width: '90%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', pt: 2 }}>
                        {activeStep === 0 && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <DemoFileUpload title="Upload Customer Success Plan:" acceptedFiles={cspFiles} setAcceptedFiles={setCspFiles} />
                                <Tooltip followCursor placement="top" title="Guided CSP Creation Tool" onClick={handleCSPCreate}>
                                    <Button variant="outlined">Need Help Creating a CSP?</Button>
                                </Tooltip>
                            </Box>
                        )}

                        {activeStep === 1 && <CXApproval doc={docs[0]} />}

                        {activeStep === 2 && (
                            <>
                                <Typography variant="h6">Ready to submit to the customer:</Typography>
                                <Paper sx={{ display: 'flex', flexDirection: 'column', maxWidth: '150px', padding: '20px', alignItems: 'center' }}>
                                    <FilePresentIcon sx={{ padding: '20px' }} fontSize="large" />
                                    <Typography textAlign="center">{docs[0].name}</Typography>
                                    <Typography textAlign="center">{prettyBytes(docs[0].size)}</Typography>
                                </Paper>
                            </>
                        )}

                        {activeStep === 3 && (
                            <>
                                <DemoFileUpload title="Upload Customer Approval:" acceptedFiles={cspFiles} setAcceptedFiles={setCspFiles} />
                            </>
                        )}

                        {activeStep === 4 && (
                            <>
                                <Typography variant="h6">Ready to submit to Cisco:</Typography>
                                <Box sx={{ display: 'flex' }}>
                                    <Paper sx={{ display: 'flex', flexDirection: 'column', maxWidth: '150px', padding: '20px', mr: 2, alignItems: 'center' }}>
                                        <FilePresentIcon sx={{ padding: '20px' }} fontSize="large" />
                                        <Typography textAlign="center">{docs[0].name}</Typography>
                                        <Typography textAlign="center">{prettyBytes(docs[0].size)}</Typography>
                                    </Paper>
                                    <Paper sx={{ display: 'flex', flexDirection: 'column', maxWidth: '150px', padding: '20px', ml: 2, alignItems: 'center' }}>
                                        <FilePresentIcon sx={{ padding: '20px' }} fontSize="large" />
                                        <Typography textAlign="center">{docs[1].name}</Typography>
                                        <Typography textAlign="center">{prettyBytes(docs[1].size)}</Typography>
                                    </Paper>
                                </Box>
                            </>
                        )}

                        {activeStep === 5 && (
                            <>
                                <Typography variant="h6" sx={{ mt: 2 }}>
                                    Cisco Approval is currently pending
                                </Typography>
                                <Typography variant="h6" sx={{ mt: 2 }}>
                                    This page should update automatically when Cisco approves these documents
                                </Typography>
                                <Button variant="outlined" sx={{ mt: 3 }}>
                                    I certify Cisco has approved this CSP
                                </Button>
                            </>
                        )}

                        {activeStep === 6 && (
                            <>
                                <VerifiedIcon color="success" sx={{ width: '4rem', height: '4rem' }} />
                                <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
                                    Customer Success Plan Completed
                                </Typography>
                            </>
                        )}

                        <Box sx={{ display: 'flex', width: '100%' }}>
                            {activeStep < 6 && (
                                <>
                                    <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ m: 2 }}>
                                        Back
                                    </Button>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button onClick={handleNext} sx={{ m: 2 }}>
                                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                    </Button>
                                </>
                            )}
                        </Box>
                    </Box>
                </Collapse>
            </Card>
        </>
    );
};

export default CSPStepper;
