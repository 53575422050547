import * as React from 'react';
import { FieldRenderProps } from 'react-final-form';
import TextField from '@mui/material/TextField';

const TextFieldWrapper: React.FunctionComponent<FieldRenderProps<string>> = ({ input: { name, onChange, value, ...restInput }, meta, viewed, numberField, minRows, ...rest }) => {
    const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

    const handleChange = (event: any) => {
        if (numberField) {
            try {
                const val = parseInt(event.target.value, 10);
                if (val >= 0 || event.target.value === '') {
                    onChange(event.target.value);
                }
            } catch {
                //pass
            }
        } else {
            onChange(event.target.value);
        }
    };

    return (
        <TextField
            {...rest}
            inputProps={restInput}
            name={name}
            helperText={showError ? meta.error || meta.submitError : undefined}
            error={showError}
            onChange={handleChange}
            value={value.toString()}
            minRows={minRows}
        />
    );
};

export default TextFieldWrapper;
