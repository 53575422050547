import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";


const useFormStyles = makeStyles((theme: Theme) => ({
    paper: {
        margin: theme.spacing(1),
        padding: theme.spacing(2),
        textAlign: 'center',
    },
    ingredientLabel: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    formField: {
        margin: `0 ${theme.spacing(1)}px`,
        marginBottom: theme.spacing(3),
        // flex: 1,
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
    },
    formContainerFull: {
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
        [theme.breakpoints.up('sm')]: {
            width: '50%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '30%',
            maxWidth: '35rem',
        },
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    submissionButtons: { paddingTop: theme.spacing(3), display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' },
}));

export default useFormStyles;
